import React, { useContext } from "react";
import styles from "./Formatters.module.css";
import InfoTooltip from '../../shared/InfoTooltip/InfoTooltip';
import { SessionContext } from "../../../context/SessionContext";

const HeaderFormatter = ({ col, centered = false, toolTipRequired = false, toolTipMessage = "", fieldName = '' }) => {
    const context = useContext(SessionContext);
    const message = (fieldName === 'repair') ? 'Available repair options are determined by the asset and component selection. To complete a unit replacement, select the asset without a component then select unit replaced as the repair.' :
        (context.accountName === 'Lennox' && fieldName === 'rate') ?
            'The rate is defaulted to purchased tier labor rate.'
            :
            'The rate is defaulted to purchased plan labor rate.';
    return (
        <div className={centered ? styles.toolTipAlignCenter : styles.toolTipAlignFlex}>
            {col.text}
            {toolTipRequired && (
                <span aria-hidden="true" className={`${styles.toolTipSection} pl-2`}>
                    <InfoTooltip iconType="info" message={message} />
                </span>
            )}
        </div>
    );
}


export default HeaderFormatter;