// IMPORTING COLUMN SETS TO BE CONSUMED BY BOOTSTRAP TABLE
import {
    LABOR_COLS,
    LABOR_SPECIAL_RATE_COLS,
    PARTS_COLS,
    PARTS_MARKUP_COLS,
    PARTS_ALLOWANCE_COLS,
    TRIP_COLS,
    TAX_COLS
} from "../ColumnConfiguration";

// EVERY CONTRACT OR CLAIM TYPE OR {{WHATEVER}} WILL HAVE A CONFIG TYPE.
// THESE ARE THE TYPES OF TABLES THAT APPEAR ON EACH CONFIG TYPE.
export const contractTypes = {
    "1": ["labor", "trip","tax"], //L
    "3": ["labor", "trip","tax"], //L
    "5": ["parts", "tax"], //P
    "4": ["labor", "partsMarkup", "trip", "tax"], //LM Canadian Version
    "10": ["labor", "partsMarkup", "trip", "tax"], //LM
    "29": ["labor", "partsAllowance", "trip", "tax"], //LP
    "19": ["labor", "parts", "trip", "tax"], //PL
    "26": ["labor", "partsMarkup", "trip", "tax"], //PLM
    "27": ["labor", "partsMarkup", "trip", "tax"], //PLME
    "20": ["partsMarkup", "tax"], //PM
    "6": ["labor", "parts", "trip", "tax"],
    "8": ["labor", "parts", "trip", "tax"],
    "13": ["labor", "parts", "trip", "tax"],
    "9": ["labor", "parts", "trip", "tax"],
    "17": ["labor", "partsMarkup", "trip", "tax"],
    //Leaving this to understand the table structure involved
    "Parts and Labor Select Rate": ["laborSelectRate", "parts", "trip", "tax"], 
    "24": ["labor", "trip","tax"], //COL
    "23": ["partsMarkup","tax"], //COM
    "16": ["parts","tax"], //CO
    "25": ["labor", "partsMarkup", "trip","tax"], //CPM
    "28": ["labor", "partsMarkup", "trip","tax"], //LR
    "18": ["labor", "parts", "trip","tax"], //LOR
    "21": ["labor", "parts", "trip","tax"], //NL
    "22": ["parts","tax"], //NLP
};

// WHEN CONFIGURING NEW TABLE, THE TABLE TYPE COMES TO THIS AND GETS THE
// PRE-DEFINED COLUMNS FOR BOOTSTRAP TABLE TO USE.
export const COLUMNS = {
    labor: LABOR_COLS,
    laborSelectRate: LABOR_SPECIAL_RATE_COLS,
    parts: PARTS_COLS,
    trip: TRIP_COLS,
    partsMarkup: PARTS_MARKUP_COLS,
    partsmarkup: PARTS_MARKUP_COLS,
    partsAllowance: PARTS_ALLOWANCE_COLS,
};

// CURRENTLY USED WITH addNewRow BELOW. THIS IS BASICALLY A WAY TO CREATE A
// NEW RECORD OR "ROW" IN A TABLE. THE VALUE IN EACH ARRAY ARE THE KEYS THAT
// WILL CREATE EVERY NEW ROW ON THAT TYPE OF TABLE.
export const columnsByTable = {
    labor: ["asset", "component", "repair", "hours", "rate", "total"],
    laborSelectRate: [
        "asset",
        "component",
        "repair",
        "repairCode",
        "percentage",
        "rate",
        "total"
    ],
    partsMarkup: [
        "qty",
        "oem",
        "asset",
        "partNumber",
        "description",
        "price",
        "markup",
        "total"
    ],
    parts: ["qty", "oem", "asset", "partNumber", "description", "price", "total"],
    trip: ["qty", "trip", "reason", "tripRate", "total"],
    partsAllowance: ["name", "total"]
};

const initialRepairOptions = [{ repairAction: 'Unit Replaced', repairActionId: 'unitreplaced' }];
const initialReasonOptions = [{ display: 'Not Installing Dealer', value: 'notinstallingdealer' }, { display: 'Return for Part Installation', value: 'returnforpartinstallation' }]

// WIREUP LOGIC WILL GO HERE WHEN CREATING A NEW ROW. WE WILL TRY TO DO AS MANY API
// CALLS AS POSSIBLE AT THE MOUNT LEVEL. ADJUSTMENTS HERE ARE BASICALLY TO SET THE
// 'DEFAULT' VALUES ON A NEWLY CREATED ROW.
export const addNewRow = (tableType, invoiceId, tableData, contract) => {
    const result = { tableType, invoiceId };
    // pricingAttributId needs to be 9 based on story 
    const tripData = contract?.riskProfile?.riskProfileConfigurationModel?.filter(e => e.pricingAttributeId === 9)?.[0];
    // pricingAttributId needs to be 8 based on story 
    const rateData = contract.riskProfile.riskProfileConfigurationModel?.filter(e => e.pricingAttributeId === 8)?.[0];

    // const newPartsRow = new PartsRow(invoiceId);
    columnsByTable[tableType].forEach((field) => {

        const options = tableData?.[0]?.[field]?.options ?? [];
        if (field === 'repair') {
            result[field] = {
                options: initialRepairOptions,
                selected: []
            }
            return;
        }
        if (field === 'asset' || field === 'component') {
            const modifiedAssets = contract?.assets?.map(e => {
                const currentContractAsset = contract.contractAssetModel?.filter(model => model.assetId === e.assetId)?.[0];
                return {
                    assetId: e.assetId,
                    assetName: `${e.assetName} - SN#${currentContractAsset?.serialNumber}`
                }
            })

            result[field] = {
                options: modifiedAssets ?? options,
                selected: []
            }
            return;
        }
        if (field === 'description') {
            result[field] = {
                options: options ?? [],
                selected: []
            }
            return;
        }
        if (field === 'hours') {
            result[field] = '0.5';
            return;
        }

        if (field === 'tripRate') {
            result[field] = parseInt(tripData?.value ?? 0);
            return;
        }

        if (field === 'rate' && tableType === 'labor') {
            result[field] = parseInt(rateData?.value ?? 0);
            return;
        }

        if (field === 'qty') {
            result[field] = 1;
            return;
        }

        if (field === 'trip') {
            result[field] = 'Round Trip';
            return;
        }

        if (field === 'oem') {
            result[field] = false;
            return;
        }

        if (field === 'price') {
            result[field] = 0;
            return;
        }

        if (field === 'reason' && tableType === 'trip') {
            result[field] = {
                options: initialReasonOptions,
                selected: []
            }
            return;
        }

        result[field] = null;
    });

    result.total = 0;
    if (tableType === 'trip') {
        result.total = parseInt(tripData?.value ?? 0);
    }

    if (tableType === 'labor') {
        result.total = parseFloat(result.hours) * parseFloat(result.rate);
    }
    return result;
    // return tableType === "parts" ? newPartsRow : result;
};
