import React, { useRef, useState, useContext, useEffect, Component, Context } from "react";
import { Card, CardHeader, CardBody, Col, Row, Container } from "reactstrap";
import styles from "./RFSForm.module.css";
import classNames from "classnames";
import { ReactComponent as AIGIcon } from '../../assets/images/Vendor/AIG/aig-logo.svg';
import { ReactComponent as TotalInfoIcon } from '../../assets/images/info-i-icon.svg';
import NeutralInfoBadge from "../shared/NeutralInfoBadge/NeutralInfoBadge";
import BootstrapTable from "react-bootstrap-table-next";
import { NonCoveredRepairsInfo, RepairLimitInfo } from "./RepairLimitInfo";
import moment from 'moment';
import PageLoading from "../shared/PageLoading/PageLoading";
import { RFSAcceptActionModal } from "./RFSAcceptActionModal";
import SnackBarAlert from '../shared/SnackBar/SnackBarAlert';
import SimpleReactValidator from 'simple-react-validator';


const tableResponsiveLg = "table-responsive";

const incidentColumns = [
    {
        dataField: "productName",
        text: "Product",
    },
    {
        dataField: "brandName",
        text: "Brand",
    },
    {
        dataField: "modelNum",
        text: "Model",
    },
    {
        dataField: "serialNum",
        text: "Serial",
    },
    {
        dataField: "purchaseDate",
        text: "Purchase Date",
    },
    {
        dataField: "planEndDate",
        text: "Plan End Date",
    }
];
const reasonOptions = [
    {
        data: "1",
        display: "Authorization Denied"
    },
    {
        data: "2",
        display: "Brand Not Covered",
    },
    {
        data: "3",
        display: "Consumer Refuses Service"
    },
    {
        data: "4",
        display: " Disagree with Terms & Conditions"
    },
    {
        data: "5",
        display: "No Sealed System Tech Available"
    },

    {
        data: "6",
        display: "Overbooked / Understaffed"
    },
    {
        data: "7",
        display: " Product Not Covered"
    },
    {
        data: "8",
        display: "Unable To Contact Consumer"
    },
    {
        data: "9",
        display: " Unit Functioning Properly"
    },
    {
        data: "10",
        display: "Zip Code Not Covered"
    }
]

let RepairEventList = ({ repairEvents }) => {

    var repairEventsList = [];
    if (repairEvents && repairEvents.length > 0) {
        repairEvents.forEach(repairEvent => {
            var repairEventsForm = <div className={styles.repairHist}><span>{repairEvent.createdDate ? moment(repairEvent.createdDate).format('MM/DD/YYYY') : ''}</span><span>Event #{repairEvent.eventNumber}</span></div>;
            repairEventsList.push(repairEventsForm);
        });
        return repairEventsList;
    }
    return repairEventsList;
}


let PreauthorizedData = ({ rateTypes }) => {
    let rateTypesList = [];
    if (rateTypes && rateTypes.length > 0) {
        rateTypes.forEach(rateType => {
            var rateTypeList = <li><span>{rateType.authorizationDescription}</span><span>${rateType.preauthorizedAmount ? (rateType.preauthorizedAmount).toFixed(2) : '00.00'}</span></li>;
            rateTypesList.push(rateTypeList);
        });
        return rateTypesList;
    }
    return rateTypesList;
}

let incidentData = [];
let rfsData = null;

export class RFSForm extends Component {
    //export const RFSForm = (props) => {
    constructor(props) {
        super(props);
        this.state = {
            showInfoModal: false,
            eventId: typeof this.props.match.params.eventId == "undefined" || this.props.match.params.eventId == null ? null : this.props.match.params.eventId,
            clientId: typeof this.props.match.params.clientId == "undefined" || this.props.match.params.clientId == null ? null : this.props.match.params.clientId,
            eventNumber: '',
            showSpinner: true,
            totalAmount: 300,
            totalPreauthorizedAmount: 0,
            showNonCoveredRepairsModal: false,
            showAlert: false,
            actions: false,
            rejectedReason: [],
            rejectedNotes: '',
            showReject: false,
            hideButton: false,
            isPageRetricted: false,
            isValidationFired: false
        }
        this.validator = new SimpleReactValidator({
            element: message => <div className="errorMsg">{message.charAt(0).toUpperCase() + message.slice(1)}</div>,
            messages: {
                required: ':attribute is required.'
            }
        });


    }
    componentWillMount() {

        this.GetRFSFormData();
    }

    async GetRFSFormData() {
        await fetch('claim/GetRFSSummaryByEventId/' + this.state.eventId + '/' + this.state.clientId)
            .then(res => res.json())
            .then(data => {
                rfsData = data;

                incidentData = [{
                    productName: rfsData.assetName,
                    brandName: rfsData.brand,
                    modelNum: rfsData.modelNumber,
                    serialNum: rfsData.serialNumber,
                    purchaseDate: rfsData.planPurchaseDate ? moment(rfsData.planPurchaseDate).format('MM/DD/YYYY') : '',
                    planEndDate: rfsData.planExpirationDate ? moment(rfsData.planExpirationDate).format('MM/DD/YYYY') : '',
                    client: rfsData?.masterProgramName
                }];

                if (rfsData.authorizationModel && rfsData.authorizationModel?.length > 0) {
                    var val = rfsData.authorizationModel.reduce((accum, item) => accum + item.preauthorizedAmount, 0);
                    this.setState({ totalPreauthorizedAmount: val });
                }
                if (rfsData.eventStatusId == 14) {
                    this.props.history.push('/authconfirmation', { rejected: true, eventNumber: rfsData.eventNumber });
                }
                this.setState({ eventNumber: rfsData.eventNumber, showSpinner: false, totalAmount: rfsData.tcfAmount, showReject: (rfsData.eventStatusId == 13 || rfsData.eventStatusId == 14) ? false : true, isPageRetricted: rfsData.eventStatusId == 14 ? true : false });
            });

    }

    handleSubmitInvoice = () => {
        //this.props.push({ pathname: "/submitinvoice", state: { eventNumber: this.state.eventNumber, eventId: this.state.eventId, clientId: this.state.clientId } });
        console.log(this.state)
        this.props.history.push('/submitinvoice', {
            eventNumber: this.state.eventNumber,
            eventId: this.state.eventId,
            clientId: this.state.clientId, modelNumber: incidentData[0].modelNum,
            serialNumber: incidentData[0].serialNum,
            brandName: incidentData[0].brandName,
            servicerPhone: this.state.servicerPhone,
            preauthorizedAmount: this.state.totalPreauthorizedAmount,
            rfsData: rfsData
        });
    }

    handleNonCoveredRepairsModal = () => {
        this.setState({ showNonCoveredRepairsModal: true });
    }

    handleAuthorizationClick = () => {
        this.props.history.push('/authorization', { eventNumber: this.state.eventNumber, rfsData: rfsData, claimId: rfsData?.claimId, clientId: this.state.clientId, eventId: this.state.eventId, preauthorizedAmount: this.state.totalPreauthorizedAmount });
    }


    formattedPhone = phone => {
        var cleaned = ('' + phone).replace(/\D/g, '')
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            var intlCode = (match[1] ? '+1 ' : '')
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
        }
        return null
    }
    handleReject = () => {
        this.setState({
            actions: true, isValidationFired: false, rejectedReason: ''
        })
        this.validator.hideMessages();
    }
    handleNotesChange = (value) => {
        this.setState({
            rejectedNotes: value
        })

    }
    handleReasonFieldChange = (
        selectedOption,
        fieldName,
        availableReasons
    ) => {
        let selectedResonCode = reasonOptions.filter(
            (e) => e.data === selectedOption
        );
        this.setState({ rejectedReason: selectedResonCode });
    };


    handleSubmit = async () => {
        if (this.state.actions && this.state.rejectedReason.length == 0) {
            this.setState({ rejectedReason: '', isValidationFired: true });
            this.validator.showMessages();
        }
        else {
            var model = {
                "EventId": parseInt(this.state.eventId),
                "ServicerName": rfsData.servicerName,
                "IsAccepted": this.state.actions ? false : true,
                "ClaimNumber": "0",
                "RejectedReason": this.state.rejectedReason[0]?.display,
                "RejectedDescription": this.state.rejectedNotes
            }
            var response = await fetch('queue/rfsacceptorreject/' + this.state.clientId, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(model)
            });

            if (response.ok) {
                const result = await response.json();
                if (result?.eventStatusId == 13) {
                    this.setState({ showAlert: true, showReject: false, isValidationFired: false });

                }
                else if (result?.eventStatusId == 14) {
                    this.setState({ showAlert: false, actions: false, showReject: false, isValidationFired: false });
                    this.props.history.push('/authconfirmation', { rejected: true, eventNumber: this.state.eventNumber });
                }
                else
                    this.setState({ showAlert: false, actions: false, isValidationFired: false });

            }

        }


    }

    //const claim = fetch();
    render() {
        if (this.state.showSpinner) {
            return (
                <PageLoading text="Loading Request" />
            );
        }

        return (
            <Container className="contentWrapper">
                {rfsData && !this.state.isPageRetricted &&
                    <div>
                        <Card>
                            <CardHeader className={styles.rfsHeader}>
                                <div className={styles.formTitle}>
                                    <span className={styles.aigLogo}>
                                        <AIGIcon />
                                    </span>
                                    <div>
                                        <h1>Service Request Details</h1>
                                        <p>Client: {rfsData.masterProgramName}</p>
                                    </div>
                                </div>
                                <div className={styles.eventNum}>
                                    <p>Event #{rfsData.eventNumber}</p>
                                    <p>{rfsData.claimSetupDate ? moment(rfsData.claimSetupDate).format('MM/DD/YYYY') : ''}</p>
                                </div>
                            </CardHeader>

                            <CardBody className={styles.rfsBody}>
                                <Row>
                                    {/* Left-side col REQUEST DETAILS OVERVIEW*/}
                                    <Col lg="9" className={styles.leftCol}>
                                        <Row className={styles.detailsRow}>
                                            <Col className={styles.details}>
                                                <h2 className="h6">Assigned To</h2>
                                                <ul className="noBullets">
                                                    <li>{rfsData.servicerName}</li>
                                                <li>{rfsData.servicerAddress1 + rfsData.servicerAddress2 + rfsData.servicerAddress3}</li>
                                                    <li>{rfsData.servicerPhone ? this.formattedPhone(rfsData.servicerPhone) : ''}</li>
                                                    <li>{rfsData.servicerEmailId}</li>
                                                </ul>
                                            </Col>
                                            <Col className={styles.details}>
                                                <h2 className="h6">Customer Information</h2>
                                                <ul className="noBullets">
                                                    <li>{rfsData.customerName}</li>
                                                    <li>{rfsData.customerAddress1 + rfsData.customerAddress2}</li>
                                                    {rfsData.customerMobileNo && <li>{this.formattedPhone(rfsData.customerMobileNo) + ' (Mobile)'}</li>}
                                                    {rfsData.customerAlternateNo && <li>{this.formattedPhone(rfsData.customerAlternateNo) + ' (Alternate)'}</li>}
                                                    <li>Contract Number: {rfsData.contractNumber}</li>
                                                </ul>
                                            </Col>
                                        </Row>
                                        <h2 className={classNames(styles.incDetailsHead, "h6")}>Incident Details</h2>
                                        <ul className={styles.incDetails}>
                                            <li>Incident Date: {rfsData.lossDate ? moment(rfsData.lossDate).format('MM/DD/YYYY') : ''}</li>
                                            <li>{rfsData.symptom}.</li>
                                            <li>Problem Description: {rfsData.incidentDescription}</li>
                                            <li>Priority Service: {rfsData.priorityService ? "Yes" : "No"}</li>
                                        </ul>
                                        <div className={styles.incidentDetTable}>
                                            <BootstrapTable
                                                keyField="Serial"
                                                data={incidentData}
                                                columns={incidentColumns}
                                                bordered={false}
                                                bootstrap4
                                                wrapperClasses={tableResponsiveLg}
                                            />
                                        </div>
                                        <Row>
                                            <Col className="col-md-5 col-lg-4">
                                                <h2 className="h6">Repair History</h2>
                                                <div className={classNames("col-md-12", styles.repairHistList)}>
                                                    <RepairEventList repairEvents={rfsData.repairEvents} />
                                                </div>
                                            </Col>
                                            {/*<button className="btn-secondary">View Covered Repair Details</button>*/}
                                             {this.state.showReject &&
                                                <div className={classNames(styles.actionButtons, "mt-5 mr-3")}>
                                                    <button className={classNames("btn-secondary mb-3")} onClick={this.handleReject}>Reject</button>
                                                    <button className={classNames("btn-primary  mb-3", styles.acceptButton)} onClick={this.handleSubmit}>Accept</button>
                                                </div>
                                            } 
                                           
                                        </Row>
                                    </Col>

                                    {/* Right-side Col LINE-ITEM SUMMARY */}
                                    <Col lg="3" className={styles.rightCol}>
                                        <h2 className={classNames("h6", styles.preAuth)}>Pre-Authorized Rates</h2>
                                        <ul className={styles.ratesList}>
                                            <PreauthorizedData rateTypes={rfsData.authorizationModel} />
                                        </ul>
                                        <h2 className={classNames(styles.totalLimit, "h6")}>Total Repair Limit
                                            <span type="button" onClick={() => { this.setState({ showInfoModal: true }); }}>
                                                <TotalInfoIcon />
                                            </span>
                                        </h2>
                                        <div className={styles.amountBadge}>
                                            <NeutralInfoBadge amount={this.state.totalAmount} />
                                        </div>
                                        <button className="btn-primary mb-3" onClick={this.handleAuthorizationClick}>
                                            Request Authorization
                                        </button>
                                        <button onClick={this.handleSubmitInvoice} className={classNames("btn-secondary", "mb-3")}>
                                            Submit Invoice
                                        </button>

                                        <button onClick={this.handleNonCoveredRepairsModal} className={classNames("btn-secondary", "mb-4")}>
                                            View Non-Covered Repairs
                                        </button>
                                        <p className={styles.notePay}>Authorization required if cost will exceed Total Repair Limit.</p>
                                    </Col>
                                </Row>

                                {/* Conditions */}
                                <div>
                                    <h2 className="font-weight-bold mt-5 h6">Conditions</h2>
                                    <ul className={styles.conditionsList}>
                                        <li>
                                            <span>(1)</span>
                                            This Service Request validates and confirms that the consumer is requesting service on behalf of
                                            the Program Client.
                                        </li>
                                        <li>
                                            <span>(2)</span>
                                            Follow the below expectations when contacting AIG customers:
                                            <ul className={styles.subList}>
                                                <li>
                                                    <span>a.</span>
                                                    If an appointment has not been scheduled and you receive this Service Request,
                                                    please call the customer within 24 hours to schedule an appointment.
                                                </li>
                                                <li>
                                                    <span>b.</span>
                                                    If priority service is required, please contact the customer within 1 hour to schedule
                                                    an appointment.
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <span>(3)</span>
                                            As our appointed Servicer, we ask that you act on our behalf to eliminate claims of abuse,
                                            neglect, acts of God, etc.
                                        </li>
                                        <li>
                                            <span>(4)</span>
                                            If you have any questions on coverage, please call our Warranty Claims Department at
                                            <a href="tel:866-523-8649" className='callLink'> (866) 523-8649</a> for assistance.
                                        </li>
                                        <li>
                                            <span>(5)</span>
                                            By acceptance of this Service Request, you as a Servicer agree to keep all personal data stated
                                            on this service request secured and protected from unauthorized or accidental access, processing,
                                            erasure and all other uses. Information transferred to any other company must have prior AIG
                                            approval.
                                        </li>
                                        <li>
                                            <span>(6)</span>
                                            By acceptance of this Service Request, you are agreeing that all service technicians within
                                            your organization have been subject to proper background screening and that your organization
                                            has a valid EIN number that can be provided to AIG.
                                        </li>
                                        <li>
                                            <span>(7)</span>
                                            Repairs exceeding the Total Repair Limit, where no authorization is obtained, can result
                                            in non-payment of repair.
                                        </li>
                                    </ul>
                                </div>
                            </CardBody>
                        </Card>
                        <RepairLimitInfo
                            toggle={() => { this.setState({ showInfoModal: false }); }}
                            isOpen={this.state.showInfoModal}
                            centered={true}
                            className="modal-md"
                        />
                        <NonCoveredRepairsInfo
                            toggle={() => { this.setState({ showNonCoveredRepairsModal: false }); }}
                            isOpen={this.state.showNonCoveredRepairsModal}
                            centered={true}
                            className="modal-md"
                            incidentData={incidentData[0]}
                        />
                        <SnackBarAlert
                            content="Service Request accepted successfully."
                            onClose={() => this.setState({ showAlert: false })}
                            showSnackBar={this.state.showAlert}
                        />
                        {this.state.actions &&
                            <RFSAcceptActionModal
                                isOpen={this.state.actions}
                                toggle={() => { this.setState({ actions: false, isValidationFired: false }) }}
                                handleReasonChange={this.handleReasonFieldChange}
                                handlerejectSubmit={this.handleSubmit}
                                reasonSelected={this.state.rejectedReason}
                                reasonOptions={reasonOptions} handleNotesChange={this.handleNotesChange}
                                notes={this.state.rejectedNotes}
                                isValidationFired={this.state.isValidationFired}
                                validator={this.validator}
                            />
                        }
                    </div>
                }
            </Container>);
    }
}

