// Error handling for table type and column specific 

export const getPartsErrorColumns = (columns, inValidationMode) => {

    const cols = columns.map(col => {
   
        if (col.dataField === 'asset') {
            return {
                ...col,
                attrs: function callback(cell, row, rowIndex, colIndex) {
                    if (cell?.selected?.length <= 0 && inValidationMode) {
                        return {
                            title: 'error-col'
                        }
                    }
                },
                style: function callback(cell, row, rowIndex, colIndex) {
                    if (cell?.selected?.length <= 0 && inValidationMode) {
                        return {
                            boxShadow: "inset 0px 0px 1px 1px red"
                        }
                    }
                },
            }
        }

        if (col.dataField === 'description') {
            return {
                ...col,
                attrs: function callback(cell, row, rowIndex, colIndex) {
                    if (cell?.selected?.length <= 0 && inValidationMode) {
                        return {
                            title: 'error-col'
                        }
                    }
                },

                style: function callback(cell, row, rowIndex, colIndex) {
                    if (cell?.selected?.length <= 0 && inValidationMode) {

                        return {
                            boxShadow: "inset 0px 0px 1px 1px red"
                        }
                    }
                },
            }
        }

        if (col.dataField === 'partNumber') {
            return {
                ...col,
                attrs: function callback(cell, row, rowIndex, colIndex) {
                    if (!cell && inValidationMode) {
                        return {
                            title: 'error-col'
                        }
                    }
                },
                style: function callback(cell, row, rowIndex, colIndex) {
                    if (!cell && inValidationMode) {
                        return {
                            boxShadow: "inset 0px 0px 1px 1px red"
                        }
                    }
                },
            }
        }
        return col;
    })
    return {
        cols,
    }
}


export const getLaborErrorColumns = (columns, inValidationMode) => {
   
    const cols = columns.map(col => {
        if (col.dataField === 'asset') {
            return {
                ...col,
                attrs: function callback(cell, row, rowIndex, colIndex) {
                    if (cell?.selected?.length <= 0 && inValidationMode) {
                        return {
                            title: 'error-col'
                        }
                    }
                },
                style: function callback(cell, row, rowIndex, colIndex) {
                    if (cell?.selected?.length <= 0 && inValidationMode) {
                        return {
                            boxShadow: "inset 0px 0px 1px 1px red"
                        }
                    }
                },
            }
        }

        if (col.dataField === 'repair') {
            return {
                ...col,
                attrs: function callback(cell, row, rowIndex, colIndex) {
                    if (cell?.selected?.length <= 0 && inValidationMode) {
                        return {
                            title: 'error-col'
                        }
                    }
                },
                style: function callback(cell, row, rowIndex, colIndex) {
                    if (cell?.selected?.length <= 0 && inValidationMode) {
                        return {
                            boxShadow: "inset 0px 0px 1px 1px red"
                        }
                    }
                },
            }
        }
       
        return col;
    })

    return {
        cols,
    }
}


export const getTripErrorColumns = (columns, inValidationMode) => {
    let errorCount = 0;
    const cols = columns.map(col => {
        if (col.dataField === 'reason') {
            return {
                ...col,
                attrs: function callback(cell, row, rowIndex, colIndex) {
                    if (cell?.selected?.length <= 0 && inValidationMode) {
                        return {
                            title: 'error-col'
                        }
                    }
                },
                style: function callback(cell, row, rowIndex, colIndex) {
                    if (cell?.selected?.length <= 0 && inValidationMode) {
                        errorCount = errorCount + 1;
                        return {
                            boxShadow: "inset 0px 0px 1px 1px red"
                        }
                    }
                },
            }
        }
        return col;
    })

    return {
        cols,
        errorCount
    }
}


export const getDuplicateRepairs = (data) => {
    let isDuplicateSelected = false;
    const duplicates = (data ?? []).reduce((acc, e) => {
        if (acc?.length > 0 && acc?.includes(e.value)) {
            isDuplicateSelected = true;
            return;
        }
        acc = [...acc ?? [], e.value];
        return acc;
    }, []);
    return {
        isDuplicateSelected,
        duplicates
    }
}

export const formatBytes = (bytes, decimals = 2) => {
    if (!bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}