import React from 'react';

// DEFAULT AIG
import {ReactComponent as Sales } from '../../assets/images/Vendor/AIG/dollar-sign-circle.svg'
import {ReactComponent as Reports } from '../../assets/images/Vendor/AIG/reports-circle.svg'
import {ReactComponent as Resources } from '../../assets/images/Vendor/AIG/resources-circle.svg'
import {ReactComponent as Claims } from '../../assets/images/Vendor/AIG/tools-circle.svg'
import {ReactComponent as ContractActive } from '../../assets/images/Vendor/AIG/contract-active.svg'
import {ReactComponent as ContractPending } from '../../assets/images/Vendor/AIG/contract-pending.svg'
import {ReactComponent as SaleIncomplete } from '../../assets/images/Vendor/AIG/sale-incomplete.svg'
import {ReactComponent as Advantage } from '../../assets/images/Vendor/AIG/shield-check.svg'
import {ReactComponent as Successful } from '../../assets/images/Vendor/AIG/successfull.svg'
import {ReactComponent as Contact } from '../../assets/images/Vendor/AIG/contact.svg'
import {ReactComponent as Enroll } from '../../assets/images/Vendor/AIG/revenue.svg'
import {ReactComponent as Error } from '../../assets/images/Vendor/AIG/exclamation.svg'
import {ReactComponent as Email } from '../../assets/images/Vendor/AIG/email-sent.svg'
import {ReactComponent as Checkboxes } from '../../assets/images/Vendor/AIG/checkboxes.svg';
import {ReactComponent as Ratings } from '../../assets/images/Vendor/AIG/ratings.svg';
import {ReactComponent as Graph } from '../../assets/images/Vendor/AIG/graph.svg';
import {ReactComponent as Lock } from '../../assets/images/Vendor/AIG/lock.svg';
import {ReactComponent as Profile } from '../../assets/images/Vendor/AIG/profile.svg';
import {ReactComponent as UploadIcon } from '../../assets/images/Vendor/AIG/upload-circle.svg';
import {ReactComponent as ClaimApprove} from '../../assets/images/Vendor/AIG/claim-saved.svg';
import {ReactComponent as ClaimReject} from '../../assets/images/Vendor/AIG/claim-cancelled.svg';
import {ReactComponent as ClaimReview} from '../../assets/images/Vendor/AIG/claim-review.svg';
// Lennox
import {ReactComponent as LennoxSales } from '../../assets/images/Vendor/Lennox/dollar-sign-circle.svg'
import {ReactComponent as LennoxReports } from '../../assets/images/Vendor/Lennox/reports-circle.svg'
import {ReactComponent as LennoxResources } from '../../assets/images/Vendor/Lennox/resources-circle.svg'
import {ReactComponent as LennoxClaims } from '../../assets/images/Vendor/Lennox/tools-circle.svg'
import {ReactComponent as LennoxContractActive } from '../../assets/images/Vendor/Lennox/contract-active.svg'
import {ReactComponent as LennoxContractPending } from '../../assets/images/Vendor/Lennox/contract-pending.svg'
import {ReactComponent as LennoxSaleIncomplete } from '../../assets/images/Vendor/Lennox/sale-incomplete.svg'
import {ReactComponent as LennoxAdvantage } from '../../assets/images/Vendor/Lennox/shield-check.svg'
import {ReactComponent as LennoxSuccessful } from '../../assets/images/Vendor/Lennox/successfull.svg'
import {ReactComponent as LennoxContact } from '../../assets/images/Vendor/Lennox/contact.svg'
import {ReactComponent as LennoxEnroll } from '../../assets/images/Vendor/Lennox/revenue.svg'
import {ReactComponent as LennoxError } from '../../assets/images/Vendor/Lennox/exclamation.svg'
import {ReactComponent as LennoxEmail } from '../../assets/images/Vendor/Lennox/email-sent-cs.svg'
import {ReactComponent as LennoxCheckboxes } from '../../assets/images/Vendor/Lennox/checkboxes-cs.svg';
import {ReactComponent as LennoxRatings } from '../../assets/images/Vendor/Lennox/ratings-cs.svg';
import {ReactComponent as LennoxLock } from '../../assets/images/Vendor/Lennox/lock-cs.svg';
import {ReactComponent as LennoxGraph } from '../../assets/images/Vendor/Lennox/graph-cs.svg';
import {ReactComponent as LennoxProfile } from '../../assets/images/Vendor/Lennox/profile.svg';
import {ReactComponent as LennoxProfileAlert } from '../../assets/images/Vendor/Lennox/profile-alert-cs.svg';
import {ReactComponent as LennoxUploadIcon } from '../../assets/images/Vendor/Lennox/upload-circle-cs.svg';
import {ReactComponent as LennoxClaimApprove} from '../../assets/images/Vendor/Lennox/claim-saved-cs.svg';
import {ReactComponent as LennoxClaimReject} from '../../assets/images/Vendor/Lennox/claim-cancelled-cs.svg';
import {ReactComponent as LennoxClaimReview} from '../../assets/images/Vendor/Lennox/claim-review-cs.svg';
// Rheem
import {ReactComponent as RheemSales } from '../../assets/images/Vendor/Rheem/dollar-sign-circle-pp.svg'
import {ReactComponent as RheemReports } from '../../assets/images/Vendor/Rheem/reports-circle-pp.svg'
import {ReactComponent as RheemResources } from '../../assets/images/Vendor/Rheem/resources-circle-pp.svg'
import {ReactComponent as RheemClaims } from '../../assets/images/Vendor/Rheem/tools-circle-pp.svg'
import {ReactComponent as RheemContractActive } from '../../assets/images/Vendor/Rheem/contract-active-pp.svg'
import {ReactComponent as RheemContractPending } from '../../assets/images/Vendor/Rheem/contract-pending-pp.svg'
import {ReactComponent as RheemSaleIncomplete } from '../../assets/images/Vendor/Rheem/sale-incomplete-pp.svg'
import {ReactComponent as RheemAdvantage } from '../../assets/images/Vendor/Rheem/shield-check-pp.svg'
import {ReactComponent as RheemSuccessful } from '../../assets/images/Vendor/Rheem/successfull-pp.svg'
import {ReactComponent as RheemContact } from '../../assets/images/Vendor/Rheem/contact-pp.svg'
import {ReactComponent as RheemEnroll } from '../../assets/images/Vendor/Rheem/revenue-pp.svg'
import {ReactComponent as RheemError } from '../../assets/images/Vendor/Rheem/exclamation-circle-pp.svg'
import {ReactComponent as RheemEmail } from '../../assets/images/Vendor/Rheem/email-sent-pp.svg'
import {ReactComponent as RheemCheckboxes } from '../../assets/images/Vendor/Rheem/checkboxes-pp.svg';
import {ReactComponent as RheemRatings } from '../../assets/images/Vendor/Rheem/ratings-pp.svg';
import {ReactComponent as RheemGraph } from '../../assets/images/Vendor/Rheem/graph-pp.svg';
import {ReactComponent as RheemLock } from '../../assets/images/Vendor/Rheem/lock-pp.svg';
import {ReactComponent as RheemProfile } from '../../assets/images/Vendor/Rheem/profile.svg';
import {ReactComponent as RheemProfileAlert } from '../../assets/images/Vendor/Rheem/profile-alert-pp.svg';
import {ReactComponent as RheemUploadIcon } from '../../assets/images/Vendor/Rheem/upload-circle-pp.svg';
import {ReactComponent as RheemClaimApprove} from '../../assets/images/Vendor/Rheem/claim-saved-pp.svg';
import {ReactComponent as RheemClaimReject} from '../../assets/images/Vendor/Rheem/claim-cancelled-pp.svg';
import {ReactComponent as RheemClaimReview} from '../../assets/images/Vendor/Rheem/claim-review-pp.svg';
// Carrier
import {ReactComponent as CarrierSales } from '../../assets/images/Vendor/Carrier/dollar-sign-circle-pp.svg'
import {ReactComponent as CarrierReports } from '../../assets/images/Vendor/Carrier/reports-circle-CSC.svg'
import {ReactComponent as CarrierResources } from '../../assets/images/Vendor/Carrier/resources-circle-CSC.svg'
import {ReactComponent as CarrierClaims } from '../../assets/images/Vendor/Carrier/tools-circle-CSC.svg'
import {ReactComponent as CarrierContractActive } from '../../assets/images/Vendor/Carrier/contract-active-CSC.svg'
import {ReactComponent as CarrierContractPending } from '../../assets/images/Vendor/Carrier/contract-pending-CSC.svg'
import {ReactComponent as CarrierSaleIncomplete } from '../../assets/images/Vendor/Carrier/sale-incomplete-CSC.svg'
import {ReactComponent as CarrierAdvantage } from '../../assets/images/Vendor/Carrier/shield-check-CSC.svg'
import {ReactComponent as CarrierSuccessful } from '../../assets/images/Vendor/Carrier/successfull-CSC.svg'
import {ReactComponent as CarrierContact } from '../../assets/images/Vendor/Carrier/contact-csc.svg'
import {ReactComponent as CarrierEnroll } from '../../assets/images/Vendor/Carrier/revenue-CSC.svg'
import {ReactComponent as CarrierError } from '../../assets/images/Vendor/Carrier/exclamation-circle-CSC.svg'
import {ReactComponent as CarrierEmail } from '../../assets/images/Vendor/Carrier/email-sent-CSC.svg'
import {ReactComponent as CarrierCheckboxes } from '../../assets/images/Vendor/Carrier/checkboxes-csc.svg';
import {ReactComponent as CarrierRatings } from '../../assets/images/Vendor/Carrier/ratings-CSC.svg';
import {ReactComponent as CarrierGraph } from '../../assets/images/Vendor/Carrier/graph-CSC.svg';
import {ReactComponent as CarrierLock } from '../../assets/images/Vendor/Carrier/lock-CSC.svg';
import {ReactComponent as CarrierProfile } from '../../assets/images/Vendor/Carrier/profile-CSC.svg';
import {ReactComponent as CarrierProfileAlert } from '../../assets/images/Vendor/Carrier/profile-alert.svg';
import {ReactComponent as CarrierUploadIcon } from '../../assets/images/Vendor/Carrier/upload-circle-csc.svg';
import {ReactComponent as CarrierClaimApprove} from '../../assets/images/Vendor/Carrier/claim-saved-csc.svg';
import {ReactComponent as CarrierClaimReject} from '../../assets/images/Vendor/Carrier/claim-cancelled-csc.svg';
import {ReactComponent as CarrierClaimReview} from '../../assets/images/Vendor/Carrier/claim-review-CSC.svg';

//Trane
import {ReactComponent as TraneProfile } from '../../assets/images/Vendor/Trane/profile-TR.svg';
import {ReactComponent as TraneEnroll } from '../../assets/images/Vendor/Trane/revenue-TR.svg';
//GE
import {ReactComponent as GESales } from '../../assets/images/Vendor/GE/dollar-sign-circle.svg';
import {ReactComponent as GEReports } from '../../assets/images/Vendor/GE/reports-circle.svg';
import {ReactComponent as GEResources } from '../../assets/images/Vendor/GE/resources-circle.svg';
import {ReactComponent as GEClaims } from '../../assets/images/Vendor/GE/tools-circle.svg';
import {ReactComponent as GEContractActive } from '../../assets/images/Vendor/GE/contract-active.svg';
import {ReactComponent as GEContractPending } from '../../assets/images/Vendor/GE/contract-pending.svg';
import {ReactComponent as GESaleIncomplete } from '../../assets/images/Vendor/GE/sale-incomplete.svg';
import {ReactComponent as GEAdvantage } from '../../assets/images/Vendor/GE/shield-check.svg';
import {ReactComponent as GEAdvantageCheck } from '../../assets/images/Vendor/GE/shield-circle-checkmark.svg';
import {ReactComponent as GESuccessful } from '../../assets/images/Vendor/GE/successfull.svg';
import {ReactComponent as GEContact } from '../../assets/images/Vendor/GE/contact.svg';
import {ReactComponent as GEEnroll } from '../../assets/images/Vendor/GE/revenue.svg';
import {ReactComponent as GEError } from '../../assets/images/Vendor/GE/exclamation-circle.svg';
import {ReactComponent as GEEmail } from '../../assets/images/Vendor/GE/email-sent.svg';
import {ReactComponent as GECheckboxes } from '../../assets/images/Vendor/GE/checkbox.svg';
import {ReactComponent as GERatings } from '../../assets/images/Vendor/GE/ratings.svg';
import {ReactComponent as GEHandsRevenue } from '../../assets/images/Vendor/GE/hand-revenue.svg';
import {ReactComponent as GEProfile } from '../../assets/images/Vendor/GE/profile.svg';
import {ReactComponent as GEProfileAlert } from '../../assets/images/Vendor/GE/profile-alert.svg';
import {ReactComponent as GEUploadIcon } from '../../assets/images/Vendor/GE/upload-circle.svg';
import {ReactComponent as GEPeople } from '../../assets/images/Vendor/GE/people.svg';
import {ReactComponent as GEHandshakeCircle } from '../../assets/images/Vendor/GE/handshake-circle.svg';
import {ReactComponent as GEPaperCircle } from '../../assets/images/Vendor/GE/paper-circle.svg';
import {ReactComponent as GEPeopleCircle } from '../../assets/images/Vendor/GE/people-circle.svg';
import {ReactComponent as GEProfileCircle } from '../../assets/images/Vendor/GE/profile-circle.svg';
import {ReactComponent as GEVerificationCircle } from '../../assets/images/Vendor/GE/verification-circle.svg';
import {ReactComponent as GEQuotation} from '../../assets/images/Vendor/GE/quote-mark.svg';
import {ReactComponent as GEClaimApprove} from '../../assets/images/Vendor/GE/claim-saved-GE.svg';
import {ReactComponent as GEClaimReject} from '../../assets/images/Vendor/GE/claim-cancelled-GE.svg';
import {ReactComponent as GEClaimReview} from '../../assets/images/Vendor/GE/claim-review-GE.svg';
//ICP
import {ReactComponent as ICPUploadIcon } from '../../assets/images/Vendor/ICP/upload-circle-pp.svg';
import {ReactComponent as ICPClaims } from '../../assets/images/Vendor/ICP/tools-circle-icp.svg';
import {ReactComponent as ICPSuccessful } from '../../assets/images/Vendor/ICP/successfull-icp.svg';
import {ReactComponent as ICPAdvantage} from '../../assets/images/Vendor/ICP/shield-check-icp.svg';
import {ReactComponent as ICPSaleIncomplete } from '../../assets/images/Vendor/ICP/sale-incomplete-icp.svg';
import {ReactComponent as ICPEnroll } from '../../assets/images/Vendor/ICP/revenue-icp.svg';
import {ReactComponent as ICPResources } from '../../assets/images/Vendor/ICP/resources-circle-icp.svg';
import {ReactComponent as ICPReports } from '../../assets/images/Vendor/ICP/reports-circle-icp.svg';
import {ReactComponent as ICPRatings } from '../../assets/images/Vendor/ICP/ratings-icp.svg';
import {ReactComponent as ICPProfile } from '../../assets/images/Vendor/ICP/profile-icp.svg';
import {ReactComponent as ICPProfileAlert } from '../../assets/images/Vendor/ICP/profile-alert-icp.svg';
import {ReactComponent as ICPError } from '../../assets/images/Vendor/ICP/exclamation-circle-icp.svg';
import {ReactComponent as ICPSales } from '../../assets/images/Vendor/ICP/dollar-sign-circle-icp.svg';
import {ReactComponent as ICPContact } from '../../assets/images/Vendor/ICP/contact-icp.svg';
import {ReactComponent as ICPContactActive } from '../../assets/images/Vendor/ICP/contract-active-icp.svg';
import {ReactComponent as ICPContactPending } from '../../assets/images/Vendor/ICP/contract-pending-icp.svg';
import {ReactComponent as ICPEmail } from '../../assets/images/Vendor/ICP/email-sent-icp.svg';
import {ReactComponent as ICPCheckbox } from '../../assets/images/Vendor/ICP/checkbox.svg';
import {ReactComponent as ICPCheckboxes } from '../../assets/images/Vendor/ICP/checkboxes-icp.svg';
import {ReactComponent as ICPCheckboxSelected } from '../../assets/images/Vendor/ICP/checkbox-selected-icp.svg';
import {ReactComponent as ICPClaimCancelled } from '../../assets/images/Vendor/ICP/claim-cancelled-icp.svg';
import {ReactComponent as ICPClaimReview } from '../../assets/images/Vendor/ICP/claim-review-icp.svg';
import {ReactComponent as ICPClaimSaved } from '../../assets/images/Vendor/ICP/claim-saved-icp.svg';
import {ReactComponent as ICPLock } from '../../assets/images/Vendor/ICP/lock-pp.svg';
import { ReactComponent as ICPPeople } from '../../assets/images/Vendor/ICP/people.svg';
import { ReactComponent as ICPHandsRevenue } from '../../assets/images/Vendor/ICP/hand-revenue.svg';
import { ReactComponent as ICPAdvantageCheck } from '../../assets/images/Vendor/ICP/shield-circle-checkmark.svg';
import { ReactComponent as ICPPaperCircle } from '../../assets/images/Vendor/ICP/enrollment.svg';
import { ReactComponent as ICPLoginPortal } from '../../assets/images/Vendor/ICP/login-portal.svg';
import { ReactComponent as ICPDealerBenefits } from '../../assets/images/Vendor/ICP/dealer-benefits.svg';
import { ReactComponent as ICPEnrollPaper } from '../../assets/images/Vendor/ICP/enroll-paper.svg';
import { ReactComponent as ICPShieldIcon } from '../../assets/images/Vendor/ICP/ComfortPromise_Icon.svg';
import { ReactComponent as ICPGraphIcon } from '../../assets/images/Vendor/ICP/graph-icp.svg';

import styles from './WhiteLabeling.module.css';

const BrandedIcon = ({accountName, iconType}) => {
    const getIcon = () => {

        const accountNameLower = () => {
            let accountNameLower = accountName.toLowerCase();
        };

        let accountNameToLower = accountName.toLowerCase();

        // REPEAT THIS "IF" FOR EACH NEW BRAND
        if (accountNameToLower === "lennox") {
            if (iconType.toLowerCase() === 'sales') return <LennoxSales/>
            if (iconType.toLowerCase() === 'reports') return <LennoxReports/>
            if (iconType.toLowerCase() === 'resources') return <LennoxResources/>
            if (iconType.toLowerCase() === 'claims') return <LennoxClaims/>
            if (iconType.toLowerCase() === 'active') return <LennoxContractActive />
            if (iconType.toLowerCase() === 'pending') return <LennoxContractPending />
            if (iconType.toLowerCase() === 'incomplete') return <LennoxSaleIncomplete />
            if (iconType.toLowerCase() === 'advantage') return <LennoxAdvantage />
            if (iconType.toLowerCase() === 'successful') return <LennoxSuccessful />
            if (iconType.toLowerCase() === 'contact') return <LennoxContact />
            if (iconType.toLowerCase() === 'enroll') return <LennoxEnroll />
            if (iconType.toLowerCase() === 'error') return <LennoxError />
            if (iconType.toLowerCase() === 'email') return <LennoxEmail />
            if (iconType.toLowerCase() === 'checkboxes') return <LennoxCheckboxes />
            if (iconType.toLowerCase() === 'ratings') return <LennoxRatings />
            if (iconType.toLowerCase() === 'graphs') return <LennoxGraph />
            if (iconType.toLowerCase() === 'lock') return <LennoxLock />
            if (iconType.toLowerCase() === 'profile') return <LennoxProfile />
            if (iconType.toLowerCase() === 'profilealert') return <LennoxProfileAlert />
            if (iconType.toLowerCase() === 'circleupload') return <LennoxUploadIcon />
            if (iconType.toLowerCase() === 'approved') return <LennoxClaimApprove />
            if (iconType.toLowerCase() === 'denied') return <LennoxClaimReject />
            if (iconType.toLowerCase() === 'review') return <LennoxClaimReview />
        }
        if (accountNameToLower === "rheem") {
          if (iconType.toLowerCase() === 'sales') return <RheemSales/>
          if (iconType.toLowerCase() === 'reports') return <RheemReports/>
          if (iconType.toLowerCase() === 'resources') return <RheemResources/>
          if (iconType.toLowerCase() === 'claims') return <RheemClaims/>
          if (iconType.toLowerCase() === 'active') return <RheemContractActive />
          if (iconType.toLowerCase() === 'pending') return <RheemContractPending />
          if (iconType.toLowerCase() === 'incomplete') return <RheemSaleIncomplete />
          if (iconType.toLowerCase() === 'advantage') return <RheemAdvantage />
          if (iconType.toLowerCase() === 'successful') return <RheemSuccessful />
          if (iconType.toLowerCase() === 'contact') return <RheemContact />
          if (iconType.toLowerCase() === 'enroll') return <RheemEnroll />
          if (iconType.toLowerCase() === 'error') return <RheemError />
          if (iconType.toLowerCase() === 'email') return <RheemEmail />
          if (iconType.toLowerCase() === 'checkboxes') return <RheemCheckboxes />
          if (iconType.toLowerCase() === 'ratings') return <RheemRatings />
          if (iconType.toLowerCase() === 'graphs') return <RheemGraph />
          if (iconType.toLowerCase() === 'lock') return <RheemLock />
          if (iconType.toLowerCase() === 'profile') return <RheemProfile />
          if (iconType.toLowerCase() === 'profilealert') return <RheemProfileAlert />
          if (iconType.toLowerCase() === 'circleupload') return <RheemUploadIcon />
          if (iconType.toLowerCase() === 'approved') return <RheemClaimApprove />
          if (iconType.toLowerCase() === 'denied') return <RheemClaimReject />
          if (iconType.toLowerCase() === 'review') return <RheemClaimReview />
        }
        if (accountNameToLower === "carrier") {
          if (iconType.toLowerCase() === 'sales') return <CarrierSales/>
          if (iconType.toLowerCase() === 'reports') return <CarrierReports/>
          if (iconType.toLowerCase() === 'resources') return <CarrierResources/>
          if (iconType.toLowerCase() === 'claims') return <CarrierClaims/>
          if (iconType.toLowerCase() === 'active') return <CarrierContractActive />
          if (iconType.toLowerCase() === 'pending') return <CarrierContractPending />
          if (iconType.toLowerCase() === 'incomplete') return <CarrierSaleIncomplete />
          if (iconType.toLowerCase() === 'advantage') return <CarrierAdvantage />
          if (iconType.toLowerCase() === 'successful') return <CarrierSuccessful />
          if (iconType.toLowerCase() === 'contact') return <CarrierContact />
          if (iconType.toLowerCase() === 'enroll') return <CarrierEnroll />
          if (iconType.toLowerCase() === 'error') return <CarrierError />
          if (iconType.toLowerCase() === 'email') return <CarrierEmail />
          if (iconType.toLowerCase() === 'checkboxes') return <CarrierCheckboxes />
          if (iconType.toLowerCase() === 'ratings') return <CarrierRatings />
          if (iconType.toLowerCase() === 'graphs') return <CarrierGraph />
          if (iconType.toLowerCase() === 'lock') return <CarrierLock />
          if (iconType.toLowerCase() === 'profile') return <CarrierProfile />
          if (iconType.toLowerCase() === 'profilealert') return <CarrierProfileAlert />
          if (iconType.toLowerCase() === 'circleupload') return <CarrierUploadIcon />
          if (iconType.toLowerCase() === 'approved') return <CarrierClaimApprove />
          if (iconType.toLowerCase() === 'denied') return <CarrierClaimReject />
          if (iconType.toLowerCase() === 'review') return <CarrierClaimReview />
        }
        if (accountNameToLower === "trane") {
          if (iconType.toLowerCase() === 'profile') return <TraneProfile />
          if (iconType.toLowerCase() === 'enroll') return <TraneEnroll />
        }
        if (accountNameToLower === "ge") {
          if (iconType.toLowerCase() === 'sales') return <GESales/>
          if (iconType.toLowerCase() === 'reports') return <GEReports/>
          if (iconType.toLowerCase() === 'resources') return <GEResources/>
          if (iconType.toLowerCase() === 'claims') return <GEClaims/>
          if (iconType.toLowerCase() === 'active') return <GEContractActive />
          if (iconType.toLowerCase() === 'pending') return <GEContractPending />
          if (iconType.toLowerCase() === 'incomplete') return <GESaleIncomplete />
          if (iconType.toLowerCase() === 'advantage') return <GEAdvantage />
          if (iconType.toLowerCase() === 'advantagecheck') return <GEAdvantageCheck />
          if (iconType.toLowerCase() === 'successful') return <GESuccessful />
          if (iconType.toLowerCase() === 'contact') return <GEContact />
          if (iconType.toLowerCase() === 'enroll') return <GEEnroll />
          if (iconType.toLowerCase() === 'error') return <GEError />
          if (iconType.toLowerCase() === 'email') return <GEEmail />
          if (iconType.toLowerCase() === 'checkboxes') return <GECheckboxes />
          if (iconType.toLowerCase() === 'ratings') return <GERatings />
          if (iconType.toLowerCase() === 'revenuehand') return <GEHandsRevenue />
          if (iconType.toLowerCase() === 'profile') return <GEProfile />
          if (iconType.toLowerCase() === 'profilealert') return <GEProfileAlert />
          if (iconType.toLowerCase() === 'circleupload') return <GEUploadIcon />
          if (iconType.toLowerCase() === 'people') return <GEPeople />          
          if (iconType.toLowerCase() === 'handshakecircle') return <GEHandshakeCircle />
          if (iconType.toLowerCase() === 'papercircle') return <GEPaperCircle />
          if (iconType.toLowerCase() === 'peoplecircle') return <GEPeopleCircle />
          if (iconType.toLowerCase() === 'profilecircle') return <GEProfileCircle />
          if (iconType.toLowerCase() === 'verificationcircle') return <GEVerificationCircle />
          if (iconType.toLowerCase() === 'quotation') return <GEQuotation />
          if (iconType.toLowerCase() === 'approved') return <GEClaimApprove />
          if (iconType.toLowerCase() === 'denied') return <GEClaimReject />
          if (iconType.toLowerCase() === 'review') return <GEClaimReview />
        }
        if (accountNameToLower === "icp") {
          if (iconType.toLowerCase() === 'sales') return <ICPSales />
          if (iconType.toLowerCase() === 'reports') return <ICPReports />
          if (iconType.toLowerCase() === 'resources') return <ICPResources />
          if (iconType.toLowerCase() === 'claims') return <ICPClaims />
          if (iconType.toLowerCase() === 'active') return <ICPContactActive />
          if (iconType.toLowerCase() === 'pending') return <ICPContactPending />
          if (iconType.toLowerCase() === 'incomplete') return <ICPSaleIncomplete />
          if (iconType.toLowerCase() === 'advantage') return <ICPAdvantage />
          if (iconType.toLowerCase() === 'successful') return <ICPSuccessful />
          if (iconType.toLowerCase() === 'contact') return <ICPContact />
          if (iconType.toLowerCase() === 'enroll') return <ICPEnroll />
          if (iconType.toLowerCase() === 'error') return <ICPError />
          if (iconType.toLowerCase() === 'email') return <ICPEmail />
          if (iconType.toLowerCase() === 'checkboxe') return <ICPCheckbox />
          if (iconType.toLowerCase() === 'checkboxes') return <ICPCheckboxes />
          if (iconType.toLowerCase() === 'ratings') return <ICPRatings />
          if (iconType.toLowerCase() === 'profile') return <ICPProfile />
          if (iconType.toLowerCase() === 'profilealert') return <ICPProfileAlert />
          if (iconType.toLowerCase() === 'circleupload') return <ICPUploadIcon />          
          if (iconType.toLowerCase() === 'checkboxselected') return <ICPCheckboxSelected />
          if (iconType.toLowerCase() === 'claimcancelled') return <ICPClaimCancelled />
          if (iconType.toLowerCase() === 'claimreview') return <ICPClaimReview />
          if (iconType.toLowerCase() === 'claimsaved') return <ICPClaimSaved />
          if (iconType.toLowerCase() === 'lock') return <ICPLock />
          if (iconType.toLowerCase() === 'people') return <ICPPeople />
          if (iconType.toLowerCase() === 'revenuehand') return <ICPHandsRevenue />
          if (iconType.toLowerCase() === 'advantagecheck') return <ICPAdvantageCheck />
          if (iconType.toLowerCase() === 'papercircle') return <ICPPaperCircle />
          if (iconType.toLowerCase() === 'verificationpaper') return <ICPEnrollPaper />
          if (iconType.toLowerCase() === 'login') return <ICPLoginPortal />
          if (iconType.toLowerCase() === 'dealer') return <ICPDealerBenefits />
          if (iconType.toLowerCase() === 'shield') return <ICPShieldIcon />
          if (iconType.toLowerCase() === 'graphs') return <ICPGraphIcon />
        }
        // FALLTHROUGH IF CLIENT NOT DEFINED OR NO MATCH FOUND
        if (iconType.toLowerCase() === 'sales') return <Sales/>
        if (iconType.toLowerCase() === 'reports') return <Reports/>
        if (iconType.toLowerCase() === 'resources') return <Resources/>
        if (iconType.toLowerCase() === 'claims') return <Claims/>
        if (iconType.toLowerCase() === 'active') return <ContractActive/>
        if (iconType.toLowerCase() === 'pending') return <ContractPending />
        if (iconType.toLowerCase() === 'incomplete') return <SaleIncomplete />
        if (iconType.toLowerCase() === 'advantage') return <Advantage />
        if (iconType.toLowerCase() === 'successful') return <Successful />
        if (iconType.toLowerCase() === 'contact') return <Contact />
        if (iconType.toLowerCase() === 'enroll') return <Enroll />
        if (iconType.toLowerCase() === 'error') return <Error />
        if (iconType.toLowerCase() === 'email') return <Email />
        if (iconType.toLowerCase() === 'checkboxes') return <Checkboxes />
        if (iconType.toLowerCase() === 'ratings') return <Ratings />
        if (iconType.toLowerCase() === 'graphs') return <Graph />
        if (iconType.toLowerCase() === 'lock') return <Lock />
        if (iconType.toLowerCase() === 'profile') return <Profile />
        if (iconType.toLowerCase() === 'circleupload') return <UploadIcon />
        if (iconType.toLowerCase() === 'approved') return <ClaimApprove />
        if (iconType.toLowerCase() === 'denied') return <ClaimReject />
        if (iconType.toLowerCase() === 'review') return <ClaimReview />
        // FALLTHROUGH IF NO MATCH ON CLIENT OR TYPE.
        return null;
    }
    
    return(
        <div className={styles.variableIconWrapper}>
            {getIcon()}
        </div>
    )
}

export default BrandedIcon;