import { calculateLabor } from "./labor";
import { calculateParts } from "./parts";
import { calculateLaborSpecialRate } from "./laborSpecialRate";
import { calculateTrip } from "./trip";
import { calculateTax } from "./tax";
import { calculatePartsMarkup } from "./partsMarkup";

export const calc = {
    labor: calculateLabor,
    parts: calculateParts,
    partsMarkup: calculatePartsMarkup,
    laborSpecialRate: calculateLaborSpecialRate,
    trip: calculateTrip,
    tax: calculateTax
};
