import React, { useCallback, useState, useRef, useEffect, useContext } from "react";
import { Breadcrumb, BreadcrumbItem, Container, Card, Row, Col } from "reactstrap";
import VendorDetails from "./VendorDetails";
import DistributorDetails from "./DistributorDetails";
import SimpleReactValidator from "simple-react-validator";
import AdvantageCampaign from "./AdvantageCampaigns";
import styles from "./VendorCreation.module.css";
import InsuranceDocument from "./InsuranceDocument";
import FederalLicensing from "./FederalLicensing";
import TermsAndCondition from "./TermsAndConditions";
import Enrollment from "./Enrollment";
import SpinnerButton from "../shared/SpinnerButton/SpinnerButton";
import { SessionContext } from '../../context/SessionContext';
import moment from 'moment';
import { Form } from "react-bootstrap";
import DownloadButton from "../shared/IconButtons/DownloadButton";
import { SendMail, SendMailByAccount, emailModel, serviceModel, getEmailTypes } from '../../components/Global/helpers';
import { getBrandContent } from "../BrandingWhiteLabel/BrandContent";
import { Link } from "react-router-dom";

// should be in order of display
const sessionStorageNames = [
    "Company Info",
    "Company Preferences",
    "Advantage Enrollment",
    "Insurance Docs",
    "Federal Docs",
    "Terms And Conditions"
];

const VendorCreation = ({ data }) => {
    const [breadCrumbs, setBreadCrumbs] = useState(["Company Info"]);
    const [activeCard, setActiveCard] = useState("Company Info");
    const [errors, setErrors] = useState({});
    const [isContinue, setIsContinue] = useState(false);
    const [vFired, setVFired] = useState(false);
    const [hasAdvantage, setHasAdvantage] = useState(false);
    const [vendorId, setVendorId] = useState(0);
    const [accountId, setAccountId] = useState(0);
    const childRef = useRef();
    const [docUrl, setDocurl] = useState();
    const [viewDocUrl, setViewDocUrl] = useState();

    const [, forceUpdate] = useState()
    const validator = useRef(new SimpleReactValidator({
        validators: {
            valid_pass: {
                message: 'The :attribute is invalid.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/) && params.indexOf(val) === -1
                },
                required: true
            }
        },
        element: (message) => (
            <div className="errorMsg" aria-live="polite">
                {message.charAt(0).toUpperCase() + message.slice(1)}
            </div>
        ),
        messages: {
            required: ":attribute is required.",
        },
        autoForceUpdate: { forceUpdate: forceUpdate }
    }));

    const [state, setState] = useState({
        industries: {}
    });
    const sessionContext = useContext(SessionContext);
    const { accountName } = sessionContext;
    const [showSpinner, setShowSpinner] = useState(false);
    const [isAdCampaignTriggered, setIsAdCampaignTriggered] = useState(false);
    const [emailTypes, setEmailTypes] = useState(null);

    useEffect(() => {
        let savePoint = "";
        let savePointExist = false;
        const sessionBreadcrumbs = JSON.parse(sessionStorage.getItem('activeBreadcrumbs'))
        const getEmailTemplateTypes = async () => {
            const types = await getEmailTypes(accountName);
            setEmailTypes(types);
        }
        getEmailTemplateTypes();

        getIndustries();

        var adCampaignButtonState = JSON.parse(sessionStorage.getItem("AdCampaignButton"));
        setIsAdCampaignTriggered(adCampaignButtonState?.isTriggered);

        var res = sessionStorageNames.map((x) => {
            var storage = JSON.parse(sessionStorage.getItem(x));

            if (storage?.isCleared) {
                savePoint = x;
                savePointExist = true;
            }
        });

        if (savePointExist) {
            let imgSession = {};

            // When an image has been uploaded previously under Insurance tab
            // the the page has been refreshed. 
            if (savePoint === "Federal Docs") {
                imgSession = JSON.parse(sessionStorage.getItem("insuranceDoc"));

                if (imgSession) {
                    savePoint = "Insurance Docs";
                    sessionStorage.setItem("insuranceDoc", JSON.stringify(false));
                }
            }

            if (sessionBreadcrumbs?.activeBreadcrumbs) {
                setBreadCrumbs(sessionBreadcrumbs?.activeBreadcrumbs)
            }

            setActiveCard(savePoint);
        }
    }, []);

    const getAccountId = async () => {
        try {
            var vendorDetails = JSON.parse(sessionStorage.getItem("vendorDetails"));
            const response = await fetch(`account/GetAccountIdByNameAndCountry/${sessionContext.accountName}/${vendorDetails.country}`);
            const accountId = await response.json();

            if (accountId != null) {
                setAccountId(accountId);
                return true;
            }
            return false;
        } catch {
            return false;
        }
    }

    const onContinue = async () => {
        setIsContinue(true);
        setShowSpinner(true);
        const checkDetailsError = (activeCard === "Company Info" || activeCard === "Advantage Enrollment") ? childRef.current.validateInputs() : true;
        const duplicationError = (activeCard === "Company Info" || activeCard === "Federal Docs") ? await childRef.current.checkDuplicates() : true;
        if (validator && validator.current.allValid() && checkDetailsError && duplicationError) {
            validator.current.hideMessages();
            validator.current.purgeFields()
            setErrors({});
            setVFired(false);
            getNextActiveCard();
        }
        else {
            setShowSpinner(false);
            setIsContinue(false);
            setVFired(true);
            if (activeCard === "Company Preferences") {
                for (const key in validator.current.errorMessages) {
                  validator.current.showMessageFor(key);
                }
            }
            setErrors(validator.current.errorMessages);
        }
    }


    const onSaveVendor = useCallback(() => {
        //for save functionality
    }, [activeCard]);


    const onSkipStep = () => {
        getNextActiveCard();
    }


    const getNextActiveCard = async () => {
        let isContinue = false;
        let isClearedToContinue = true;
        let nextActiveCard = "";
        const vendorSessionData = JSON.parse(sessionStorage.getItem('vendorDetails'));
        const vendorType = vendorSessionData.vendorType;
        const dealerSessionData = JSON.parse(sessionStorage.getItem('questionData'));
        const isCNS = dealerSessionData?.dealerQuestions?.questions?.find(data => data.name === "isCNS")?.answer;
        const account = sessionContext?.accountName?.toLowerCase()

        switch (activeCard) {
            case "Company Info":
                const res = await getAccountId();
                if (res) {
                    nextActiveCard = "Company Preferences";
                }
                setActiveBreadcrumbs("Company Preferences", "continue")
                break;

            case "Company Preferences":
                if (vendorType.includes("dealer") && (sessionContext.accountName !== "Trane")) {
                    nextActiveCard = "Advantage Enrollment";
                }
                else if ((vendorType.includes("dealer") && vendorType.includes("servicer")) ||
                    (vendorType.includes("servicer") || isCNS === "Yes")) {
                    nextActiveCard = "Insurance Docs"
                }
                else {
                    nextActiveCard = (account === "rheem" || account === "carrier") ? "Terms And Conditions" : "Enrollment";
                }
                if (nextActiveCard === "Advantage Enrollment") {
                    isContinue = isAdCampaignTriggered ? false : true;
                }
                setActiveBreadcrumbs(nextActiveCard, "continue")
                getDocument();
                break;

            case "Advantage Enrollment":
                setHasAdvantage(true);
                nextActiveCard = ((vendorType.includes("dealer") && vendorType.includes("servicer")) ||
                    (vendorType.includes("servicer") || isCNS === "Yes")) ?
                    "Insurance Docs" :
                    ((account === "rheem" || account === "carrier") ?
                        "Terms And Conditions" :
                        "Enrollment");
                setActiveBreadcrumbs(nextActiveCard, "continue")
                break;

            case "Insurance Docs":
                nextActiveCard = "Federal Docs";
                setActiveBreadcrumbs("Federal Docs", "continue")               
                break;

            case "Federal Docs":
                if (account === "rheem" || account === "carrier") {
                    nextActiveCard = "Terms And Conditions";
                    setActiveBreadcrumbs(nextActiveCard, "continue")
                } else {
                    const success = await onEnrollVendor();
                    if (success)
                        nextActiveCard = "Enrollment";
                }
                break;

            case "Terms And Conditions":
                const success = await onEnrollVendor();
                if (success)
                    nextActiveCard = "Enrollment";
                break;

            default:
                return;
        }

        setActiveCard(nextActiveCard);
        setIsContinue(isContinue);
        setShowSpinner(false);

        if (nextActiveCard != "") {
            sessionStorage.setItem(nextActiveCard, JSON.stringify({ "isCleared": isClearedToContinue }));
        }
    }

    const getIndustries = () => {
        fetch('asset/AssetIndustry/GetAll')
            .then(res => res.json())
            .then(data => {
                let industries = data.map(x => {
                    return {
                        data: x.assetIndustryId,
                        display: `${x.industryName} (${x.industryAbbreviation})`,
                        value: x.industryAbbreviation
                    }
                });

                sessionStorage.setItem("industryList", JSON.stringify({ industries }));
            });
    }

    const goBack = () => {
        let toggle = false;
        validator.current.purgeFields();

        switch (activeCard) {
            case 'Company Preferences':
                setActiveCard('Company Info');
                break;

            case 'Advantage Enrollment':
                setActiveCard('Company Preferences');
                setIsContinue(false);
                break;

            case 'Insurance Docs':
                toggle = goBackInsuranceTab(toggle)
                break;

            case 'Federal Docs':
                const vendorSessionData = JSON.parse(sessionStorage.getItem('vendorDetails'));
                const vendorType = vendorSessionData.vendorType;
                const dealerSessionData = JSON.parse(sessionStorage.getItem('questionData'));
                const isCNS = dealerSessionData?.dealerQuestions?.questions?.find(data => data.name === "isCNS")?.answer
                if ((vendorType.includes("dealer") && vendorType.includes("servicer")) ||
                (vendorType.includes("servicer") || isCNS === "Yes")) {
                    setActiveCard('Insurance Docs');
                } else {
                    toggle = goBackInsuranceTab(toggle)
                }
                break;
            case 'Terms And Conditions':
                setActiveCard('Federal Docs');
                break;
        }

        setIsContinue(toggle);
    }


    const goBackInsuranceTab = (toggle) => {
        const vendorSessionData = JSON.parse(sessionStorage.getItem('vendorDetails'));
        const vendorType = vendorSessionData.vendorType;
        if (!vendorType.includes("dealer") || (sessionContext.accountName === "Trane")) {
            setActiveCard('Company Preferences');
        }
        else {
            setHasAdvantage(false);
            setActiveCard('Advantage Enrollment');
            toggle = !isAdCampaignTriggered;
        }
        return toggle
    }
    
    const backToInsurance = (card) => {
        setActiveCard(card);
    }

    const sendEnrollmentEmail = async (emailType) => {

        const programName = getBrandContent(sessionContext.accountName).programName;
        const programPhone = getBrandContent(sessionContext.accountName).programPhone;
        const advantageProgramName = getBrandContent(sessionContext.accountName).advantageProgramName

        const vendorDetails = JSON.parse(sessionStorage.getItem("vendorDetails"));

        let email = new emailModel();
        let service = new serviceModel();

        email.recipients = [vendorDetails.contacts[0].email];
        email.cc = [];
        email.bcc = [];
        email.isBodyHtml = true;
        email.emailType = emailType

        service.programName = programName;
        service.programPhoneNumber = programPhone;
        service.advantageAccountName = advantageProgramName;
        service.companyName = vendorDetails?.dbaName;
        service.vendorId = service.vendorId !== null ? vendorId.toString() : "";
        service.partyId = service.partyId !== null ? service.partyId : 0;
        
        SendMail('hvac', email, service);
    }

    const toggleContinue = (toggle, src) => {
        if (!toggle && src == 'Advantage Enrollment') {
            sessionStorage.setItem("AdCampaignButton", JSON.stringify({ "isTriggered": true }));
            setIsAdCampaignTriggered(true);
        }

        setIsContinue(toggle)
    }

    const onEnrollVendor = async () => {
        try {
            const vendorDetails = JSON.parse(sessionStorage.getItem("vendorDetails"));
            const questionDetails = JSON.parse(sessionStorage.getItem("questionData"));
            const advantageDetails = JSON.parse(sessionStorage.getItem("advSessionStorage"));
            const insuranceDetails = JSON.parse(sessionStorage.getItem("insuranceDocument"));
            
            let formData = {
                AccountName: sessionContext.accountName,
                CompanyName: vendorDetails?.companyName,
                DbaName: vendorDetails?.dbaName,
                isDistributor: vendorDetails?.vendorType.includes("distributor"),
                isDealer: getIsDealer(),
                isServicer: getIsServicer(),
                ClientDealerId: vendorDetails?.clientDealerId,
                CountryId: vendorDetails?.country,
                LanguageId: vendorDetails?.language[0].value,
                CompanyPhone: vendorDetails?.companyPhone,
                CompanyFax: vendorDetails?.companyFax,
                IsMailingAddPhysical: vendorDetails?.isMailingAddPhysical,
                IsBillingAddPhysical: vendorDetails?.isBillingAddPhysical,
                PhysicalAddress: vendorDetails?.physicalAddress,
                MailingAddress: vendorDetails?.isMailingAddPhysical ? vendorDetails?.physicalAddress : vendorDetails?.mailingAddress,
                BillingAddress: vendorDetails?.isBillingAddPhysical ? vendorDetails?.physicalAddress : vendorDetails?.billingAddress,
                Contacts: vendorDetails?.contacts?.map(c => {
                    return {
                        name: c.name,
                        phoneNumber: c.phoneNumber,
                        email: c.email,
                        isPrimary: c.contactRadio,
                        contactTypes: c.contactTypes,
                        commPreferences: c.commPreferences
                    };
                }),
                ReferenceType: vendorDetails?.reference === "" ? null : vendorDetails?.reference,
                ReferenceContact: vendorDetails?.contactName,
                SellingDistributor: questionDetails?.distributorQuestions?.questions[0].answer === 'Yes',
                PaymentMethod: questionDetails?.distributorQuestions?.questions[0]?.options[0]?.questions[1]?.answer,
                PreferredServicerName: questionDetails?.dealerQuestions?.questions[1]?.options[1]?.questions[0]?.answer,
                PreferredServicerPhone: questionDetails?.dealerQuestions?.questions[1]?.options[1]?.questions[1]?.answer,
                PreferredServicerEmail: questionDetails?.dealerQuestions?.questions[1]?.options[1]?.questions[2]?.answer,
                ServicingAll: getServicerInfo("servicing") === 'Yes',
                HasCampaign: hasAdvantage,
                TierId: advantageDetails?.stateValue?.planState?.selectedLabor,
                CoverageTypeId: advantageDetails?.stateValue?.planState?.selectedCoverage,
                TermId: advantageDetails?.stateValue?.planState?.selectedYears,
                ExcludeRncflag: advantageDetails?.stateValue?.excludeRncflag === "" ? null : advantageDetails?.stateValue?.excludeRncflag,
                CampaignStartDate: new Date(new moment(advantageDetails?.stateValue?.startDate, vendorDetails?.country === 2 ? "DD/MM/YYYY" : "MM/DD/YYYY").format("MM/DD/YYYY")),
                CampaignEndDate: new Date(new moment(advantageDetails?.stateValue?.endDate, vendorDetails?.country === 2 ? "DD/MM/YYYY" : "MM/DD/YYYY").format("MM/DD/YYYY")),
                InsuredAmount: insuranceDetails?.formData?.insuredAmount?.replace(/[^a-z0-9]/gi, ''),
                PolicyNumber: insuranceDetails?.formData?.policyNumber,
                EmployeeCount: insuranceDetails?.formData?.NoofEmp,
                IndustryCoverage: getServicerInfo("industry"),
                CoverageZip: getServicerInfo("zip"),
                CoverageRadius: getServicerInfo("radius"),
                FederalDocs: getUploadedDocs("federalDoc"),
                LegalInfos: getLegalInfos(),
                InsuranceDocs: getUploadedDocs("insuranceDoc"),
                AccountEmail: vendorDetails?.email,
                AccountUserName: vendorDetails?.contacts?.find(x => x.contactRadio == true)?.name ?? "",
                AccountUserPassword: vendorDetails?.newPassword,
            }

            const settings = {
                method: 'POST',
                body: JSON.stringify(formData),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            }

            const response = await fetch(`party/VendorCreation`, settings);
            if (response.ok) {
                const responseData = await response.json();

                if (getIsDealer() && responseData.successful) {
                    setVendorId(responseData.dealer.dealerId);
                }
                else if (getIsServicer() && responseData.successful) {
                    setVendorId(responseData.servicer.servicerId);
                }
                else if (vendorDetails?.vendorType.includes("distributor") && responseData.successful) {
                    setVendorId(responseData.distributor.distributorId);
                }
                else {
                    return false;
                }
            } else {
                return false;
            }
        } catch {
            return false;
        }

        return true;
    }

    const getIsServicer = () => {
        const vendorDetails = JSON.parse(sessionStorage.getItem("vendorDetails"));
        const questionDetails = JSON.parse(sessionStorage.getItem("questionData"));

        if (vendorDetails?.vendorType?.includes("servicer") && questionDetails?.dealerQuestions?.questions[1]?.answer !== "No") return true;

        if (vendorDetails?.vendorType?.includes("dealer") && questionDetails?.dealerQuestions?.questions[1]?.answer === "Yes") return true;

        return false;
    }

    const getIsDealer = () => {
        const vendorDetails = JSON.parse(sessionStorage.getItem("vendorDetails"));
        const questionDetails = JSON.parse(sessionStorage.getItem("questionData"));

        if (vendorDetails?.vendorType.includes("dealer")) return true;

        if (questionDetails?.distributorQuestions?.questions[0]?.answer === "Yes") return true;

        return false;
    }

    const getServicerInfo = (question) => {
        const vendorDetails = JSON.parse(sessionStorage.getItem("vendorDetails"));
        const questionDetails = JSON.parse(sessionStorage.getItem("questionData"));
        let number = 1;
        switch (question) {
            case "servicing": number = 0; break;
            case "industry": number = 1; break;
            case "zip": number = 2; break;
            case "radius": number = 3; break;
            default: number = 1; break;
        }

        if (vendorDetails?.vendorType.includes("dealer")) {
            let answers = questionDetails?.dealerQuestions?.questions[1]?.answer === "Yes" ?
                questionDetails?.dealerQuestions?.questions[1]?.options[0]?.questions[number]?.answer : null;
            return answers;
        } else {
            return questionDetails?.servicerQuestions?.questions[number]?.answer;
        }
    }

    const getDocument = async () => {
        try {
            const vendorDetails = JSON.parse(sessionStorage.getItem("vendorDetails"));

            vendorDetails.isDistributor = vendorDetails?.vendorType.includes("distributor");
            vendorDetails.isDealer = vendorDetails?.vendorType.includes("dealer");
            vendorDetails.isServicer = vendorDetails?.vendorType.includes("servicer");           

            fetch("dealer/getdealerTcsDoc/" + accountId + "/" + vendorDetails.physicalAddress.State + "/" +
                vendorDetails.isDistributor + "/" + vendorDetails.isDealer + "/" + vendorDetails.isServicer)
                .then(res => {
                    if (res.status === 200) {
                        setViewDocUrl(res?.url)
                        return res.arrayBuffer()
                    }
                    else {
                        return undefined;
                    }
                })
                .then(data => {
                    if (data !== undefined) {
                        var file = new Blob([data], { type: 'application/pdf' });
                        var fileURL = URL.createObjectURL(file);
                        setDocurl(fileURL);
                    }
                    else {
                        console.log('error');
                    }
                });
                   
        }
        
        catch (error)
        {
            console.log(error);
        }

    }

    
    // get uploaded docs.
    const getUploadedDocs = (type) => {
        const contextDocs = sessionContext.getUploadedDocs();
        const docs = contextDocs[type];
        let uploadedDocs = [];

        if (docs) {
            if (Array.isArray(docs)) {
                docs.forEach((x) => {
                    uploadedDocs.push(x);
                });
            }
        }

        return uploadedDocs;
    }

    const getLegalInfos = () => {
        const fdSession = JSON.parse(sessionStorage.getItem("federalDetails"));
        const taxList = fdSession?.taxList;
        const formData = fdSession?.formData;

        if (fdSession) {
            const vdSession = JSON.parse(sessionStorage.getItem("vendorDetails"));
            const dbaName = vdSession?.dbaName ?? null;
            let legalInfo = [];
            let newData = [];

            if (formData) {
                newData = { ...formData, dbaName: dbaName }
                legalInfo = updateLegalInfoList(legalInfo, formData);
            }

            if (Array.isArray(taxList)) {
                taxList.forEach((t) => {
                    newData = t;
                    newData.registeredName = formData?.taxForm;
                    newData.dbaName = dbaName;

                    legalInfo = updateLegalInfoList(legalInfo, newData);
                })
            }

            return legalInfo;
        }

        return null;
    }

    const updateLegalInfoList = (legalInfoList, newData) => {
        let updatedList = legalInfoList;

        updatedList.push({
            partyId: null,
            registeredName: newData?.taxForm ?? null,
            dba: newData.dbaName ?? null,
            licenseNumber: newData?.licenseNo ?? null,
            taxId: newData?.taxFormName ?? null,
            taxTypeCode: newData?.taxStatus ?? null,
            taxIdTypeId: null,
        });

        return updatedList;
    }

    const checkForUpdatedBreadcrumbs = (currentBreadcrumbs, activeCrumb) => {
        const sessionCrumbs = JSON.parse(sessionStorage.getItem("activeBreadcrumbs"));
        const crumbsArray = sessionCrumbs?.activeBreadcrumbs;
        let updatedCrumbs = currentBreadcrumbs;
        if (crumbsArray?.length > 0 && activeCard === "Company Preferences") {
            if (
                (activeCrumb === "Advantage Enrollment" &&
                    !crumbsArray?.includes("Advantage Enrollment")) ||
                (activeCrumb === "Insurance Docs" &&
                    !crumbsArray?.includes("Insurance Docs")) ||
                (crumbsArray?.includes("Advantage Enrollment") &&
                    crumbsArray?.includes("Insurance Docs"))
            ) {
                updatedCrumbs = [...breadCrumbs.slice(0, 2), activeCrumb];
            }
        }
        return updatedCrumbs;
    };

    const setActiveBreadcrumbs = (activeCrumb, action) => {
        let updatedBreadcrumbs = [];        
        if (activeCrumb) {
            setActiveCard(activeCrumb);
            const uniqueBreadcrumbs = [...new Set(breadCrumbs)];
            if (action === 'continue') {
                if (uniqueBreadcrumbs.includes(activeCrumb)) {
                    updatedBreadcrumbs = [...uniqueBreadcrumbs]
                } else {
                    updatedBreadcrumbs = [...uniqueBreadcrumbs, activeCrumb]
                }
                updatedBreadcrumbs = checkForUpdatedBreadcrumbs(updatedBreadcrumbs, activeCrumb)
            } else {
                updatedBreadcrumbs = [...uniqueBreadcrumbs]
            }          

            setBreadCrumbs([...updatedBreadcrumbs]);
            sessionStorage.setItem('activeBreadcrumbs', JSON.stringify({ "activeBreadcrumbs": [...updatedBreadcrumbs] }));
        }
    }

    return (
        <>
            <Container className="contentWrapper">
                {breadCrumbs.length > 1 && activeCard !== 'Enrollment' &&
                    <Breadcrumb className={styles.breadcrumb}>
                        {breadCrumbs.map((crumb, i) => {
                            const isActive = crumb === activeCard;
                            return (
                                <BreadcrumbItem active={isActive}>
                                    {isActive ? crumb : <Link to="#" onClick={() => setActiveBreadcrumbs(crumb, "back")}>{crumb}</Link>}
                                </BreadcrumbItem>
                            );
                        })}
                    </Breadcrumb>
                }
                <Card>
                    {activeCard === "Company Info" && (
                        <VendorDetails validator={validator} errors={errors} ref={childRef} vendorname={data?.name} accountName={accountName} isFired={vFired} />
                    )}
                    {activeCard === "Company Preferences" && (
                        <DistributorDetails validator={validator?.current} ref={childRef} errors={errors} />
                    )}
                    {activeCard === "Advantage Enrollment" && (
                        <AdvantageCampaign validator={validator?.current} ref={childRef} errors={errors} accountId={accountId} toggleContinue={toggleContinue} />
                    )}
                    {activeCard === "Insurance Docs" && (
                        <InsuranceDocument validator={validator?.current} errors={errors} />
                    )}
                    {activeCard === "Federal Docs" && (
                        <FederalLicensing validator={validator?.current} errors={errors}  ref={childRef}/>
                    )}
                    {activeCard === "Terms And Conditions" && (
                        <TermsAndCondition
                            validator={validator?.current}
                            errors={errors}
                            document={docUrl}
                            viewDocument={viewDocUrl}
                        />
                    )}
                    {
                        activeCard === "Enrollment" && (
                            <Enrollment validator={validator} errors={errors} vendorId={vendorId} sendEnrollmentEmail={sendEnrollmentEmail} emailTypes={emailTypes} backToInsurance={backToInsurance}/>
                        )
                    }
                    {activeCard === "Advantage Enrollment" && (
                        <div className="mr-4 mb-4">
                            <button className={`btnLink justify-content-end pr-4 mr-3 w-100 h-100`} onClick={onSkipStep}>
                                Skip This Step
                            </button>
                        </div>
                    )}
                </Card>
                {activeCard !== "Enrollment" && (
                    <div className="d-inline-block mb-3 w-100">
                        {/* 16/9/2022- commented due to show with dealers will be reverted once after that */}
                        {/* <div className={`float-left ${styles.saveBtn}`}>
                <button className={`btn-secondary ${styles.saveBtn}`} onClick={onSaveVendor}>
                    Save For Later
                </button>
                </div> */}
                        {activeCard !== "Company Info" && (
                            <div className={`float-left ${styles.saveBtn}`}>
                                <button className={`btn-secondary ${styles.saveBtn}`} onClick={goBack}>
                                    Back
                                </button>
                            </div>
                        )}
                        <div className={`float-right d-flex ${styles.btnWrapper} ${styles.saveBtn}`}>
                            {/*9/20/2022 commenting out Upload Later for dealer show, add back in later*/}
                            {/*{(activeCard === "AdCampaign" || activeCard === "Federal") && (*/}
                            <SpinnerButton
                                className={`${styles.continueBtn} ${styles.saveBtn}`}
                                onClick={onContinue}
                                disabled={isContinue}
                                spinning={showSpinner}
                                type={"primary"}
                                text={activeCard === "Terms And Conditions" ? "Accept" : "Continue"}
                            />
                        </div>
                    </div>
                )}
            </Container>
        </>
    );
}
export default VendorCreation;
