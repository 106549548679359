export const widthFormatter = (width) => {
    const widths = {
        dropdownCol: '150px',
        rateTypeCol: '65px',
        priceCol: '50px',
        repairCodeCol: '94px',
        percCol: '50px',
        totalCol: '80px',
        totalLaborCol: '80px',
        totalPriceCol: '70px',
        tripCol: '150px',
        qtyCol: '10px',
        qtyTripCol: '10px',
        amountCol: '100px',
        hrsCol: '50px',
        assetCol: '225px',
        assetPartsCol: '180px',
        oemCol: '130px',
        componentCol: '225px',
        deleteCol: '36px',
        deleteTripCol: '44px',
        reasonCol: '225px',
        tripReasonCol: '250px',
        repairColumn: '250px',
        description: '135px',
        dropCol: '20px',
        dummyCol: '261px',
        dummyTripCol: '325px',
        tripRateCol: '80px',
        invoiceTotalCol: '80px',
        partNumberCol: '55px',
        partsPriceCol: '80px',
        totalPartsCol:'61px',
        totalTripCol:'61px',
        assetDescCol:'200px'
    }

    return {
        width: widths[width],
    }
};