import React, { useState, useCallback, useContext } from "react";
import Accordion from "../../shared/Accordion/Accordion";
import { FormGroup } from 'reactstrap';
import { ReactComponent as SearchIcon } from '../../../assets/images/search.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg';
import styes from './Faqs.module.css';
import { Link } from 'react-router-dom';
import { getBrandContent } from '../../BrandingWhiteLabel/BrandContent';
import { SessionContext } from "../../../context/SessionContext";

const AdvantageProgramFaqs = ({ changeTab }) => {

  const [activeAccord, setActiveAccord] = useState(-1);
  const [search, setSearch] = useState("");
  const [tempSearch, setTempSearch] = useState("");
  const context = useContext(SessionContext);
  const { accountName } = context;
  const currentBrand = getBrandContent(accountName);

  const onToggle = useCallback((id) => {
    if (id === activeAccord) {
      setActiveAccord(-1);
    }
    else {
      setActiveAccord(id);
    }
  }, [activeAccord, setActiveAccord]);

  const isLennox = currentBrand.clientName.toLowerCase() === 'lennox';
  const isCarrier = currentBrand.clientName.toLowerCase() === 'carrier';
  const isRheem = currentBrand.clientName.toLowerCase() === 'rheem';
  const isICP = currentBrand.clientName.toLowerCase() === 'icp';

  const lennoxAccordionContents = [
    {
      bodyContent: `To receive the significant discounts in our ${currentBrand.advantageProgramName} program, we do require a minimum of 30 days of participation.`,
      title: `How long must I stay enrolled in the ${currentBrand.advantageProgramName} program?`
    },
    {
      bodyContent: <>
        <p>
          If you enroll and have a campaign in the {currentBrand.programName} {currentBrand.advantageProgramName} that
          applies based on product install date, your pre-selected labor warranty term and reimbursement tier will be
          automatically offered and applied during the product registration process via WarrantyYourWay.com.
        </p>
        <p className="mb-0">
          Important: If the homeowner is registering on their own and you are a participant of the {currentBrand.advantageProgramName} program,
          customers will be prompted to contact you directly regarding their {currentBrand.programName} labor warranty.
          You will be responsible for entering the labor warranty through the {currentBrand.programName} dealer portal via LennoxPros.
        </p>
      </>,
      title: `Do I need to manually enter warranties if I enroll in ${currentBrand.advantageProgramName}?`
    },
    {
      bodyContent: `Through the WarrantyYourWay.com product registration and warranty selection site, you will have the ability to purchase upgraded ${currentBrand.programName} labor coverages that may be needed.`,
      title: `What do I do when my customer upgrades to a longer term labor warranty than what I pre-selected for ${currentBrand.advantageProgramName} (i.e. you pre-selected for 3 yr term, but a customer wants 10 yrs)?`
    },
    {
      bodyContent: "Regulations differ by state, but to be safe and abide by state regulatory requirements, the cost of coverage should be listed on your sales invoice paperwork to your customer. If the customer declines the coverage, that cost would then need to be deducted from your bid price to your customer.",
      title: "When selling the coverage to my customer, am I required to list the cost of the coverage on my sales invoice?"
    },
    {
      bodyContent: `If any customers decline the labor warranties offered, then the cost of the labor warranties need to be deducted from the total sale invoice amounts.  During the product registration and warranty coverage selection process via WarrantyYourWay.com simply decline the ${currentBrand.advantageProgramName} warranty offers at that time.`,
      title: "What do I do when my customer declines coverage?"
    },
    {
      bodyContent: `No, commercial equipment will not be included in the ${currentBrand.advantageProgramName} processing, but commercial warranties can be purchased via the ${currentBrand.programName} website.`,
      title: `Is commercial equipment available for ${currentBrand.advantageProgramName}?`
    },
    {
      bodyContent: `Depending on your payment status with Lennox, you will be required to pay for warranties via credit card, or other electronic method, or you’ll be invoiced by Lennox for ${currentBrand.advantageProgramName} sales.`,
      title: `How do I remit payment for ${currentBrand.advantageProgramName} plans?`
    },
    {
      bodyContent: `You will be able to extend your ${currentBrand.advantageProgramName} selection coverage expiration date during the selection campaign and can add new campaign options and date parameters.`,
      title: "Am I able to change the date parameters originally set before the end of the campaign?"
    },
    {
      bodyContent: `${currentBrand.advantageProgramName} labor warranty transactions are real-time during product registration and warranty coverage selection process via WarrantyYourWay.com.`,
      title: `How often will ${currentBrand.advantageProgramName} plans be processed from my customers’ product registrations?`
    },
    {
      bodyContent: "Yes, wait periods do apply. For registrations completed within sixty (60) days of installation, the wait period is ninety (90) days from the installation date. Otherwise, the coverage will begin ninety-one (91) days from the purchase date.",
      title: `Is there a wait period on ${currentBrand.advantageProgramName} contracts?`
    },
    {
      bodyContent: "Consumers should expect to receive their certificates of coverage approximately 14 days after registration is complete.",
      title: "When will customers receive certificates of coverage?"
    },
    {
      bodyContent: `${currentBrand.advantageProgramName} plans follow the same cancellation procedure as other ${currentBrand.programName} labor plans. Contracts can be canceled by filling out the cancellation form, including the customer’s signature, and sending to ${currentBrand.programName} for processing. Contracts can be canceled within 60 days with a full refund, and cancellations processed after 60 days will be pro-rated based upon time lapsed and less claims paid.`,
      title: `How do I cancel ${currentBrand.advantageProgramName} plans?`
    },
    {
      bodyContent: (<>Yes, all {currentBrand.programName} labor warranties are fully transferable at no cost. To transfer coverage, download the <Link onClick={() => changeTab(0, { data: 'contractManagement', display: "Contract Management" })}>transfer form</Link> and submit according to the instructions on the form. Once the transfer is complete, an updated copy of the labor warranty will be issued to the new customer.  Please note: Transfer Forms require original customer’s signatures, or the transfer request will not be accepted.</>),
      title: `Are ${currentBrand.advantageProgramName} labor warranties transferable? If yes, how?`
    },
    {
      bodyContent: `No, the service procedures & claiming guidelines are the same for all ${currentBrand.programName} Labor Plan options whether through standard ${currentBrand.programName}, ${currentBrand.programName} Select, or ${currentBrand.advantageProgramName}.`,
      title: `Is there any difference in the service procedures for ${currentBrand.advantageProgramName} vs standard ${currentBrand.programName}?`
    },
    {
      bodyContent: `${currentBrand.advantageProgramName} contracts will be available on the ${currentBrand.programName} site, so you can manage the contracts there, including submitted invoices for repairs.`,
      title: `How do I file claims on ${currentBrand.advantageProgramName} plans?`
    },
    {
      bodyContent: `${currentBrand.programName} reimbursement tiers will be available under the ${currentBrand.advantageProgramName} program. Basic, Tier 1, Tier 2 and Tier 3.`,
      title: `What service reimbursement options are available in the ${currentBrand.advantageProgramName} program?`
    },
    {
      bodyContent: `We are committed to processing invoices within 30 days of receipt and will mail you a check. ACH (direct deposit) is also available to expedite claim payments. Contact ${currentBrand.programName} for more assistance to setup ACH payments.`,
      title: "How soon will I be paid for repair invoices I submit?"
    },
    {
      bodyContent: `Yes, depending on the ${currentBrand.advantageProgramName} labor warranties originally purchased, then there may be renewal options. ${currentBrand.programName} will notify consumers that their labor warranty is nearing its expiration so it’s time to renew to continue their coverage with your company, and you remain the preferred service provider. ${currentBrand.programName} will ensure your company is referenced in all renewal communications and consumers that choose to renew coverage then the labor reimbursement will be Tier 3.`,
      title: `Are ${currentBrand.advantageProgramName} plans renewable? If yes, how?`
    },
  ];

  const rheemAccordionContents = [
    {
      bodyContent: `To receive the significant discounts in our ${currentBrand.advantageProgramName} Program, we do require a minimum of 30 days of participation.`,
      title: `How long must I stay enrolled in the ${currentBrand.advantageProgramName} Program?`
    },
    {
      bodyContent: `No, enrolling in ${currentBrand.advantageProgramName} eliminates all the up-front order entry of warranties.  Warranties will be created through an automated process based on the coverage term and reimbursement plan you select when setting up a campaign in the ${currentBrand.advantageProgramName} Program.`,
      title: `Do I need to manually order warranties through the ${currentBrand.programName} ${currentBrand.porProgramName} or ${currentBrand.programName} Vendor Portal (via ${currentBrand.clientSite}) if I have an active campaign in the ${currentBrand.advantageProgramName} Program?`
    },
    {
      bodyContent: `Immediately following product registration click the call-to-action button on the registration confirmation page to purchase upgraded labor warranty coverage.  Order the longer labor warranty term through the easy-to-use ${currentBrand.porProgramName} purchase path.  By immediately entering the upgrade warranty through the ${currentBrand.porProgramName} purchase path, a 2nd warranty will not be created thorough the ${currentBrand.advantageProgramName} file processing.  However, if an ${currentBrand.advantageProgramName} warranty is created in error, simply contact ${currentBrand.programName} to cancel the duplicate coverage and you will be credited in full.`,
      title: `What do I do when my customer upgrades to a longer term than what I pre-selected for ${currentBrand.advantageProgramName} (i.e., you pre-selected 3-year term, but your customer wants to purchase a 10-year term)?`
    },
    {
      bodyContent: `Regulations differ by state, but to be safe and abide by state regulatory requirements, the cost of coverage should be listed on the sales invoice paperwork given to your customers.  If the customer declines the coverage, that cost would then need to be deducted from your bid price to the customer.`,
      title: `When selling the coverage to my customer, am I required to list the cost of the labor coverage on their sales invoice?`
    },
    {
      bodyContent: `Access the ${currentBrand.programName} Portal to cancel any pending policies for manual pay dealers.  If coverage was created and paid for, ${currentBrand.programName} can work with you to cancel the contract, and fully credit the cost back.`,
      title: `What do I do when my customer declines coverage?`
    },
    {
      bodyContent: `No, commercial equipment will not be included in the ${currentBrand.advantageProgramName} Program processing.`,
      title: `Is commercial equipment available for ${currentBrand.advantageProgramName}?`
    },
    {
      bodyContent: `You can save your preferred payment method(s) in the ${currentBrand.programName} Portal, from there you can use these payment methods to pay for policies.  ${currentBrand.advantageProgramName} Program dealers can be on manual-pay or auto-pay for your ${currentBrand.advantageProgramName} Program processing, all while utilizing your saved payment method(s) on file.`,
      title: `How do I remit payment for ${currentBrand.advantageProgramName} Program plans?`
    },
    {
      bodyContent: `You will be able to extend your ${currentBrand.advantageProgramName} Program selection coverage expiration date during the setup of the campaign and you’re able to extend the expiration date or add new campaigns.`,
      title: `Am I able to change the date parameters originally set up before the end of the campaign?`
    },
    {
      bodyContent: `Rheem/Ruud will be providing registration data weekly, so that we can process labor warranties for the applicable ${currentBrand.advantageProgramName} Program registrations each week.`,
      title: `How often will the ${currentBrand.advantageProgramName} plans be processed after completing my customers’ product registration?`
    },
    {
      bodyContent: `Yes, wait periods do apply.  The wait period is thirty (30) days from the installation date unless registration of the equipment is greater than thirty (30) days after the equipment installation date.  If registration date is greater than thirty (30) days after installation date, then the wait period is (thirty) 30 days from contract purchase/paid date.`,
      title: `Is there a wait period on ${currentBrand.advantageProgramName} Program contracts?`
    },
    {
      bodyContent: `Order processing will take place weekly. Customers should expect to receive their certificate of coverage approximately 14 business days after registration is complete.`,
      title: `When will customers receive Certificates of Coverage?`
    },
    {
      bodyContent: `${currentBrand.advantageProgramName} plans follow the same cancellation procedure as other ${currentBrand.programName} contracts.  Contracts can be canceled by completing a Cancellation Form, including the customer’s signature and sending to ${currentBrand.programName} for processing.  Cancellations received within sixty (60) days of purchase are eligible for a full refund.  Cancellations processed after sixty (60) days from purchase will be pro-rated based upon time lapsed and less any claims paid.`,
      title: `How do I cancel ${currentBrand.advantageProgramName} plans?`
    },
    {
      bodyContent: `Yes, all ${currentBrand.programName} plans are fully transferable at no cost.  To transfer coverage, download the Transfer Form, complete, and submit according to the instructions on the form.  Once the transfer is complete, an updated copy of the labor warranty will be issued to the new customer.  Please note: Transfer Forms require original customer’s signatures, or the transfer request will not be accepted.`,
      title: `Are ${currentBrand.advantageProgramName} plans transferable?  If yes, how?`
    },
    {
      bodyContent: `No, the service procedures and claiming guidelines are the same for all ${currentBrand.programName} plan options, whether they were purchased through the ${currentBrand.programName} Portal, or through ${currentBrand.porProgramName} and ${currentBrand.advantageProgramName} purchase paths.`,
      title: `Is there any difference in the service procedures for repairs completed using ${currentBrand.advantageProgramName} versus standard ${currentBrand.programName}?`
    },
    {
      bodyContent: `${currentBrand.advantageProgramName} contracts will be available on the ${currentBrand.programName} Portal, so you can go there to submit invoices for repairs, as well as complete other ${currentBrand.programName} labor warranty related tasks.`,
      title: `How do I file claims on ${currentBrand.advantageProgramName} plans?`
    },
    {
      bodyContent: `All ${currentBrand.programName} Plan options will be available through the ${currentBrand.advantageProgramName} Program, which are ${currentBrand.standardPlanOne}, ${currentBrand.standardPlanTwo}, ${currentBrand.standardPlanThree}, ${currentBrand.valuePlanTwo} and ${currentBrand.valuePlanThree}.`,
      title: `What labor reimbursement Plans are available in the ${currentBrand.advantageProgramName} Program?`
    },
    {
      bodyContent: `We are committed to processing invoices within thirty (30) days of receipt and will issue payment in the form of a check for approved claims. ACH (direct deposit) is also available to expedite claim payments. Contact ${currentBrand.programName} Support for more assistance to setup ACH payments.`,
      title: `How soon will I be paid for approved claims?`
    },
    {
      bodyContent: `${currentBrand.advantageProgramName} plans are renewable.  ${currentBrand.programName} will notify your customers when their coverage is nearing expiration and provide options to renew, all while maintaining their relationship with you as their preferred servicing contractor. ${currentBrand.programName} will ensure your company is referenced in all renewal communications. For all customers that renew coverage, the labor reimbursement is set at our ${currentBrand.standardPlanThree} reimbursement level.`,
      title: `Are ${currentBrand.advantageProgramName} plans renewable? If yes, how?`
    },
  ];

  const carrierAccordionContents = [
    {
      bodyContent: `To receive the significant discounts in our ${currentBrand.advantageProgramName} Program, we do require a minimum of 30 days of participation.`,
      title: `How long must I stay enrolled in the ${currentBrand.advantageProgramName} Program?`
    },
    {
      bodyContent: `No, enrolling in ${currentBrand.advantageProgramName} eliminates all the up-front order entry of warranties.  Warranties will be created through an automated process based on the coverage term and reimbursement plan you select when setting up a campaign in the ${currentBrand.advantageProgramName} Program.`,
      title: `Do I need to manually order warranties through the ${currentBrand.programName} ${currentBrand.porProgramName} or ${currentBrand.programName} Vendor Portal (via ${currentBrand.clientSite}) if I have an active campaign in the ${currentBrand.advantageProgramName} Program?`
    },
    {
      bodyContent: `Immediately following product registration click the call-to-action button on the registration confirmation page to purchase upgraded labor warranty coverage.  Order the longer labor warranty term through the easy-to-use ${currentBrand.porProgramName} purchase path.  By immediately entering the upgrade warranty through the ${currentBrand.porProgramName} purchase path, a 2nd warranty will not be created thorough the ${currentBrand.advantageProgramName} file processing.  However, if an ${currentBrand.advantageProgramName} warranty is created in error, simply contact ${currentBrand.programName} to cancel the duplicate coverage and you will be credited in full.`,
      title: `What do I do when my customer upgrades to a longer term than what I pre-selected for ${currentBrand.advantageProgramName} (i.e., you pre-selected 3-year term, but your customer wants to purchase a 10-year term)?`
    },
    {
      bodyContent: `Regulations differ by state, but to be safe and abide by state regulatory requirements, the cost of coverage should be listed on the sales invoice paperwork given to your customers.  If the customer declines the coverage, that cost would then need to be deducted from your bid price to the customer.`,
      title: `When selling the coverage to my customer, am I required to list the cost of the labor coverage on their sales invoice?`
    },
    {
      bodyContent: `Access the ${currentBrand.programName} Portal to cancel any pending policies for manual pay dealers.  If coverage was created and paid for, ${currentBrand.programName} can work with you to cancel the contract, and fully credit the cost back.`,
      title: `What do I do when my customer declines coverage?`
    },
    {
      bodyContent: `No, commercial equipment will not be included in the ${currentBrand.advantageProgramName} Program processing.`,
      title: `Is commercial equipment available for ${currentBrand.advantageProgramName}?`
    },
    {
      bodyContent: `You can save your preferred payment method(s) in the ${currentBrand.programName} Portal, from there you can use these payment methods to pay for policies.  ${currentBrand.advantageProgramName} Program dealers can be on manual-pay or auto-pay for your ${currentBrand.advantageProgramName} Program processing, all while utilizing your saved payment method(s) on file.`,
      title: `How do I remit payment for ${currentBrand.advantageProgramName} Program plans?`
    },
    {
      bodyContent: `You will be able to extend your ${currentBrand.advantageProgramName} Program selection coverage expiration date during the setup of the campaign and you’re able to extend the expiration date or add new campaigns.`,
      title: `Am I able to change the date parameters originally set up before the end of the campaign?`
    },
    {
      bodyContent: `Carrier/Bryant will be providing registration data weekly, so that we can process labor warranties for the applicable ${currentBrand.advantageProgramName} Program registrations each week.`,
      title: `How often will the ${currentBrand.advantageProgramName} plans be processed after completing my customers’ product registration?`
    },
    {
      bodyContent: `Yes, wait periods do apply. The wait period is thirty (30) days from the installation date unless registration of the equipment is greater than thirty (30) days after the equipment installation date. If registration date is greater than thirty (30) days after installation date, then the wait period is (thirty) 30 days from contract purchase/paid date.`,
      title: `Is there a wait period on ${currentBrand.advantageProgramName} Program contracts?`
    },
    {
      bodyContent: `Order processing will take place weekly. Customers should expect to receive their certificate of coverage approximately 14 business days after registration is complete.`,
      title: `When will customers receive Certificates of Coverage?`
    },
    {
      bodyContent: `${currentBrand.advantageProgramName} plans follow the same cancellation procedure as other ${currentBrand.programName} contracts.  Contracts can be canceled by completing a Cancellation Form, including the customer’s signature and sending to ${currentBrand.programName} for processing.  Cancellations received within sixty (60) days of purchase are eligible for a full refund.  Cancellations processed after sixty (60) days from purchase will be pro-rated based upon time lapsed and less any claims paid.`,
      title: `How do I cancel ${currentBrand.advantageProgramName} plans?`
    },
    {
      bodyContent: `Yes, all ${currentBrand.programName} plans are fully transferable at no cost.  To transfer coverage, download the Transfer Form, complete, and submit according to the instructions on the form.  Once the transfer is complete, an updated copy of the labor warranty will be issued to the new customer.  Please note: Transfer Forms require original customer’s signatures, or the transfer request will not be accepted.`,
      title: `Are ${currentBrand.advantageProgramName} plans transferable?  If yes, how?`
    },
    {
      bodyContent: `No, the service procedures and claiming guidelines are the same for all ${currentBrand.programName} plan options, whether they were purchased through the ${currentBrand.programName} Portal, or through ${currentBrand.porProgramName} and ${currentBrand.advantageProgramName} purchase paths.`,
      title: `Is there any difference in the service procedures for repairs completed using ${currentBrand.advantageProgramName} versus standard ${currentBrand.programName}?`
    },
    {
      bodyContent: `${currentBrand.advantageProgramName} contracts will be available on the ${currentBrand.programName} Portal, so you can go there to submit invoices for repairs, as well as complete other ${currentBrand.programName} labor warranty related tasks.`,
      title: `How do I file claims on ${currentBrand.advantageProgramName} plans?`
    },
    {
      bodyContent: `All ${currentBrand.programName} Plan options will be available through the ${currentBrand.advantageProgramName} Program, which are ${currentBrand.standardPlanOne}, ${currentBrand.standardPlanTwo}, ${currentBrand.standardPlanThree}, ${currentBrand.standardPlanFour}, ${currentBrand.valuePlanTwo} and ${currentBrand.valuePlanFour}.`,
      title: `What labor reimbursement Plans are available in the ${currentBrand.advantageProgramName} Program?`
    },
    {
      bodyContent: `We are committed to processing invoices within thirty (30) days of receipt and will issue payment in the form of a check for approved claims. ACH (direct deposit) is also available to expedite claim payments. Contact ${currentBrand.programName} Support for more assistance to setup ACH payments.`,
      title: `How soon will I be paid for approved claims?`
    },
    {
      bodyContent: `${currentBrand.advantageProgramName} plans are renewable. ${currentBrand.programName} will notify your customers when their coverage is nearing expiration and provide options to renew, all while maintaining their relationship with you as their preferred servicing contractor. ${currentBrand.programName} will ensure your company is referenced in all renewal communications. For all customers that renew coverage, the labor reimbursement is set at our ${currentBrand.standardPlanFour} reimbursement level.`,
      title: `Are ${currentBrand.advantageProgramName} plans renewable? If yes, how?`
    },
  ];
  const icpAccordionContents = [
    
    {
      bodyContent: `To receive the significant discounts in our ${currentBrand.advantageProgramName} program, we do require a minimum of 30 days of participation.`,
      title: `How long must I stay enrolled in the ${currentBrand.advantageProgramName} program?`
    },
    {
      bodyContent: `No, enrolling in ${currentBrand.advantageProgramName} eliminates upfront data entry of sales. The extended service agreements (ESAs) will be created through an automated process based on the coverage term and reimbursement ${currentBrand.pricingLevelName}  options you select when enrolling in the ${currentBrand.advantageProgramName} program.`,
      title: `Do I need to manually enter sales through the ${currentBrand.programName} portal if I enroll in ${currentBrand.advantageProgramName}?`
    },
    {
      bodyContent: `Immediately following product registration click the call-to-action button on the registration site to purchase labor coverage. Process the warranty through the Select purchase path. By immediately entering the upgrade warranty through the Select purchase path a second warranty will not be created through the ${currentBrand.advantageProgramName} process. However, if a ${currentBrand.advantageProgramName} ${currentBrand.pricingLevelName}  is created in error, contact ${currentBrand.programName} and we will cancel the duplicated contract and you will be fully credited for the contract price.`,
      title: `What do I do when my customer upgrades to a longer term than what I pre-selected for ${currentBrand.advantageProgramName} (i.e. dealer pre-selected for 3-year term, but a customer wants 10 years)?`
    },
    {
      bodyContent: `Regulations differ by state, but to be safe and abide by state regulatory requirements, the cost of coverage should be listed on your sales invoice paperwork to your customer. If the
      customer declines the coverage, that cost would then need to be deducted from your bid price to your customer.`,
      title: `When selling the coverage to my customer, am I required to list the cost of the coverage on my sales invoice?`
    },
    {
      bodyContent: `Contact ${currentBrand.programName} at ${currentBrand.programPhone} or via email hvacsupport@aig.com. We will cancel the contract and you will be fully credited for the contract price.`,
      title: `What do I do when my customer declines coverage?`
    },
    {
      bodyContent: `No, commercial equipment will not be included in the ${currentBrand.advantageProgramName} processing, but commercial warranties can be purchased via the ${currentBrand.programName} website.`,
      title: `Is commercial equipment available for ${currentBrand.advantageProgramName}?`
    },
    {
      bodyContent: `Depending on your payment status, you will be required to pay for contracts via credit card, or other electronic method, or you’ll be invoiced by ${currentBrand.clientName} for ${currentBrand.advantageProgramName} sales.`,
      title: `How do I remit payment for ${currentBrand.advantageProgramName} ${currentBrand.pricingLevelName}s?`
    },
    {
      bodyContent: `You will be able to extend your ${currentBrand.advantageProgramName} selection coverage expiration date during the selection campaign and can add new campaign options and date parameters.`,
      title: `Am I able to change the date parameters originally set before the end of the campaign?`
    },
    {
      bodyContent: `${currentBrand.clientName} will be providing registration data weekly, so we will work to process labor coverage contracts for those registrations on a weekly basis.`,
      title: `How often will ${currentBrand.advantageProgramName} ${currentBrand.pricingLevelName}s be processed from my customers’ product registrations?`
    },
    {
      bodyContent: `Yes, wait periods do apply. The wait period is 30 days (coverage begins day 31).`,
      title: `Is there a wait period on ${currentBrand.advantageProgramName} contracts?`
    },
    {
      bodyContent: `Sales processing will take place weekly. Consumers should expect to receive their certificate of coverage approximately 14 days after registration is complete.`,
      title: `When will customers receive certificate of coverage?`
    },
    {
      bodyContent: `${currentBrand.advantageProgramName} ${currentBrand.pricingLevelName}s follow the same cancellation procedure as other ${currentBrand.programName} labor coverage ${currentBrand.pricingLevelName}s. Contracts can be canceled by filling out the cancellation form, including
        the customer’s signature, and sending to ${currentBrand.programName} for processing. Contracts can be canceled within 60 days with a full refund, and cancellations processed after 60 days will be pro-rated based upon time lapsed and less claims paid.`,
      title: `How do I cancel ${currentBrand.advantageProgramName} ${currentBrand.pricingLevelName}s?`
    },
    {
      bodyContent: `Yes, ${currentBrand.programName} ${currentBrand.advantageProgramName} ${currentBrand.pricingLevelName}s are fully transferable at no cost. To transfer coverage, download the transfer form on the ${currentBrand.programName} site and submit according to the
        instructions for processing.`,
      title: `Are ${currentBrand.advantageProgramName} ${currentBrand.pricingLevelName}s transferable? If yes, how?`
    },
    {
      bodyContent: `No, the service procedures and claiming guidelines are the same for all ${currentBrand.programName} extended service agreement options whether through standard ${currentBrand.programName} or ${currentBrand.advantageProgramName}.`,
      title: `Is there any difference in the service procedures for ${currentBrand.advantageProgramName} vs standard ${currentBrand.programName}?`
    },
    {
      bodyContent: `${currentBrand.advantageProgramName} contracts will be available on the ${currentBrand.programName} site, so you can manage the contracts there, including submitted invoices for repairs.`,
      title: `How do I file claims on ${currentBrand.advantageProgramName} ${currentBrand.pricingLevelName}s?`
    },
    {
      bodyContent: `All ${currentBrand.programName} reimbursement ${currentBrand.pricingLevelName}s are available in the ${currentBrand.advantageProgramName} program.`,
      title: `What service reimbursement options are available in the ${currentBrand.advantageProgramName} program?`
    },
    {
      bodyContent: `We are committed to processing invoices within 30 days of receipt and will mail you a check.`,
      title: `How soon will I be paid for repair invoices I submit?`
    },
    {
      bodyContent: `${currentBrand.advantageProgramName} ${currentBrand.pricingLevelName}s will be renewable. ${currentBrand.programName} will notify consumers when their labor coverage is nearing its expiration and it’s time to renew their coverage with your
        company, and you remain the preferred service provider. ${currentBrand.programName} would ensure your company name is mentioned throughout the notice, and if the consumer agrees to renew, we will transact the sale and create the renewal warranty (at the highest reimbursement rate – ${currentBrand.standardPlanSix}.)`,
      title: `Are ${currentBrand.advantageProgramName} ${currentBrand.pricingLevelName}s renewable? If yes, how?`
    }
  ];

  const filterSearch = acc => {
    const { title, bodyContent } = acc;
    return (typeof title === "string" && title.toLowerCase().includes(tempSearch)) || (typeof bodyContent === "string" && bodyContent.toLowerCase().includes(tempSearch));
  }

  const clientContents = isLennox ? lennoxAccordionContents : (isRheem ? rheemAccordionContents : (isICP? icpAccordionContents: carrierAccordionContents));

  const contents = clientContents
    .filter(filterSearch)
    .map((accord, i) => {
      if (search.length === 0) {
        (i < 9) ?
          (accord.title = `0${i + 1}. ${accord.title}`)
          :
          (accord.title = `${i + 1}. ${accord.title}`)
      }
      return <Accordion key={i} id={i} open={i === activeAccord} {...accord} onToggle={onToggle} />
    });

  return (
    <>
      <h1 className="h6">How can we help?</h1>
      <FormGroup className="col-md-4 col-lg-3 p-0">
        <input
          type="search"
          name="search"
          id="exampleSearch"
          placeholder="Search"
          className="form-control"
          value={search}
          onChange={e => {
            setSearch(e.target.value);
          }}
          onKeyDown={e => {
            setTempSearch(search.trim().toLowerCase());
          }}
        />
        {search.length > 0 ?
          <CloseIcon
            className={styes.closeIcon}
            onClick={() => { setSearch(''); setTempSearch(""); }}
          /> : <SearchIcon className={styes.searchIcon} />
        }
      </FormGroup>
      {contents.length > 0 ? contents : <h3>0 Results Found</h3>}
    </>
  )
}

export default AdvantageProgramFaqs;