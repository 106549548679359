import React from 'react'
import {
    QuantityInput,
    BasicInput,
    PriceInput,
    CurrencyField,
    DeleteRowButton,
    EditRowButton,
    DependentDropdown,
    HeaderFormatter,
} from "../Formatters";
import styles from './TableConfig.module.css';
import { widthFormatter } from "../Formatters/widthFormatter";
import { StaticEditRowButton } from '../Formatters/StaticEditRowButton';
import { ReactComponent as PencilIcon } from '../../../assets/images/pencil.svg';

export const LABOR_COLS = [
    {
        text: '',
        dataField: '',
        isDummyColumn: true,
        style: {
            ...widthFormatter('deleteCol'),
            padding: 0
        },
        formatter: (col, row, rowI) => <DeleteRowButton rowId={row.id} rowIndex={rowI} tableType='labor' />,
        align: 'center',
        footerClasses: [styles.footer, styles.noborder, styles.trashIcon]
    },
    {
        dataField: "asset",
        text: "Asset",
        classes: styles.assetWidth,
        formatter: (col, row, rowI) => (
            <div className={styles.assetColumn}>
                <DependentDropdown cell={col} row={row} rowIndex={rowI} tableType={'labor'} fieldName={"asset"} />
            </div>
        )
    },
    {
        dataField: "component",
        text: "Component",
        formatter: (col, row, rowI) => (
            <div className={styles.componentColumn}>
                <DependentDropdown cell={col} row={row} rowIndex={rowI} tableType={'labor'} fieldName={"component"} isClearable={true} />
            </div>
        )
    },
    {
        dataField: "repair",
        text: "Repair",
        headerFormatter: (col) => (<HeaderFormatter col={col} centered={true} toolTipRequired={true} fieldName={'repair'} />),
        formatter: (col, row, rowI) => (
            <div className={styles.repairColumn}>
                <DependentDropdown cell={col} row={row} rowIndex={rowI} tableType={'labor'} fieldName={"repair"} />
            </div>
        )
    },
    {
        dataField: "replacementDetails",
        text: "",
        isDummyColumn: true,
        style: {
            ...widthFormatter('deleteCol'),
            padding: 0
        },
        headerFormatter: (col) => (
            <div className={styles.trashIconHeader}>
                <PencilIcon />
            </div>
        ),
        formatter: (col, row, rowI) => {
            return <StaticEditRowButton
                row={{ ...row, ...(row?.replacementDetails ?? {}) }}
                rowId={row.id}
                rowIndex={rowI}
                tableType='labor'
            />
        },
        align: 'center',
    },
    {
        dataField: "hours",
        text: "Hrs",
        style: widthFormatter('hrsCol'),
        formatter: (col, row, rowI) => (
            <QuantityInput cell={col} row={row} rowIndex={rowI} tableType={'labor'} fieldName={"hours"} />
        )
    },
    {
        dataField: "rate",
        text: "Rate",
        headerAlign: 'left',
        align: 'right',
        style: widthFormatter('invoiceTotalCol'),
        headerFormatter: (col) => (<HeaderFormatter col={col} centered={true} toolTipRequired={true} fieldName={'rate'} />),
        formatter: (c, r, rI) => {
            return `$${Number(c)?.toFixed(2)}`;
        }
    },
    {
        dataField: "total",
        text: "Total",
        headerAlign: 'left',
        align: 'right',
        style: widthFormatter('invoiceTotalCol'),
        formatter: (col, row, i) => {
            return <CurrencyField col={col} row={row} rowIndex={i} fieldName="total" />
        }
    }
];
