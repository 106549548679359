import React, { Component, useEffect, useState, useContext, createContext, useReducer } from "react";
import Adjudication from '../SubmitInvoice/InvoiceAdjudication';
let rfsData = null;
export class SubmitInvoice  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventId: typeof props.match.params.channelId == "undefined" ? props.location.state.eventId : props.match.params.eventId,
            clientId: typeof props.match.params.channelId == "undefined"  ? props.location.state.clientId : props.match.params.clientId,
            eventNumber: typeof props.match.params.channelId == "undefined"  ? props.location.state.eventNumber : "",
            modelNumber: typeof props.match.params.channelId == "undefined"  ? props.location.state.modelNumber : "",
            serialNumber: typeof props.match.params.channelId == "undefined"  ? props.location.state.serialNumber : "",
            brandName: typeof props.match.params.channelId == "undefined"  ? props.location.state.brandName : "",
            servicerPhone: typeof props.match.params.channelId == "undefined"  ? props.location.state.servicerPhone : "",
            preauthorizedAmount: typeof props.match.params.channelId == "undefined" ? props.location?.state?.preauthorizedAmount : null,
            currentClaimOverviewId: 0,
            isRequestedFromOtherSite: typeof props.match.params.channelId == "undefined"  ? false : true,
            isLoaded: false

        }
    }

    componentWillMount()
    {
        if (this.state.isRequestedFromOtherSite)
            this.GetRFSFormData();
        else
            this.setState({ isLoaded : true });
    }
    async GetRFSFormData()
    {
        await fetch('claim/GetRFSSummaryByEventId/' + this.state.eventId + '/' + this.state.clientId)
            .then(res => res.json())
            .then(data => {
                rfsData = data;
                this.setState({ isLoaded: true,eventNumber: rfsData.eventNumber, modelNumber: rfsData.modelNumber, serialNumber: rfsData.serialNumber, brandName: rfsData.brand });
            });
    }
    
    render() {
        console.log(this.state, this.props)
        return (this.state.isLoaded &&<Adjudication eventNumber = { this.state.eventNumber }
        eventId = { this.state.eventId }
        claimId = { this.state.currentClaimOverviewId }
        clientId = { this.state.clientId }
        modelNumber = { this.state.modelNumber }
        serialNumber = { this.state.serialNumber }
        brandName={this.state.brandName}
        preauthorizedAmount={this.state.preauthorizedAmount}
        servicerPhone = { this.state.servicerPhone } />);
    }
}