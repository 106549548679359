import React, { useContext, useEffect } from 'react'
import { Col, FormGroup, Row } from "reactstrap";
import { DropDownAutoComplete } from "../inputs/DropDownAutoComplete";
import { Controller, useForm, useFormContext } from "react-hook-form";
import StandardInput from '../shared/StandardInput';
import { fetchBrandsByAccountId, getProductionDescriptionFromProd, loadSeries, loadWarrantyOptions } from '../../lib/ProductInputUtils';
import { useState } from 'react';
import { useClaimInvoiceStore } from './lib/state';
import classNames from "classnames";
import styles from "../InvoiceTables/Table/Table.module.css";
import { TextInput } from '../inputs/TextInput';

// Replacement Details Section ON CLICK Unit Replaced
const ReplacementDetails = ({ dealerProfile, row, formProps }) => {
    const store = useClaimInvoiceStore(state => state);
    const contract = store.getContract();
    //Static Flag needed to toggle modal static view vs edit view
    const staticFlag = row?.replacementDetails ? true : false;
    const assetName = row?.asset?.selected?.[0]?.assetName ?? row?.asset?.selected?.[0]?.display;
    const assetId = row?.asset?.selected?.[0]?.assetId ?? row?.asset?.selected?.[0]?.value;
    //Static claim has row data from API - As we are reusing the component data points are being toggled from different views
    const currentContractModel = row?.replacementDetails ? row : contract.contractAssetModel?.filter(e => e.assetId === assetId)?.[0];
    const currentContractAssetConfigurationModel = contract.contractAssetConfigurationModel?.[0];
    const currentBrandId = currentContractModel?.brandId || currentContractModel?.currentBrandId?.selected?.[0]?.data;
    const isUnitReplacedSelected = row?.repair?.selected?.[0]?.value === 'unitreplaced';

    const {
        control,
        setValue,
        trigger,
    } = useFormContext();

    useEffect(() => {
        setValue('modelNumber', isUnitReplacedSelected ? '' : currentContractModel?.modelNumber);
        setValue('serialNumber', isUnitReplacedSelected ? '' : currentContractModel?.serialNumber)
        setValue('series', isUnitReplacedSelected ? '' : currentContractAssetConfigurationModel?.value ?? 1)
        setValue('mfgParty', isUnitReplacedSelected ? '' : currentContractModel?.oemPartsCoverage )
        setValue('mfgLabor', isUnitReplacedSelected ? '' : currentContractModel?.oemLaborCoverage )
        setValue('brandName', isUnitReplacedSelected ? '' : currentBrandId)
    }, [row])

    const [brandOptions, setBrandOptions] = useState([]);
    const [warrantyPartsOptions, setWarrantyPartsOptions] = useState([]);
    const [warrantyLaborOptions, setWarrantyLaborOptions] = useState([]);
    const [seriesOptions, setSeriesOptions] = useState([]);

    const fetchOptions = async () => {
        let masterId = dealerProfile?.masterProgramId;
        const res = await Promise.all([
            fetchBrandsByAccountId(dealerProfile.accountId),
            getProductionDescriptionFromProd(assetId, masterId),
            loadWarrantyOptions(dealerProfile.accountId),
            loadSeries()
        ]);

        const brandOpts = res?.[0]?.map(e => ({ display: e.name, data: e.brandId }));
        const seriesOpts = res?.[3]?.map(e => ({ display: e.seriesDescription, data: e.seriesId }));
        const warrantyPartsOpts = res?.[2]?.sortedMfg?.map((val) => {
            val.display = `${val.data} year${val.data == "1" ? "" : "s"}`;
            return val;
        });
        const warrantyLaborOpts = res?.[2]?.sortedMfgLabor?.map((val) => {
            val.display = `${val.data} year${val.data == "1" ? "" : "s"}`;
            return val;
        });

        setBrandOptions(brandOpts);
        setSeriesOptions(seriesOpts);
        setWarrantyPartsOptions(warrantyPartsOpts);
        setWarrantyLaborOptions(warrantyLaborOpts);

        return {
            brandOpts,
            seriesOpts,
            warrantyPartsOpts,
            warrantyLaborOpts
        };
    }

    useEffect(() => {
        fetchOptions();
    }, [])

    return (
        <>
            <Row className="multiColWrapper">
                <Col className="col-6">
                    <FormGroup>
                        <label htmlFor="assetName" className="form-label">Asset</label>
                        <span aria-hidden="true" className="required-indicator">*</span>
                        <TextInput disabled={true} value={assetName} name="assetName" />
                    </FormGroup>
                </Col>
                <Col className="col-6">
                    <Controller
                        control={control}
                        defaultValue={""}
                        name="brandName"
                        rules={{
                            required: 'Brand is required.',
                        }}
                        render={({ field: { value }, fieldState, formState }) => (
                            <FormGroup className={`${fieldState.error && 'form-error'}`}>
                                <label htmlFor="brandName" className="form-label">Brand</label>
                                <span aria-hidden="true" className="required-indicator">*</span>
                                <DropDownAutoComplete
                                    name="brandName"
                                    disabled={staticFlag}
                                    className="form-control"
                                    id="brandName"
                                    aria-required="true"
                                    options={brandOptions}
                                    value={value}
                                    onChangedValue={(e) => {
                                        setValue('brandName', e)
                                        trigger('brandName');
                                    }
                                    }
                                />
                                <p className="errorMsg">{fieldState.error?.message}</p>
                            </FormGroup>
                        )}
                    />
                </Col>
            </Row>
            <Row className="multiColWrapper">
                <Col className={classNames("col-6", styles.labelAlignment)}>
                    <Controller
                        control={control}
                        defaultValue={""}
                        name="modelNumber"
                        rules={{
                            required: 'Model number is required.',
                        }}
                        render={({ field: { value }, fieldState, formState }) => (
                            <FormGroup className={`${fieldState.error && 'form-error'}`}>
                                <StandardInput
                                    hasError={fieldState.error?.message}
                                    label="Model Number"
                                    disabled={staticFlag}
                                    required
                                    fieldName="modelNumber"
                                    ariaLabelAndFieldId="modelNumber"
                                    value={value}
                                    onChange={(e) => {
                                        setValue('modelNumber', e.target.value)
                                        trigger('modelNumber');
                                    }
                                    }
                                    validator={fieldState.error?.message}
                                />
                            </FormGroup>
                        )}
                    />
                </Col>
                <Col className={classNames("col-6", styles.labelAlignment)}>
                    <Controller
                        control={control}
                        defaultValue={""}
                        name="serialNumber"
                        rules={{
                            required: 'Serial number is required.',
                        }}
                        render={({ field: { value }, fieldState, formState }) => (
                            <FormGroup className={`${fieldState.error && 'form-error'}`}>
                                <StandardInput
                                    hasError={fieldState.error?.message}
                                    label="Serial Number"
                                    required
                                    disabled={staticFlag}
                                    fieldName="serialNumber"
                                    ariaLabelAndFieldId="serialNumber"
                                    value={value}
                                    onChange={(e) => {
                                        setValue('serialNumber', e.target.value)
                                        trigger('serialNumber');
                                    }
                                    }
                                    validator={fieldState.error?.message}
                                />
                            </FormGroup>
                        )}
                    />
                </Col>
            </Row>
            <Row className="multiColWrapper">
                <Col className="col-6">
                    <Controller
                        control={control}
                        defaultValue={""}
                        name="mfgParty"
                        rules={{
                            required: 'MFG Party Warranty is required.',
                        }}
                        render={({ field: { value }, fieldState, formState }) => (
                            <FormGroup className={`${fieldState.error && 'form-error'}`}>
                                <label htmlFor="mfgParty" className="form-label">MFG Party Warranty</label>
                                <span aria-hidden="true" className="required-indicator">*</span>
                                <DropDownAutoComplete
                                    disabled={!isUnitReplacedSelected || staticFlag}
                                    name="mfgParty"
                                    className="form-control"
                                    id="mfgParty"
                                    aria-required="true"
                                    value={value}
                                    options={warrantyPartsOptions}
                                    onChangedValue={(e) => {
                                        setValue('mfgParty', e)
                                        trigger('mfgParty');
                                    }
                                    }
                                    validator={fieldState.error?.message}
                                />
                                <p className="errorMsg">{fieldState.error?.message}</p>
                            </FormGroup>
                        )}
                    />
                </Col>
                <Col className="col-6">
                    <Controller
                        control={control}
                        defaultValue={""}
                        name="mfgLabor"
                        rules={{
                            required: 'MFG Labor warranty is required.',
                        }}
                        render={({ field: { value }, fieldState, formState }) => (
                            <FormGroup className={`${fieldState.error && 'form-error'}`}>
                                <label htmlFor="brandName" className="form-label">{contract.country === 'CA' ? 'MFG Labour Warranty' : 'MFG Labor Warranty'}</label>
                                <span aria-hidden="true" className="required-indicator">*</span>
                                <DropDownAutoComplete
                                    disabled={!isUnitReplacedSelected || staticFlag}
                                    name="mfgLabor"
                                    className="form-control"
                                    id="mfgLabor"
                                    aria-required="true"
                                    value={value}
                                    options={warrantyLaborOptions}
                                    onChangedValue={(e) => {
                                        setValue('mfgLabor', e)
                                        trigger('mfgLabor');
                                    }
                                    }
                                    validator={fieldState.error?.message}
                                />
                                <p className="errorMsg">{fieldState.error?.message}</p>

                            </FormGroup>
                        )}
                    />
                </Col>
            </Row>
            {(contract.riskProfile.masterProgramId === 6) &&
                <Row className="multiColWrapper">
                    <Col className="col-6">
                        <Controller
                            control={control}
                            defaultValue={""}
                            name="series"
                            rules={{
                                required: 'Series is required.',
                            }}
                            render={({ field: { value }, fieldState, formState }) => (
                                <FormGroup className={`${fieldState.error && 'form-error'}`}>
                                    <label htmlFor="series" className="form-label">Series</label>
                                    <span aria-hidden="true" className="required-indicator">*</span>
                                    <DropDownAutoComplete
                                        name="series"
                                        className="form-control"
                                        id="series"
                                        disabled={staticFlag}
                                        aria-required="true"
                                        options={seriesOptions}
                                        value={value}
                                        onChangedValue={(e) => {
                                            setValue('series', e)
                                            trigger('series');
                                        }
                                        }
                                        validator={fieldState.error?.message}
                                    />
                                    <p className="errorMsg">{fieldState.error?.message}</p>
                                </FormGroup>
                            )}
                        />
                    </Col>
                </Row>
            }
        </>

    );
};
export default ReplacementDetails;