import React, { Component, useContext, useEffect, useState } from "react";
import {
    Container, Card, CardBody, Row, Col, Input, Button, Label, Dropdown,
    DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown
} from 'reactstrap';
import styles from "./QueueReport.module.css";
import classNames from "classnames";
import { DropDownInput } from "../../components/inputs/DropDownInput";
import "react-dates/initialize";
import { ReactComponent as Export } from "../../../src/assets/images/export.svg";
import { ReactComponent as Delete } from "../../../src/assets/images/trash.svg";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import { ReportContext } from "./ReportsContext";
import ReportsFilter from "./ReportsFilter";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import moment from "moment";
import { DeleteSale } from "../../components/Modals/DeleteSale";
import { SessionContext } from "../../context/SessionContext";
import { ReactComponent as Calendar } from '../../../src/assets/images/calendar.svg';
import { ReactComponent as SearchIcon } from '../../../src/assets/images/search.svg';
import { ReactComponent as Close } from '../../../src/assets/images/close.svg';
import { ReactComponent as Visa } from '../../../src/assets/images/cc-visa.svg';
import { ReactComponent as MC } from '../../../src/assets/images/cc-mastercard.svg';
import { ReactComponent as AE } from '../../../src/assets/images/cc-amex.svg';
import { ReactComponent as Discover } from '../../../src/assets/images/cc-discover.svg';
import { dataLayerPush } from '../../components/Global/helpers';
import NeutralInfoBadge from '../shared/NeutralInfoBadge/NeutralInfoBadge'
import PendingContractsSelectionModal from "../Modals/PendingContractsSelectionModal";
import SnackBarAlert from "../shared/SnackBar/SnackBarAlert";
import PageLoading from "../shared/PageLoading/PageLoading";
import { NotificationIds } from '../Global/NotificationConstants';
import { ContractStatusId } from '../Global/ContractStatusId';
import { ReactComponent as DownArrow } from "../../../src/assets/images/arrow-cheveron-down.svg";
import { ContractStatus } from "../Contract/ContractStatus";
import { paymentHistoryData, serviceRequestData, claimsData } from './SampleData';
import PaymentHistoryFilter from './PaymentHistoryFilter';
import ServiceRequestFilter from './ServiceRequestFilter';
import ClaimsFilter from './ClaimsFilter';
import CustomSearch from "./CustomSearch";
import SubtabNav from "../Nav/Subtabs/SubtabNav";
import { getReportSubtabType } from "../../lib/util";
import { useHistory } from 'react-router-dom';
import { StaticClaim } from '../ClaimInvoice/StaticClaim';
import { useClaimInvoiceStore } from "../InvoiceTables/lib/state";
import { getCurrencySymbol } from '../../lib/util';

let claimSummary = styles.claimSummary;
let table = "table";

const stateOptions = [{ display: "All Tasks", data: "alltasks" }, { display: "My Tasks", data: "mytasks" },];
const contractTypeOptions = [{ display: "All Contracts", data: "allcontracts" }, { display: "Active Contracts", data: "active" }, { display: "Wait Period Contracts", data: "wait" }, { display: "Cancelled Contracts", data: "cancel" }, { display: "Expired Contracts", data: "expire" },];
const ReportTypeOptions = [{ display: "Contracts", data: "contracts" }, { display: "Pending Contracts", data: "pendingcontracts" }, { display: "Incomplete Sales", data: "incompletesales" }, { display: "Payment History", data: "paymenthistory" }, { display: "Claims", data: "claims" },];
const PaymentTypeFilterOptions = [
    { display: 'All Payments', data: "allpayments" },
    { display: 'Visa', data: "visa" },
    { display: 'Discover', data: "discover" },
    { display: 'Amex', data: "ae" },
    { display: 'MC', data: "mc" }
];
const nullDefaultDate = "01/01/0000";

const ContractDateFilter = {
    Install: "installationDate",
    Purchase: "purchaseDate",
    Register: "registerDate",
    Paid: "paidDate",
    Transaction: "transactionDate",
    Save: "dateEntered",
    Invoice: "invoiceDate",
    request: "requestDate"
}

const CountryDateFormat = {
    US: "MM/DD/YYYY",
    CAN: "DD/MM/YYYY"
}

const Country = {
    US: 1,
    CAN: 2
}

const ReportType = {
    ActiveContracts: "activecontracts",
    Claims: "claims",
    Contracts: "contracts",
    IncompleteSales: "incompletesales",
    PendingContracts: "pendingcontracts",
    PaymentHistory: "paymenthistory",
    ServiceRequest: "servicerequest"
}

const ReportsContainer = (props) => {
    const history = useHistory();
    const [dropdownOpen, setDropDownOpen] = React.useState(false);
    const [state, dispatch] = useContext(ReportContext);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [showMultipleDeleteModal, setMultipleShowDeleteModal] = React.useState(false)
    const [focusedInput, setFocusedInput] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [startDate, setStartDate] = React.useState(null);
    const [showActionModal, setActionModal] = React.useState(false);
    const [showExport, setShowExport] = React.useState(false);
    const [contractShowExport, setContractShowExport] = React.useState(false);
    const [selectedMulipleRow, setSelectedMultipleRow] = React.useState([]);
    const [selectedMultipleRowData, setSelectedMultipleRowData] = React.useState([])
    const [selectedRow, setSelectedRow] = React.useState(false);
    const [column, setColumn] = React.useState(null);
    const [tableData, setTableData] = React.useState(null);
    const [dateFilter, setDateFilter] = React.useState(ContractDateFilter.Install);
    const [tableColumns, setTableColumns] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [showPendingContractsModal, setShowPendingContractsModal] = React.useState(false);
    const [pendingContractsModalType, setPendingContractsModalType] = React.useState("");
    const [clientId, setClientId] = React.useState("hvac");
    const [showSnackBar, setShowSnackBar] = React.useState(false);
    const [pendingContractsSelected, setPendingContractsSelected] = React.useState([]);
    const [singlePendingContract, setSinglePendingContract] = React.useState([]);
    const [singlePendingSelection, setSinglePendingSelection] = React.useState(false);
    const [filterdColumn, setFilterdColumn] = React.useState(null);
    const [showContractsLoader, setShowContractsLoader] = React.useState(false);
    const [paymentTypeOptions, setPaymentTypeFilterOptions] = React.useState([{ display: 'All Payments', data: "allpayments" }]);
    const [serviceRequestOptions, setServiceRequestOptions] = useState([{ display: 'All Requests', data: "allrequests" }, { display: 'Accepted', data: "accepted" }, { display: 'Rejected', data: "rejected" }, { display: 'Pending', data: "pending" }]);
    const [claimOptions, setClaimOptions] = useState([{ display: 'All Claims', data: "allclaims" }, { display: 'Open Claims', data: "open" }, { display: 'Closed Claims', data: "closed" }, { display: 'Partial Claims', data: "partial" }, { display: 'Closed With Amount Claims', data: "closedwamount" }, { display: 'Closed Without Payment Claims', data: "closedwopayment" }, { display: 'Saved Claims', data: "saved" }, { display: 'Abandoned Claims', data: "abandoned" }, { display: 'Closed No Activity Claims', data: "closednoactivity" }]);
    const [orderTotal, setOrderTotal] = React.useState(0);
    const [defaultSortedBy, setDefaultSortedBy] = React.useState([
        {
            dataField: state.sortData.sortField,
            order: state.sortData.sortOrder,
        },
    ]);
    const [sortData, setSortData] = useState({ sortField: 'installationDate', sortOrder: 'desc' })
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const initialState = {
        custom: true,
        page: 1,
        sizePerPage: 15,
        totalSize: state.totalContracts
    }
    const [contractStatusTypeCheck, setContractStatusTypeCheck] = useState('');
    const [serviceStatusTypeCheck, setServiceStatusTypeCheck] = useState('');
    const [claimStatusTypeCheck, setClaimStatusTypeCheck] = useState({claimStatus: "allclaims", claimStatusId: "allclaims"});
    const [paymentHistoryStatusTypeCheck, setPaymentHistoryStatusTypeCheck] = useState('');
    const [customPaginationOptions, setCustomPaginationOptions] = useState({ ...initialState });
    const [csvData, setCsvData] = useState([]);
    const [contract, setContract] = useState({});
    const sessionContext = useContext(SessionContext);
    const { getCurrentTabAndSubtab, updateReportTab } = sessionContext;
    const { tab, subtab } = getCurrentTabAndSubtab();
    const [currentReportFilter, setCurrentReportFilter] = useState(ContractStatus.All);
    const [searchText, setSearchText] = useState('');
    const [keyField, setKeyField] = useState("contractId");
    const { invoiceId } = getCurrentTabAndSubtab().subtab;
    const invoiceStoreState = useClaimInvoiceStore(state => state);

    const dealerProfile = sessionContext.getProfile();
    const dealerId = dealerProfile.dealerId;
    const countryId = dealerProfile.countryId;
    const dateFormat = countryId === 1 ? CountryDateFormat.US : CountryDateFormat.CAN;
    const currency = getCurrencySymbol(countryId);
    let contractStatusType = null;
    let claimStatusType = null;
    let claimsData = [];
    const [searchKey, setSearchKey] = useState("");

    useEffect(() => {
        if (state.type === ReportType.Contracts) {
            dataLayerPush('Pageview', {
                pageTitle: "Report Contracts",
                pagePath: "/report/contracts"
            })
        }
        else if (state.type === ReportType.PendingContracts) {
            dataLayerPush('Pageview', {
                pageTitle: "Report Pending Contracts",
                pagePath: "/report/pendingcontracts"
            })
        }
        else if (state.type === ReportType.IncompleteSales) {
            dataLayerPush('Pageview', {
                pageTitle: "Incomplete Sales",
                pagePath: "/report/incompletesales"
            })
        }
        else if (state.type === ReportType.PaymentHistory) {
            dataLayerPush('Pageview', {
                pageTitle: "Payment History",
                pagePath: "/report/paymenthistory"
            })
        }
        else if (state.type === ReportType.ServiceRequest) {
            dataLayerPush('Pageview', {
                pageTitle: "Service Request",
                pagePath: "/report/servicerequest"
            })
        }
        else if (state.type === ReportType.Claims) {
            dataLayerPush('Pageview', {
                pageTitle: "Claims",
                pagePath: "/report/claims"
            })
        }
    })

    useEffect(() => {
        if (state.type != null) {
            updateReportTab({
                tabId: tab.tabId,
                tabType: "reports",
                reportType: state.type
            });
            const subtabType = getReportSubtabType(state.type);
            sessionContext.tabDispatch('ADD_SUBTAB', {
                subtabType: subtabType,
                reportType: state.type,
                closeStatus: true
            })

            if (state.type !== 'paymenthistory' && state.type !== 'servicerequest' && state.type !== 'claims') {
                setKeyField("contractId");
                getContractsData();
                if (dateFilter === ContractDateFilter.Install || dateFilter === ContractDateFilter.Purchase || dateFilter === ContractDateFilter.Paid) {
                    setDateFilter(dateFilter);
                } else {
                    setDateFilter(ContractDateFilter.Install);
                }
            }
            else if (state.type === 'paymenthistory') {
                setKeyField("contractId");
                getPaymentContractsData();
                setDateFilter(ContractDateFilter.Transaction);
            }
            else if (state.type === 'servicerequest') {
                setKeyField("eventId");
                getServiceRequestData();
                setDateFilter(ContractDateFilter.request);
            }
            else if (state.type === 'claims') {
                setKeyField("claimsKey");
                getClaimsData();
                setDateFilter(ContractDateFilter.Invoice);
            }
            updateDefaultSorting();
        }

        if (state.type === ReportType.Contracts || (state.type === ReportType.PendingContracts || sessionContext.pendingReportTrack.pendingBackFlag)) {
            if (sessionContext.isExternalLogin) {
                sessionContext.isExternalLogin = false;
            }
        }

        if (state.type !== "contracts") {
            setContractStatusTypeCheck("");
        }

        (sessionContext.pendingReportTrack.selecteContractId.length > 0)
            ? setSelectedMultipleRow([...sessionContext.pendingReportTrack.selecteContractId])
            : setSelectedMultipleRow([]);
        (sessionContext.pendingReportTrack.selecteContractRows.length > 0)
            ? setSelectedMultipleRowData([...sessionContext.pendingReportTrack.selecteContractRows])
            : setSelectedMultipleRowData([]);
    }, [state.type, totalRecords, contractStatusTypeCheck, serviceStatusTypeCheck, claimStatusTypeCheck, paymentHistoryStatusTypeCheck]);

    const handleReportType = (reportType) => {
        dispatch({
            type: 'SET_TYPE',
            payload: reportType
        })
    }

    // place default sortings here.
    const updateDefaultSorting = () => {
        if (state.type === ReportType.PendingContracts || sessionContext.pendingReportTrack.pendingBackFlag) {
            setDefaultSortedBy([
                {
                    dataField: "DaysPending",
                    order: "asc",
                },
            ]);
        } else if (state.type === ReportType.IncompleteSales) {
            setDefaultSortedBy([
                {
                    dataField: "dateEntered",
                    order: "desc",
                },
            ]);
        }
        else if (state.type === ReportType.PaymentHistory) {
            setDefaultSortedBy([
                {
                    dataField: "transactionDate",
                    order: "desc",
                },
            ]);
        }
        else if (state.type === ReportType.ServiceRequest) {
            setDefaultSortedBy([
                {
                    dataField: "transactionDate",
                    order: "asc",
                },
            ]);
        }
        else if (state.type === ReportType.Claims) {
            setDefaultSortedBy([
                {
                    dataField: "invoiceDate",
                    order: "asc",
                },
            ]);
        }

    }

    const filterData = (payload, data, totalContracts, contractStatusType) => {
        let column = getColumnInfo(payload);
        let filteredData = column.filterId == ContractStatusId.All ? data : data.filter(c => c.statusId == column.filterId);

        setTableData(payload === 'activecontracts' ? data : filteredData);
        setColumn(filteredData);
        setTableColumns(column.header);
        setTotalRecords(totalContracts);

        dispatch({
            type: 'SET_TYPE',
            payload: payload
        })

        dispatch({
            type: "SET_TABLEDATA",
            payload: filteredData,
            totalContracts: totalContracts,
            contractStatusType: contractStatusType
        })
        calculateOrderTotal(filteredData);
        setShowContractsLoader(false);
    }

    const getPaymentHistoryData = () => {
        let jsonResponse = paymentHistoryData;
        let column = jsonResponse.contracts;
        const countryId = jsonResponse.countryId;
        const format = countryId === 1 ? CountryDateFormat.US : CountryDateFormat.CAN;
        let paymentContracts = column;
        setTableData(paymentContracts);
        setColumn(paymentContracts);
        setTableColumns(paymentContractsColumns);

        dispatch({
            type: "SET_TABLEDATA",
            payload: paymentContracts,
        });
    };

    const getServiceRequestData = async (value=1, searchKey, clear, sDate=null, eDate=null, clearDate=false) => {
        const { sortField, sortOrder } = state.sortData;
        const sortFields = ["eventNumber", "requestDate", "statusName", "contractNumber", "lastName", "coveredItem", "address"];
        const serviceSortField = (sortFields.includes(sortField)) ? sortField : "requestDate";

        let filterStartDate = (moment(sDate).isValid() && moment(eDate).isValid()) ? moment(sDate).format(dateFormat) : null;
        let filterEndDate = (moment(sDate).isValid() && moment(eDate).isValid()) ? moment(eDate).format(dateFormat) : null;
        if(startDate !== null && endDate !== null) {
            filterStartDate = (moment(startDate).isValid() && moment(endDate).isValid()) ? moment(startDate).format(dateFormat) : null;
            filterEndDate = (moment(startDate).isValid() && moment(endDate).isValid()) ? moment(endDate).format(dateFormat) : null;
        }
        if(clearDate) {
            filterStartDate = null;
            filterEndDate = null;
        }
        let dateFilterType = (filterStartDate !== null && filterEndDate !== null) ? dateFilter : null;

        let searchContractText = searchKey ?? "";//Pass entered text value in search textbox instead of static value 
        if (searchContractText !== null) {
            searchContractText = searchContractText.replaceAll("'", "''");
        }
        setSearchKey(searchContractText);

        try {
            setShowContractsLoader(true);
            setTableColumns(null);
            setTableData(null);
            //const response = await fetch(`contract/GetContractServiceRequestByDealerId/${dealerId}/${clientId}`);
            //SEU-24117 Change the following parameter same as contract report
            var model = {
                "dealerid": dealerProfile.dealerId,
                "clientid": clientId,
                "activePageNumber": value,
                "sizeperpage": '15',
                "StatusName": serviceStatusTypeCheck,
                "sortColumn": serviceSortField,
                "sortOrder": sortOrder,
                "searchContractText": searchContractText,
                "dateFilterType": dateFilterType,  //use datefilter dates
                "startDate": filterStartDate,
                "endDate": filterEndDate
            }

            var response = await fetch("contract/GetDynamicContractServiceRequestByDealerId", {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(model)
            });
            const jsonResponse = await response.json();
            //SEU-21253
            let serviceRecords = jsonResponse?.serviceRequest;

            if(serviceRecords?.length > 0) {
                serviceRecords.forEach((element, index) => {
                    serviceRecords[index].address = serviceRecords[index].address + ', ' + serviceRecords[index].city + ', ' + serviceRecords[index].state + ', ' + serviceRecords[index].zip;
                });
            }

            let column = getColumnInfo(state.type);
            const countryId = jsonResponse?.countryId;
            const format = countryId === 1 ? CountryDateFormat.US : CountryDateFormat.CAN;
            const totalServiceRecords = (jsonResponse?.paging !== null) ? jsonResponse?.paging?.totalCount: 0;

            setTableData(serviceRecords);
            setColumn(serviceRecords);
            setTableColumns(column.header);

            dispatch({
                type: 'SET_TYPE',
                payload: state.type
            });

            dispatch({
                type: "SET_TABLEDATA",
                payload: serviceRecords,
                totalContracts: totalServiceRecords,
                contractStatusType: serviceStatusTypeCheck
            });
            setCustomPaginationOptions({
                ...customPaginationOptions,
                page: value,
                totalSize: totalServiceRecords
            });

            setShowContractsLoader(false);

        } catch (error) {
            await setErrorMessage("Error fetching queues. Try again.");
        }
        setShowContractsLoader(false);
    };

    const getClaimsData = async (value=1, searchKey, clear, sDate=null, eDate=null, clearDate=false) => {
        const { sortField, sortOrder } = state.sortData;
        const sortFields = ["claimNumber", "claimStatusName", "invoiceNumber", "contractNumber", "lastName", "assetName", "invoiceDate", "invoiceAmount", "denialAmount", "checkNumber", "claimId"];
        const claimSortField = (sortFields.includes(sortField)) ? sortField : "claimNumber";

        let filterStartDate = (moment(sDate).isValid() && moment(eDate).isValid()) ? moment(sDate).format(dateFormat) : null;
        let filterEndDate = (moment(sDate).isValid() && moment(eDate).isValid()) ? moment(eDate).format(dateFormat) : null;
        if(startDate !== null && endDate !== null) {
            filterStartDate = (moment(startDate).isValid() && moment(endDate).isValid()) ? moment(startDate).format(dateFormat) : null;
            filterEndDate = (moment(startDate).isValid() && moment(endDate).isValid()) ? moment(endDate).format(dateFormat) : null;
        }

        if(clearDate) {
            filterStartDate = null;
            filterEndDate = null;
        }
        let dateFilterType = (filterStartDate !== null && filterEndDate !== null) ? dateFilter : null;

        let searchContractText = searchKey ?? "";//Pass entered text value in search textbox instead of static value 
        if (searchContractText !== null) {
            searchContractText = searchContractText.replaceAll("'", "''");
        }
        setSearchKey(searchContractText);


        try {
            setShowContractsLoader(true);
            setTableColumns(null);
            setTableData(null);
            //SEU-24117 Change the following parameter same as contract report 
            var model = {
                "dealerid": dealerProfile.dealerId,
                "clientid": clientId,
                "activePageNumber": value,
                "sizeperpage": '15',
                "StatusName": claimStatusTypeCheck.claimStatusId,
                "sortColumn": claimSortField,
                "sortOrder": sortOrder,
                "searchContractText": searchContractText,
                "dateFilterType": dateFilterType,  //use datefilter dates
                "startDate":  filterStartDate,
                "endDate": filterEndDate
            }

            var response = await fetch("claim/GetDynamicClaimSummaryByDealerId", {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(model)
            });
            const jsonResponse = await response.json();
            //SEU-21253
            const claims = jsonResponse?.claims;

            const reMap = claims?.map(m => ({
                ...m,
                claimsKey: m?.claimId + (m?.invoiceId !== "" && m?.invoiceId),
                claimsKeyList: m?.contractId + '|' + m?.claimId
            }))

            let claimRecords = reMap;

            let column = getColumnInfo(state.type);
            const countryId = jsonResponse?.countryId;
            const format = countryId === 1 ? CountryDateFormat.US : CountryDateFormat.CAN;
            const totalRecords = (jsonResponse?.paging !== null) ?jsonResponse?.paging.totalCount : 0;

            setTableData(claimRecords);
            setColumn(claimRecords);
            setTableColumns(column.header);

            dispatch({
                type: 'SET_TYPE',
                payload: state.type
            });

            dispatch({
                type: "SET_TABLEDATA",
                payload: claimRecords,
                totalContracts: totalRecords,
                contractStatusType: claimStatusTypeCheck.claimStatus
            });

            await setCustomPaginationOptions({
                ...customPaginationOptions,
                page: value,
                totalSize: totalRecords
            });

            setShowContractsLoader(false);

        } catch (error) {
            setErrorMessage("Error fetching queues. Try again.");
        }
        setShowContractsLoader(false);
    }

    // add your columns/report types here
    const getColumnInfo = (payload) => {
        let columnInfo = {
            header: contractReportColumns,
            filterId: ContractStatusId.All
        };

        switch (payload) {
            case ReportType.PendingContracts:
                setCsvData([])
                columnInfo.header = pendingContractsColumns;
                columnInfo.filterId = ContractStatusId.Pending;
                break;
            case ReportType.IncompleteSales:
                setCsvData([])
                columnInfo.header = inCompleteSalesColumns;
                columnInfo.filterId = ContractStatusId.Incomplete;
                break;
            case ReportType.ActiveContracts:
                setCsvData([])
                columnInfo.header = contractReportColumns;
                columnInfo.filterId = ContractStatusId.Active;
                break;
            case ReportType.PaymentHistory:
                setCsvData([])
                columnInfo.header = paymentContractsColumns;
                columnInfo.filterId = ContractStatusId.All;
                break;
            case ReportType.ServiceRequest:
                setCsvData([])
                columnInfo.header = serviceRequestColumns;
                columnInfo.filterId = ContractStatusId.All;
                break;
            case ReportType.Claims:
                setCsvData([])
                columnInfo.header = claimsColumns;
                columnInfo.filterId = ContractStatusId.All;
                break;

            default:
                setCsvData([])
                columnInfo.header = contractReportColumns;
                columnInfo.filterId = ContractStatusId.All;
                break;
        }

        return columnInfo;
    }

    const accountName = sessionContext?.accountName || "account";

    //Fetch Contract  report 
    const getContractsData = async (value, searchKey, clear, clearDate = false) => {
        const tabData = tab?.filterOptions;
        const { page } = customPaginationOptions;
        const { sortField, sortOrder } = state.sortData;
        let contractStatus = "";

        const sortFields = ["contractNumber", "clientContractNumber", "statusName", "lastName", "totalAmount", "installationDate", "purchaseDate", "paidDate"];
        const contractSortField = (sortFields.includes(sortField)) ? sortField : "installationDate";

        if(state.type === "contracts") {
            contractStatus = contractStatusTypeCheck;
        } else {
            if (state.type === "pendingcontracts") {
                contractStatus = "pending";
            }
            else if (state.type === "incompletesales") {
                contractStatus = "incomplete";
            }
        }
        
        //SEU-14620
        let searchContractText = searchKey ?? "";//Pass entered text value in search textbox instead of static value 
        if (searchContractText !== null) {
            searchContractText = searchContractText.replaceAll("'", "''");
        }

        //Update search value and page number for filter retention
        let updatedSearchVal =
            searchContractText || clear ? searchContractText : tabData?.search;
        let updatedPageVal =
            value ? value : tabData?.page ? tabData?.page : 1;

        //Retain filter applied between switching tabs
        const filterOptions = {
            page: updatedPageVal,
            search: updatedSearchVal
        }
        setSearchText(updatedSearchVal);
        sessionContext.tabDispatch('UPDATE_TAB', { filterOptions });

        let filterStartDate = (moment(startDate).isValid() && !clearDate) ? moment(startDate).format(dateFormat) : null;
        let filterEndDate = (moment(endDate).isValid() && !clearDate) ? moment(endDate).format(dateFormat) : null;
        let dateFilterType = (filterStartDate !== null && filterEndDate !== null) ? dateFilter : null;

        try {
            setShowContractsLoader(true);
            var model = {
                "dealerid": dealerProfile.dealerId,
                "activePageNumber": updatedPageVal,
                "sizeperpage": options?.sizePerPage,
                "clientid": clientId,
                "contractType": state.type,
                "contractStatus": contractStatus,
                "sortColumn": contractSortField,
                "sortOrder": sortOrder,
                "searchContractText": updatedSearchVal,
                "dateFilterType": dateFilterType,
                "startDate": filterStartDate,
                "endDate": filterEndDate
            }

            var response = await fetch("contract/GetDynamicContractSummaryByDealer", {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(model)
            });


            const jsonResponse = await response.json();

            let column = jsonResponse.contracts;
            let columnData = column;
            const totalRecordCount = (jsonResponse.paging !== null) ? jsonResponse.paging.totalCount : 0;

            // await setTotalRecords(totalRecordCount);

            columnData = showHideDates(columnData, dateFormat);

            if (sessionContext.pendingReportTrack.pendingBackFlag) {
                filterData(ReportType.PendingContracts, columnData, totalRecordCount, contractStatus);
            }
            else {
                filterData(state.type, columnData, totalRecordCount, contractStatus);
            }
            if (state.type === "pendingcontracts") {
                    calculateOrderTotal(columnData);
            }
            if (updatedPageVal !== undefined) {
                await setCustomPaginationOptions({
                    ...customPaginationOptions,
                    page: updatedPageVal,
                    totalSize: totalRecordCount
                });
            }
        } catch (error) {
            setErrorMessage("Error fetching queues. Try again.");
        }
    };

    const getPaymentContractsData = async (value=1, searchKey, clearDate = false) => {
        const { sortField, sortOrder } = state.sortData;
        const sortFields = ["cardNumber", "contractNumber", "clientContractNumber", "statusName", "lastName", "totalAmount", "TransactionDate"];
        const paymentSortField = (sortFields.includes(sortField)) ? sortField : "TransactionDate";

        let searchContractText = searchKey ?? "";//Pass entered text value in search textbox instead of static value 
        if (searchContractText !== null) {
            searchContractText = searchContractText.replaceAll("'", "''");
        }
        setSearchKey(searchContractText);

        try {
            setShowContractsLoader(true);
            setTableData(null);
            setColumn(null);
            // setTableColumns(columPayment.header);
            let filterStartDate = (moment(startDate).isValid() && !clearDate) ? moment(startDate).format(dateFormat) : null;
            let filterEndDate = (moment(endDate).isValid() && !clearDate) ? moment(endDate).format(dateFormat) : null;
            let dateFilterType = (filterStartDate !== null && filterEndDate !== null) ? dateFilter : null;
            //const response = await fetch(`contract/GetContractPaymentSummaryByDealer/${dealerId}/500/${clientId}`);
            var model = {
                "dealerid": dealerProfile.dealerId,
                "activePageNumber": value,
                "clientid": clientId,
                "sizeperpage": '15',
                "StatusName": paymentHistoryStatusTypeCheck.status,
                "sortColumn": paymentSortField,
                "sortOrder": sortOrder,
                "searchContractText": searchContractText,
                "dateFilterType": dateFilterType,  //use datefilter dates
                "startDate": filterStartDate,
                "endDate": filterEndDate
            }

            var response = await fetch("contract/GetDynamicContractPaymentSummaryByDealer", {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(model)
            });
            const jsonResponse = await response.json();
            let column = jsonResponse.contracts;

            column.forEach((element, index) => {
                column[index].contractId = column[index].contractId + '_' + index;
            });

            let columnData = column;

            const uniqueCards = Array.from(
                new Set(columnData.map(item => item.cardNumber))).map(
                    cardNumber => {
                        return {
                            cardName: columnData.find(s => s.cardNumber === cardNumber).cardName,
                            cardNumber: cardNumber
                        };
                    });

            const paymentTypeFilters = uniqueCards.map(m => ({
                display: `${cardType(m.cardName)} #${m.cardNumber}`,
                data: cardType(m.cardName) + '#' + m.cardNumber
            }))
            let paymentTypeFiltersAll = [];
            paymentTypeFiltersAll.push({ display: 'All Payments', data: 'allpayments' });
            paymentTypeFilters.forEach(f => {
                paymentTypeFiltersAll.push({ display: f.display, data: f.data });
            });
            columnData = showHideDates(columnData, dateFormat);
            setPaymentTypeFilterOptions(paymentTypeFiltersAll);
            const totalPaymentRecords = (jsonResponse?.paging !== null) ? jsonResponse?.paging?.totalCount : 0;
            let columPayment = getColumnInfo(state.type);
            setTableData(columnData);
            setColumn(columnData);
            setTableColumns(columPayment.header);

            dispatch({
                type: 'SET_TYPE',
                payload: state.type
            });

            dispatch({
                type: "SET_TABLEDATA",
                payload: columnData,
                totalContracts: totalPaymentRecords,
                contractStatusType: paymentHistoryStatusTypeCheck
            });
            setCustomPaginationOptions({
                ...customPaginationOptions,
                page: value,
                totalSize: totalPaymentRecords
            });            

        } catch (error) {
            setErrorMessage("Error fetching queues. Try again.");
        }
        setShowContractsLoader(false);
    };

    function cardType(cardAbbr) {
        let cardName = '';
        switch (cardAbbr) {
            case 'VI':
                cardName = 'Visa';
                break;
            case 'AE':
                cardName = 'American Express';
                break;
            case 'DI':
                cardName = 'Discover';
                break;
            case 'MC':
                cardName = 'MasterCard'
                break;

        }

        return cardName;
    }

    function headerFormatter(column, index, { sortElement, filterElement }) {
        let { order } = sortElement.props;
        const { sortField, sortOrder } = state.sortData;

        if (!order && column.dataField === 'requestDate') {
            order = 'asc';
        }
        return (
            (state.type !== "paymenthistory" && state.type !== "servicerequest" && state.type !== "claims")
                ? (
                    <span className={`
                    ${sortField === column.dataField && sortOrder === "asc" ? "ascending" : ""} 
                    ${sortField === column.dataField && sortOrder === "desc" ? "descending" : ""}
                    `}>
                        {column.text}
                    </span>
                ) : (
                    <span className={`${order === "asc" ? "ascending" : ""} ${order === "desc" ? "descending" : ""}`}>
                        {column.text}
                    </span>
                )
        );
    }

    function getDaysDifference(col) {
        const currenDate = moment();
        var diff = currenDate.diff(col, "days");
        if (diff > 360) {
            return `${Math.round(diff / 360)}y`;
        } else if (diff >= 30) {
            return `${Math.round(diff / 30)}m`;
        } else if (diff >= 7) {
            return `${Math.round(diff / 7)}w`;
        } else if (diff >= 1) {
            return `${Math.round(diff)}d`;
        } else {
            var diffHours = currenDate.diff(col, "hours");
            if (diffHours > 1) {
                return `${Math.round(diffHours)}h`;
            } else {
                return `new`;
            }
        }
    }

    const handleDateRangeChange = (dates) => {
        if (dates) {
            setStartDate(dates.startDate);
            setEndDate(dates.endDate);
            
            dispatch({ type: "SET_STARTDATE", payload: dates.startDate });
            dispatch({ type: "SET_ENDDATE", payload: dates.endDate });
        }
        return;
    };

    const handleClaimDateRangeChange = (dates) => {
        
        if (dates) {
            dispatch({ type: "SET_STARTDATE", payload: dates.startDate });
            dispatch({ type: "SET_ENDDATE", payload: dates.endDate });
            setStartDate(dates.startDate);
            setEndDate(dates.endDate);
        }
        if(dates.startDate === null && dates.endDate === null) {
            if(state.type === "servicerequest") {
                if((dates.startDate === null && document.getElementById("serviceRangeStartDate").value.length === 10) && 
                    (dates.endDate === null && document.getElementById("serviceRangeEndDate").value.length === 10)) {
                    getServiceRequestData(1, searchKey, "", null, null, true);
                }
            }
            if(state.type === "claims") {
                if((dates.startDate === null && document.getElementById("claimRangeStartDate").value.length === 10) && 
                    (dates.endDate === null && document.getElementById("claimRangeEndDate").value.length === 10)) {
                        getClaimsData(1, searchKey, "", null, null, true);
                }
            }            
        }
        const data = [...tableData];

        const currentFilter = dateFilter;
        const filterDatesAreValid = moment(dates.startDate, dateFormat).isValid() && moment(dates.endDate, dateFormat).isValid();
        if (dates.startDate !== null && dates.endDate !== null) {
            if (isValidDateFilter(currentFilter) && filterDatesAreValid) {
                if (data) {
                    setDropDownOpen(!dropdownOpen);
                    if(state.type === "servicerequest") {
                        getServiceRequestData(1, searchKey, "", dates.startDate, dates.endDate, false);
                    }
                    if(state.type === "claims") {
                        getClaimsData(1, searchKey, "", dates.startDate, dates.endDate);
                    }
                }
                return;
            }
        }
    }

    const checkStartDate = (e) => {
        const evt = e.target;
        if (evt && evt.value) {
            const val = evt.value;
            const possibleDate = moment(val, "MM/DD/YYYY");
            const strings = val.split("/");
            if (
                possibleDate.toString() !== "Invalid date" &&
                strings[2] &&
                strings[2].length === 4
            ) {
                setStartDate(possibleDate);
            }
        }
    };

    const checkEndDate = (e) => {
        const evt = e.target;
        if (evt && evt.value) {
            const val = evt.value;
            const possibleDate = moment(val, dateFormat);
            const strings = val.split("/");
            if (
                possibleDate.toString() !== "Invalid date" &&
                strings[2] &&
                strings[2].length === 4
            ) {
                setEndDate(possibleDate);
            }
        }
    };

    useEffect(() => {
        const startDatePicker = document.getElementById("rangeStartDate");
        const endDatePicker = document.getElementById("rangeEndDate");

        if (startDatePicker) {
            startDatePicker.addEventListener("keyup", checkStartDate);
        }

        if (endDatePicker) {
            endDatePicker.addEventListener("keyup", checkEndDate);
        }
        return () => {
            if (startDatePicker) {
                startDatePicker.removeEventListener("keyup", checkStartDate);
            }
            if (endDatePicker) {
                endDatePicker.removeEventListener("keyup", checkEndDate);
            }
        };
    });

    const handleShowActionModal = (row) => {
        setSelectedRow(row);
        setShowDeleteModal(true);
    };

    const handleDeleteShowActionModal = (row) => {
        setMultipleShowDeleteModal(true)
    };

    const handleSelect = (row, isSelect, rowIndex, e) => {
        if (!isSelect && e.target.textContent === "Delete Icon") return false;
        if (state.type === "paymenthistory") {
            row.contractId = row.contractId.split("_")[0];
        }

        if (isSelect) {
            if (state.type === "claims") {
                setSelectedMultipleRow([...selectedMulipleRow, row.claimsKey])
                setSelectedMultipleRowData([...selectedMultipleRowData, row])
                setCsvData([...selectedMultipleRowData, row])

                sessionContext.setSelectedCsv([...selectedMultipleRowData, row])
            } else if (state.type === "servicerequest") {

                setSelectedMultipleRow([...selectedMulipleRow, row.eventId])
                setSelectedMultipleRowData([...selectedMultipleRowData, row])
                setCsvData([...selectedMultipleRowData, row])

                sessionContext.setSelectedCsv([...selectedMultipleRowData, row])
            } else {
                setSelectedMultipleRow([...selectedMulipleRow, row.contractId])
                setSelectedMultipleRowData([...selectedMultipleRowData, row])
                setCsvData([...selectedMultipleRowData, row])

                sessionContext.setSelectedCsv([...selectedMultipleRowData, row])
            }

        } else {
            if (state.type === "claims") {
                setSelectedMultipleRow([...selectedMulipleRow].filter(x => x !== row.claimsKey))
                setSelectedMultipleRowData([...selectedMultipleRowData].filter(x => x.claimsKey !== row.claimsKey))
                setCsvData([...selectedMultipleRowData].filter(x => x.claimsKey !== row.claimsKey))

                sessionContext.setSelectedCsv([...selectedMultipleRowData].filter(x => x.claimsKey !== row.claimsKey))
            } else if (state.type === "servicerequest") {
                setSelectedMultipleRow([...selectedMulipleRow].filter(x => x !== row.eventId))
                setSelectedMultipleRowData([...selectedMultipleRowData].filter(x => x.eventId !== row.eventId))
                setCsvData([...selectedMultipleRowData].filter(x => x.eventId !== row.eventId))

                sessionContext.setSelectedCsv([...selectedMultipleRowData].filter(x => x.eventId !== row.eventId))
            } else {
                setSelectedMultipleRow([...selectedMulipleRow].filter(x => x !== row.contractId))
                setSelectedMultipleRowData([...selectedMultipleRowData].filter(x => x.contractId !== row.contractId))
                setCsvData([...selectedMultipleRowData].filter(x => x.contractId !== row.contractId))

                sessionContext.setSelectedCsv([...selectedMultipleRowData].filter(x => x.contractId !== row.contractId))
            }
        }

        if (state.type === "contracts" || state.type === "claims" || state.type === "claims") {
            setContractShowExport(isSelect);
        }
    }

    const handleOnSelectAll = (isSelect, rows) => {
        let selectedMulipleRowTemp = [...selectedMulipleRow];
        let selectedMultipleRowDataTemp = [...selectedMultipleRowData];

        rows.forEach(r => {
            if (isSelect) {
                if (state.type === "claims") {
                    if (selectedMulipleRow.indexOf(r.claimsKey)) {
                        selectedMulipleRowTemp = [...selectedMulipleRowTemp, r.claimsKey]
                        selectedMultipleRowDataTemp = [...selectedMultipleRowDataTemp, r]

                        sessionContext.setSelectedCsv([...selectedMultipleRowData, r])
                    }
                } else if (state.type === "servicerequest") {
                    if (selectedMulipleRow.indexOf(r.eventId)) {
                        selectedMulipleRowTemp = [...selectedMulipleRowTemp, r.eventId]
                        selectedMultipleRowDataTemp = [...selectedMultipleRowDataTemp, r]

                        sessionContext.setSelectedCsv([...selectedMultipleRowData, r])
                    }
                } else {
                    if (selectedMulipleRow.indexOf(r.contractId)) {
                        selectedMulipleRowTemp = [...selectedMulipleRowTemp, r.contractId]
                        selectedMultipleRowDataTemp = [...selectedMultipleRowDataTemp, r]
                    }
                }
            } else {
                if (state.type === "claims") {
                    selectedMulipleRowTemp = [...selectedMulipleRowTemp].filter(x => x !== r.claimsKey)
                    selectedMultipleRowDataTemp = [...selectedMultipleRowDataTemp].filter(x => x.claimsKey !== r.claimsKey)

                    sessionContext.setSelectedCsv([...selectedMultipleRowData].filter(x => x.claimsKey !== r.claimsKey))
                } else if (state.type === "servicerequest") {
                    selectedMulipleRowTemp = [...selectedMulipleRowTemp].filter(x => x !== r.eventId)
                    selectedMultipleRowDataTemp = [...selectedMultipleRowDataTemp].filter(x => x.eventId !== r.eventId)

                    sessionContext.setSelectedCsv([...selectedMultipleRowData].filter(x => x.eventId !== r.eventId))
                }
                else {
                    selectedMulipleRowTemp = [...selectedMulipleRowTemp].filter(x => x !== r.contractId)
                    selectedMultipleRowDataTemp = [...selectedMultipleRowDataTemp].filter(x => x.contractId !== r.contractId)
                }
            }
        });
        setSelectedMultipleRow(selectedMulipleRowTemp);
        setSelectedMultipleRowData(selectedMultipleRowDataTemp);
        setCsvData(selectedMultipleRowDataTemp);
        sessionContext.setSelectedCsv(selectedMultipleRowDataTemp)
    }

    const onValueChange = (event) => {
        setDateFilter(event.target.value)
    }

    const onClearDateFilter = () => {
        setDropDownOpen(!dropdownOpen);
        setColumn(tableData);
        dispatch({
            type: "SET_TABLEDATA",
            payload: tableData,
        });

        setCustomPaginationOptions({
            ...customPaginationOptions,
            page: 1,
            totalSize: totalRecords
        });

        if (state.type != null) {
            if (state.type !== 'paymenthistory' && state.type !== 'claims') {
                setDateFilter(ContractDateFilter.Install);
                getContractsData("", "", "", true);
            }
            if (state.type === 'paymenthistory') {
                setDateFilter(ContractDateFilter.Transaction);
                getPaymentContractsData(1, "", true);
            }
            if (state.type === 'claims') {
                setDateFilter(ContractDateFilter.Invoice);
            }
            if (state.type === "pendingcontracts") {
                calculateOrderTotal(tableData);
            }
        }

        setStartDate('');
        setEndDate('');
    }

    const onApplyDateFilter = () => {
        var data = tableData;
        var currentFilter = dateFilter;
        var filterDatesAreValid = moment(startDate, dateFormat).isValid() && moment(endDate, dateFormat).isValid();
        if (isValidDateFilter(currentFilter) && filterDatesAreValid) {
            if (data) {
                setDropDownOpen(!dropdownOpen);
                //Added as part of SEU-24114
                if (state.type === 'paymenthistory') {
                    setKeyField("contractId");
                    getPaymentContractsData();
                } else {
                    getContractsData();
                }
            }
            return;
        } else {
            !startDate && !endDate && onClearDateFilter();
        }
    }

    const isValidDateFilter = (filter) => {
        switch (filter) {
            case ContractDateFilter.Install:
            case ContractDateFilter.Purchase:
            case ContractDateFilter.Paid:
            case ContractDateFilter.Register:
            case ContractDateFilter.Transaction:
            case ContractDateFilter.Save:
            case ContractDateFilter.Invoice:
            case ContractDateFilter.request:

                return true;
            default:
                return false;
        }
    }

    const contractDateInRange = (date, startDate, endDate) => {
        var mDate = moment(date, dateFormat);
        var mStart = moment(startDate, dateFormat);
        var mEnd = moment(endDate, dateFormat);

        return mDate.isValid() && mStart.isValid() && mEnd.isValid() &&
            mDate.isSameOrBefore(endDate, 'day') &&
            mDate.isSameOrAfter(startDate, 'day');
    }


    const actionsFormatter = (column, colIndex) => {
        const selectedCount = sessionContext.getSelectedCsv();
        const disableCsvExport = selectedCount.length > 0 ? false : true;

        return (
            <UncontrolledDropdown onToggle={() => { }} >
                <DropdownToggle tag="span" >
                    <Export className={styles.toggleIcon} alt="toggle dropdown" />
                </DropdownToggle>
                <DropdownMenu right className={styles.exportContainer}>
                    <DropdownItem onClick={() => handleExportAll()}>
                        Export All
                    </DropdownItem>
                    <DropdownItem
                        className={disableCsvExport ? styles.disableItem : ""}
                        disabled={disableCsvExport}
                        onClick={() => handleExport(sessionContext.getSelectedCsv())}>
                        Export Selected
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    };

    const handleExportAll = async () => {
        //Export All function
        var dealer = dealerProfile;
        var route = "";
        var fileDownload = "";
        const tabData = tab?.filterOptions;
        const { sortField, sortOrder } = state.sortData;

        let updatedSearchVal = tabData?.search;
        let updatedPageVal = tabData?.page ? tabData?.page : 1;

        if (state.contractStatusType !== null && state.contractStatusType !== undefined) {
            contractStatusType = (contractStatusType === null) ? state.contractStatusType : contractStatusType;
        }

        var model = {
            "dealerid": dealerProfile.dealerId,
            "activePageNumber": updatedPageVal,
            "sizeperpage": totalRecords,
            "clientid": clientId,
            "contractType": state.type,
            "contractStatus": contractStatusType,
            "sortOrder": sortOrder,
            "searchContractText": updatedSearchVal,
            "dateFilterType": null,
            "startDate": null,
            "endDate": null
        }

        if (state.type == "claims") {
            route = "claim/ExportAllClaims/hvac/" + dealer.dealerId;
            fileDownload = "export-claims";
        } else if (state.type == "servicerequest") {
            route = "contract/ExportAllServiceRequest/hvac/" + dealer.dealerId;
            fileDownload = "export-servicerequest";
        } else {
            route = "contract/ExportAllContracts/hvac/" + dealer.accountId + '/' + state.type + '/' + dealer.dealerId
            fileDownload = state.type === "incompletesales" ? "export-incompletesales" : "export -contracts";
        }

        var res = await fetch(route, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(model)
        });

        var data = await res.blob();
        if (data != undefined) {
            const file = new Blob([data], { type: 'text/csv' });
            const fileURL = window.URL.createObjectURL(file);

            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.download = fileDownload;
            fileLink.click();

        } else {
            console.log("Error fetching file.");
            return undefined;
        }
    }

    const handleExport = async (data) => {
        if (data.length != 0) {
            var route = "";
            var fileDownload = "";
            var dealer = dealerProfile;
            var param = "";

            if (state.type == "claims") {
                param = data.map(d => d.claimsKeyList);
                fileDownload = "export-claims";
                route = route = "claim/exportselectedclaims/hvac/" + dealer.dealerId;
            } else if (state.type == "servicerequest") {
                param = data.map(d => d.eventId);
                fileDownload = "export-servicerequest";
                route = route = "contract/ExportSelectedServiceRequest/hvac/" + dealer.dealerId;
            } else if (state.type === "incompletesales") {
                fileDownload = "export-incompletesales"
                param = data;
                route = "contract/exportselectedincompletesales/hvac/" + dealerProfile.dealerId;
            } else {
                param = data.map(d => d.contractNumber);
                fileDownload = "export-contracts";
                route = "contract/exportselectedcontracts/hvac/" + dealer.accountId + '/' + dealer.countryId
            }

            var res = await fetch(route, {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(param),
            });

            var data = await res.blob();
            if (data != undefined) {
                const file = new Blob([data], { type: 'text/csv' });
                const fileURL = window.URL.createObjectURL(file);

                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.download = fileDownload;
                fileLink.click();

            } else {
                console.log("Error fetching file.");
                return undefined;
            }
        }
    };

    const { SearchBar, ClearSearchButton } = Search;

    const selectRow = {
        mode: "checkbox",
        clickToSelect: true,
        classes: "",
        onSelect: handleSelect,
        onSelectAll: handleOnSelectAll,
        selected: selectedMulipleRow,
        style: { backgroundColor: "#efefef" },
        selectionRenderer: ({ mode, checked, disabled }) => (
            <div className={classNames("checkbox", "mb-0")}>
                <Input className="mr-2" type="checkbox" checked={checked} />
                <Label className={classNames("checkboxLabel", sessionContext.accountName)}></Label>
            </div>
        ),
        selectionHeaderRenderer: ({ mode, checked, disabled }) => (
            <div className={classNames("checkbox", "mb-0")}>
                <Input className="mr-2" type="checkbox" checked={checked} />
                <Label className={classNames("checkboxLabel", sessionContext.accountName)}></Label>
            </div>
        ),
    };

    const options = {
        activePageNumber: 1,
        sizePerPage: 15,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: true
    };

    const handleCellClick = (row) => {
        console.log({ row });
    };

    const onTableChange = async (type, newState) => {
        const { page } = newState;
        ((state.type !== "servicerequest" && state.type !== "claims" && state.type !== 'paymenthistory') && type == 'pagination') && getContractsData(page);
        (state.type === "servicerequest" && type == 'pagination') && getServiceRequestData(page);
        (state.type === "claims" && type == 'pagination') && getClaimsData(page);
        (state.type === 'paymenthistory' && type == 'pagination') && getPaymentContractsData(page);
    }

    const onViewInvoice = (tableData) => {
        if (tableData != null && tableData.contractNumber != null) {
            sessionContext.tabDispatch('ADD_TAB', {
                title: tableData.firstName + ' ' + tableData.lastName,
                tabType: "claimOverview",
                contractNumber: tableData.contractNumber,
                client: clientId,
                claimOverviewModel: tableData,
                claimReportStatus: true
            });
        }
    };

    const onViewContract = (tableData) => {
        if (tableData != null && tableData.contractNumber != null) {
            sessionContext.tabDispatch('ADD_TAB', {
                title:
                    (tableData.firstName != null ? tableData.firstName : "") +
                    " " +
                    (tableData.lastName != null ? tableData.lastName : ""),
                tabType: "contract",
                contractNumber: tableData.contractNumber,
                serviceNumber: ""
            });
        }
    };

    const onViewRFSData = async (tableData) => {
        if (tableData != null && tableData.contractNumber != null) {
            sessionContext.tabDispatch('ADD_TAB', {
                title:
                    (tableData.firstName != null ? tableData.firstName : "") +
                    " " +
                    (tableData.lastName != null ? tableData.lastName : ""),
                tabType: "serviceRequest",
                contractNumber: tableData.contractNumber,
                serviceNumber: tableData.eventNumber,
                eventData: tableData,
                hideContractSubtab: await hideRFSContractSubTab(tableData.contractNumber)
            });
        }
    };

    const hideRFSContractSubTab = async (contractNumber) => {

        const response = await fetch(`contract/GetContractByContractNumber/${contractNumber}/${clientId}`);
        const jsonResponse = await response.json();
        let data = jsonResponse;

        if (dealerId === data[0].dealerId) {
            return false;
        } else {
            return true;
        }

        return false;
    }

    const contractNumberFormatter = (contract, tableData) => {
        return (
            <a onClick={() => onViewContract(tableData)} className="btnLink" >
                {contract}
            </a>
        );
    };

    //SEU-21263
    const pendingDaysFormatter = (daysPending, tableData) => {
        let days = tableData.daysPending;
        if (days > 14) {
            return (
                <span className={styles.dayAlert}>{days} days</span>
            );
        }
        return (
            <span>{days} days</span>
        );
    };
    //SEU-21263

    const priceFormatter = (price, tableData) => {
        return (
            <div className="text-right">{currency}{Number.parseFloat(price).toFixed(2)}</div>
        );
    };

    const pricePaymentHistoryFormatter = (column, rowData) => {
        let price = rowData.totalAmount || 0;
        price = Number.parseFloat(price).toFixed(2);
        return (
            <>
                {price < 0 ? (
                    <span className={styles.refundAmount}>{`$${price}`}</span>
                ) : (
                    column == "" ? <span></span> : <span>{`$${price}`}</span>
                )}
            </>
        );
    }

    const pendingPriceFormatter = (price, tableData) => {
        return (
            <div>{currency}{Number.parseFloat(price).toFixed(2)}</div>
        );
    };

    const dateFormatter = (cell, row) => {
        if (!cell) return "";
        if (!moment(cell, dateFormat, true).isValid())
            return "";
        return cell;
    };

    const paymentTypeFormatter = (cell, row) => {
        if (!cell) return "";
        return (
            <div>
                {row.cardName === 'VI' && <Visa className={styles.cardIcon} />}
                {row.cardName === 'MC' && <MC className={styles.cardIcon} />}
                {row.cardName === 'AE' && <AE className={styles.cardIcon} />}
                {row.cardName === 'DI' && <Discover className={styles.cardIcon} />}
                {row.cardNumber}
            </div>
        );
    };

    const onContractFilterChange = (value) => {
        //default option is return all
        var columnData = tableData;
        switch (value) {
            case "active":
                contractStatusType = "active";
                break;
            case "wait":
                contractStatusType = "wait";
                break;
            case "cancel":
                contractStatusType = "cancel";
                break;
            case "expire":
                contractStatusType = "expire";
                break;
            case "allcontracts":
                contractStatusType = "allcontracts";
                break;
            default:
                contractStatusType = "";
                break;
        }
        setContractStatusTypeCheck(value);
    };

    const onPaymentFilterChange = (value) => {
        if (value !== 'allpayments') {
            setPaymentHistoryStatusTypeCheck({status: value.slice(-4), statusId: value});
        } else {
            setPaymentHistoryStatusTypeCheck("");
        }
    };

    const onClaimFilterChange = (value) => {
        //default option is return all
        var columnData = tableData;
        var statusId = "allclaims";

        switch (value) {
            case "allclaims":
                statusId = "allclaims";
                break;
            case "open":
                statusId = "open";
                break;
            case "closed":
                statusId = "closed";
                break;
            case "partial":
                statusId = "partial";
                break;
            case "closedwamount":
                statusId = "closed with amount";
                break;
            case "closedwopayment":
                statusId = "closed without payment";
                break;
            case "saved":
                statusId = "saved";
                break;
            case "abandoned":
                statusId = "abandoned";
                break;
            case "closednoactivity":
                statusId = "closed no activity";
                break;
        }
        setClaimStatusTypeCheck({claimStatus: value, claimStatusId: statusId});
    };

    //service request filter for accepted, pending, rejected state
    const onServiceRequestFilterChange = (value) => {
        let columnData = tableData;
        
        if (value !== 'allrequests') {
            setServiceStatusTypeCheck(value);
        } else {
            setServiceStatusTypeCheck(null);
        }
            
    };

    const inCompleteSalesColumns = [
        {
            dataField: "dateEntered",
            text: "Saved Date",
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            formatter: (col, row) => {
                const difference = getDaysDifference(moment(col));
                const value = `${moment(col).format(dateFormat)} (${difference})`;
                return (
                    <a href="#" onClick={() => { handleContinueSales(row) }}>
                        <b>{value}</b>
                    </a>
                );
            },
            headerFormatter: headerFormatter,
        },
        {
            dataField: "clientContractNumber",
            text: "Dealer PO#/Reg #",
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            headerFormatter: headerFormatter,
        },
        {
            dataField: "installationDate",
            headerFormatter: headerFormatter,
            text: "Installation Date",
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            formatter: dateFormatter,
        },
        {
            dataField: "planType",
            headerFormatter: headerFormatter,
            text: "Plan Type",
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
        },
        {
            dataField: "lastName",
            headerFormatter: headerFormatter,
            text: "Last Name",
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
        },
        {
            dataField: "",
            headerFormatter: headerFormatter,
            text: "",
            isDummyField: true,
            formatter: (col, row) => {
                return <Delete className={classNames(styles.blueIcon, styles.toggleIcon)} onClick={(e) => handleShowActionModal(row)} />
            },
            headerFormatter: actionsFormatter,
            headerStyle: () => {
                return { width: "5px" };
            },
        },
    ];


    useEffect(() => {
        (state.type !== "paymenthistory" && state.type !== "servicerequest" && state.type !== "claims") && getContractsData();
        (state.type === "servicerequest") && getServiceRequestData();
        (state.type === "claims") && getClaimsData();
        (state.type === 'paymenthistory') && getPaymentContractsData();
    }, [state.sortData])

    const manageSort = (field, order) => {
        const reOrder = (state.sortData.sortOrder == 'asc') ? 'desc' : 'asc';
        let statusType = contractStatusType;
        if(state.type === "servicerequest") {
            statusType = serviceStatusTypeCheck;
        } else if(state.type === "claims") {
            statusType = claimStatusTypeCheck.claimStatus;
        }
        dispatch({
            type: 'SET_ORDER',
            sortData: { sortField: field, sortOrder: reOrder },
            contractStatusType: statusType
        })
    }

    const sortFunc = (a, b, order) => {
        if (order === "desc")
            return moment(b).valueOf() - moment(a).valueOf();
        else if (order === "asc")
            return moment(a).valueOf() - moment(b).valueOf();
    }

    const contractReportColumns = [
        {
            dataField: "contractNumber",
            text: "Contract #",
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            formatter: contractNumberFormatter,
            headerFormatter: headerFormatter,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
        },
        {
            dataField: "clientContractNumber",
            text: "Dealer PO#/Reg #",
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            headerFormatter: headerFormatter,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
        },
        {
            dataField: "statusName",
            headerFormatter: headerFormatter,
            text: "Status",
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
        },
        {
            dataField: "lastName",
            headerFormatter: headerFormatter,
            text: "Last Name",
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
        },
        {
            dataField: "totalAmount",
            headerFormatter: headerFormatter,
            text: "Plan Price",
            formatter: priceFormatter,
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
        },
        {
            dataField: "installationDate",
            headerFormatter: headerFormatter,
            formatter: dateFormatter,
            text: "Installation Date",
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
            sortFunc: (a, b, order) => {
                sortFunc(a, b, order);
            },
        },
        {
            dataField: "purchaseDate",
            text: "Purchase Date",
            headerFormatter: headerFormatter,
            formatter: dateFormatter,
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
            sortFunc: (a, b, order) => {
                sortFunc(a, b, order);
            },
        },
        {
            dataField: "paidDate",
            text: "Paid Date",
            headerFormatter: headerFormatter,
            formatter: dateFormatter,
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
            sortFunc: (a, b, order) => {
                sortFunc(a, b, order);
            },
        },
        {
            dataField: "userId",
            headerFormatter: headerFormatter,
            text: "",
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
            isDummyField: true,
            headerFormatter: actionsFormatter,
            headerStyle: () => {
                return { width: "5px" };
            },
        },
    ];

    //SEU-21263
    const getPendingDays = (row) => {
        const daysBuffer = 14;
        let daysPending = row.daysPending;
        let result = false;
        if (daysPending != "") {
            let duration = moment(daysPending, 'days');

            if (duration > daysBuffer) {
                result = true;
            }
        }
        return result;
    }
    //SEU-21263

    const pendingContractsColumns = [
        {
            dataField: "contractNumber",
            text: "Contract #",
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            headerFormatter: headerFormatter,
            formatter: (col, row) => {
                return <div style={{ position: 'relative' }}> {getPendingDays(row) && <span className={styles.exceptionBorder}></span>} <span><a className="btnLink" onClick={() => onViewContract(row)}>{col}</a></span> </div>
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
        },
        {
            dataField: "clientContractNumber",
            text: "Dealer PO#/Reg #",
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            headerFormatter: headerFormatter,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
        },
        {
            dataField: "statusName",
            headerFormatter: headerFormatter,
            text: "Status",
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
            formatter: (col, row) => {
                return "Pending Payment"
            },
        },
        {
            dataField: "lastName",
            headerFormatter: headerFormatter,
            text: "Last Name",
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
        },
        {
            dataField: "totalAmount",
            headerFormatter: headerFormatter,
            text: "Plan Price",
            formatter: pendingPriceFormatter,
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
        },
        {
            dataField: "installationDate",
            headerFormatter: headerFormatter,
            text: "Installation Date",
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            formatter: dateFormatter,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                if (order === "asc") {
                    if (a === "") return -1;
                    if (b === "") return 1;
                    return moment(b, dateFormat).diff(moment(a, dateFormat));
                }
                if (a === "") return 1;
                if (b === "") return -1;
                return moment(a, dateFormat).diff(moment(b, dateFormat));
            },
        },
        {
            dataField: "registerDate",
            text: "Register Date",
            headerFormatter: headerFormatter,
            formatter: dateFormatter,
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                if (order === "asc") {
                    if (a === "") return -1;
                    if (b === "") return 1;
                    return moment(b, dateFormat).diff(moment(a, dateFormat));
                }
                if (a === "") return 1;
                if (b === "") return -1;
                return moment(a, dateFormat).diff(moment(b, dateFormat));
            },
        },
        {
            dataField: "DaysPending",
            text: "Days Pending",
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            headerFormatter: headerFormatter,
            formatter: pendingDaysFormatter,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
            sortFunc: (a, b, order, dataField) => {
                if (order === 'asc') {
                    return parseInt(b) - parseInt(a);
                }
                return parseInt(a) - parseInt(b); // desc
            }

        },
        {
            dataField: "userId",
            headerFormatter: headerFormatter,
            text: "",
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    setSinglePendingContract([row]);
                    setSinglePendingSelection(true);
                    setShowPendingContractsModal(true);
                    setPendingContractsModalType('Cancel');
                },
            },
            isDummyField: true,
            headerFormatter: actionsFormatter,
            formatter: (col, row) => {

                return <div> <Delete className={classNames(styles.blueIcon, styles.toggleIcon)} /> </div>
            },
            headerStyle: () => {
                return { width: "5px" };
            },
        },
    ];

    const paymentContractsColumns = [
        {
            dataField: "cardNumber",
            text: "Payment Type",
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            headerFormatter: headerFormatter,
            formatter: paymentTypeFormatter
        },
        {
            dataField: "contractNumber",
            text: "Contract #",
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            headerFormatter: headerFormatter,
            formatter: (col, row) => {
                return <div style={{ position: 'relative' }}> <span><a className="btnLink" onClick={() => onViewContract(row)}>{col}</a></span> </div>
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
        },
        {
            dataField: "clientContractNumber",
            text: "Dealer PO#/Reg #",
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            headerFormatter: headerFormatter,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
        },
        {
            dataField: "statusName",
            headerFormatter: headerFormatter,
            text: "Status",
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
        },
        {
            dataField: "lastName",
            headerFormatter: headerFormatter,
            text: "Last Name",
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
        },
        {
            dataField: "totalAmount",
            headerFormatter: headerFormatter,
            text: "Amount",
            formatter: pricePaymentHistoryFormatter,
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
        },
        {
            dataField: "transactionDate",
            headerFormatter: headerFormatter,
            text: "Transaction Date",
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            formatter: dateFormatter,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                if (order === "asc") {
                    if (a === "") return -1;
                    if (b === "") return 1;
                    return moment(b, dateFormat).diff(moment(a, dateFormat));
                }
                if (a === "") return 1;
                if (b === "") return -1;
                return moment(a, dateFormat).diff(moment(b, dateFormat));
            },
        },
        {
            dataField: "userId",
            headerFormatter: headerFormatter,
            text: "",
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
            isDummyField: true,
            headerFormatter: actionsFormatter,
            headerStyle: () => {
                return { width: "5px" };
            },
        },
    ];

    const serviceRequestColumns = [
        {
            dataField: "eventNumber",
            text: "Event #",
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            headerFormatter: headerFormatter,
            formatter: (col, row) => {
                return <div style={{ position: 'relative' }}> <span><a className="btnLink" onClick={() => onViewRFSData(row)}>{col}</a></span> </div>
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
            headerStyle: { width: '8%' }
        },
        {
            dataField: "requestDate",
            text: "Requested Date",
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            headerFormatter: headerFormatter,
            formatter: dateFormatter,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                if (order === "desc") {
                    if (a === "") return -1;
                    if (b === "") return 1;
                    return moment(b, dateFormat).diff(moment(a, dateFormat));
                }
                if (a === "") return 1;
                if (b === "") return -1;
                return moment(a, dateFormat).diff(moment(b, dateFormat));
            },
        },
        {
            dataField: "statusName",
            headerFormatter: headerFormatter,
            text: "Status",
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
        },
        {
            dataField: "contractNumber",
            text: "Contract #",
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            headerFormatter: headerFormatter,
            formatter: (col, row) => {
                return <div style={{ position: 'relative' }}> 
                        {(row.dealerId === dealerId) && 
                            <span><a className="btnLink" onClick={() => onViewContract(row)}>{col}</a></span>
                        }
                    </div>
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
        },
        {
            dataField: "lastName",
            headerFormatter: headerFormatter,
            text: "Last Name",
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
        },
        {
            dataField: "coveredItem",
            headerFormatter: headerFormatter,
            text: "Covered Item",
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
            headerStyle: { width: '13%' }
        },
        {
            dataField: "address",
            headerFormatter: headerFormatter,
            text: "Address",
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            headerStyle: { width: '32%' }
        },
        {
            dataField: "userId",
            headerFormatter: headerFormatter,
            text: "",
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
            isDummyField: true,
            headerFormatter: actionsFormatter,
            headerStyle: () => {
                return { width: "5px" };
            },
        },
    ];

    const claimsColumns = [
        {
            dataField: "claimNumber",
            text: "Claim #",
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            headerFormatter: headerFormatter,
            formatter: (col, row) => {
                return <div style={{ position: 'relative' }}> <span><a className="btnLink" onClick={() => onViewInvoice(row)}>{col}</a></span> </div>
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
        },
        {
            dataField: "claimStatusName",
            headerFormatter: headerFormatter,
            text: "Status",
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
            formatter: (col, row) => {
                return <div style={{ position: 'relative', maxWidth: '80px' }}>{col}</div>
            },
        },
        {
            dataField: "invoiceNumber",
            text: "Invoice #",
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            headerFormatter: headerFormatter,
            formatter: (col, row) => {
                return <div className={styles.claimsHeader}> <span><a className="btnLink" onClick={() => onViewInvoice(row)}>{col}</a></span> </div>
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
        },
        {
            dataField: "contractNumber",
            text: "Contract #",
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            headerFormatter: headerFormatter,
            formatter: (col, row) => {
                return <div style={{ position: 'relative' }}> <span><a className="btnLink" onClick={() => onViewContract(row)}>{col}</a></span> </div>
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
        },
        {
            dataField: "lastName",
            headerFormatter: headerFormatter,
            text: "Last Name",
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
        },
        {
            dataField: "assetName",
            headerFormatter: headerFormatter,
            text: "Covered Item",
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
            formatter: (col, row) => {
                return <div style={{ position: 'relative', maxWidth: '80px' }}>{col}</div>
            },
        },
        {
            dataField: "invoiceDate",
            headerFormatter: headerFormatter,
            text: "Invoice Date",
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            formatter: dateFormatter,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                if (order === "asc") {
                    if (a === "") return -1;
                    if (b === "") return 1;
                    return moment(b, dateFormat).diff(moment(a, dateFormat));
                }
                if (a === "") return 1;
                if (b === "") return -1;
                return moment(a, dateFormat).diff(moment(b, dateFormat));
            },
        },
        {
            dataField: "invoiceAmount",
            headerFormatter: headerFormatter,
            text: "Total Paid",
            formatter: priceFormatter,
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
        },
        {
            dataField: "denialAmount",
            headerFormatter: headerFormatter,
            text: "Total Denied",
            formatter: priceFormatter,
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
        },
        {
            dataField: "checkNumber",
            headerFormatter: headerFormatter,
            text: "Payment",
            sort: true,
            onSort: (field, order) => {
                manageSort(field, order);
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                },
            },
        },
        {
            dataField: "claimId",
            headerFormatter: headerFormatter,
            text: "",
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleCellClick(row);
                    e.stopPropagation();
                },
            },
            isDummyField: true,
            headerFormatter: actionsFormatter,
            headerStyle: () => {
                return { width: "5px" };
            },
        },
    ];

    const toggle = () => {
        setDropDownOpen(!dropdownOpen);
    };

    const showHideDates = (data, format) => {
        data.map(x => {
            let installDate = x.installationDate;
            let purchaseDate = moment(x.purchaseDate, format).format(format);

            installDate = installDate == nullDefaultDate ? "" : moment(installDate, format).format(format);
            x.installationDate = installDate;
            x.purchaseDate = ((purchaseDate == null) || (installDate == "") || (moment(purchaseDate, format).isBefore(moment(installDate, format)))) ? "" : purchaseDate;
        });

        return data;
    }

    const handleDeleteRow = () => {
        // for future use when you want to update multiple rows.
        //const rowsToDelete = tableData.filter(e => selectedMulipleRow.includes(e.contractId));
        const rowsToDelete = tableData.filter(e => e.savedSaleId === selectedRow.savedSaleId)
        updateRow(rowsToDelete, ContractStatusId.Deleted);

        setShowDeleteModal(false);
    };

    const updateRow = (data, status) => {
        let updatedData = data.map(x => {
            return {
                savedSaleId: x.savedSaleId,
                saleInfo: '{}',
                statusId: status,
                createdBy: '-',
                modifiedBy: '-'
            }
        });

        let content = JSON.stringify(updatedData);
        let request = {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: content
        };

        fetch('contract/SetMultipleSavedSaleStatus', request)
            .then(res => res.json())
            .then(data => {
                const savedIds = data.map(x => { return x.savedSaleId });
                const filter = tableData.filter(x => !savedIds.includes(x.savedSaleId));

                setColumn(filter);
                setTableData(filter);
            });
    }

    const handleDeleteMultipleRow = () => {
        const filter = tableData.filter(e => !selectedMulipleRow.includes(e.contractId));
        setColumn(filter);
        setTableData(filter);
        setMultipleShowDeleteModal(false);
        setSelectedMultipleRow([]);
    }

    const handleModalClose = async (selected, openTab, status) => {
        getContractsData();
        setPendingContractsSelected(selected);
        setSinglePendingSelection(false);

        setShowPendingContractsModal(false);
        if (status == "cancelSuccess" || status == "payment") {
            setShowSnackBar(true)

            let selectedMulipleRowTemp = [...selectedMulipleRow];
            let selectedMultipleRowDataTemp = [...selectedMultipleRowData];

            selected.forEach(r => {
                selectedMulipleRowTemp = [...selectedMulipleRowTemp].filter(x => x !== r.contractId)
                selectedMultipleRowDataTemp = [...selectedMultipleRowDataTemp].filter(x => x.contractId !== r.contractId)
            });
            setSelectedMultipleRow(selectedMulipleRowTemp);
            setSelectedMultipleRowData(selectedMultipleRowDataTemp);

            if (status == "cancelSuccess") {
                await sessionContext.updateNotificationAndAlert(NotificationIds.AutoPayError, tableData.length - selected.length);
                await sessionContext.updateNotificationAndAlert(NotificationIds.PendingContracts, tableData.length - selected.length);
            }
        } else {
            setShowSnackBar(false);
        }

        if (status == "payment") {
            sessionContext.pendingReportTrack.selecteContractId = [...selectedMulipleRow];
            sessionContext.pendingReportTrack.selecteContractRows = [...selectedMultipleRowData];
            sessionContext.pendingReportTrack.contractType = "pendingContractReport";
            sessionContext.pendingReportTrack.pendingBackFlag = true;
        }

        if (selected.length !== undefined && openTab) {
            sessionContext.setSourcePage({
                sourcePage: {
                    pageId: "Reports",
                    targetTab: 5,
                    contracts: selected
                }
            });

            sessionContext.tabDispatch('ADD_TAB', {
                title: "Make a Payment",
                tabType: "orderinfo",
                contractNumber: tableData.contractNumber,
            });

            sessionContext.setFromPendingPaymentScreen(true);
        }
    }

    const handleContinueSales = (currentRow) => {
        if (currentRow != null) {
            sessionContext.tabDispatch('ADD_TAB', {
                title: "Sale Entry",
                tabType: "orderinfo",
            });

            sessionContext.setSourcePage({
                sourcePage: {
                    pageId: "Reports_IncompleteSales",
                    targetPageState: currentRow
                }
            });
        }
    }

    const calculateOrderTotal = (data) => {
        setOrderTotal(data.reduce((acc, val) => (acc + parseFloat(val.totalAmount)), 0).toLocaleString(undefined, { maximumFractionDigits: 2 }));
    }

    if (!tableData || !tableColumns || showContractsLoader) {
        return (
            <Container className="pageLoading">
                {errorMessage ? (
                    <div className="centeredXY text-center">
                        <p>{errorMessage}</p>
                    </div>
                ) : (
                    <div className="centeredXY text-center">
                        <PageLoading text="Report is Loading" />
                    </div>
                )}
            </Container>
        );
    }

    let totalRowCount = (filterdColumn || tableData).length;
    
    return (
        <>
            {showDeleteModal && (<DeleteSale isOpen={true} centered={true} toggle={() => setShowDeleteModal(false)} handleDelete={handleDeleteRow} />)}
            {showMultipleDeleteModal && <DeleteSale isOpen={true} centered={true} toggle={() => setMultipleShowDeleteModal(false)} handleDelete={handleDeleteMultipleRow} />}
            <div
                className="tab-pane show active"
                id="main-contract-panel"
                role="tabpanel"
                aria-labelledby="main-contract-tab"
            >
                <SubtabNav handleReportType={handleReportType} />
                {subtab?.subtabType !== null && subtab?.subtabType === 'staticclaiminvoice' ? (
                    <Container className="contentWrapper">
                        <StaticClaim contract={contract} />
                    </Container>)
                    :
                    <ToolkitProvider
                        keyField={keyField}
                        columns={tableColumns}
                        search={{
                            afterSearch: (newResult) => {
                                setFilterdColumn(newResult);
                                calculateOrderTotal(newResult);
                                dispatch({
                                    type: "SET_TABLEDATA",
                                    payload: newResult,
                                });
                            }
                        }}
                        bootstrap4
                        classses={classNames(table, claimSummary)}
                        id="recTable"
                        defaultSorted={defaultSortedBy}
                        data={column || tableData || []}
                        ref={(n) => (this.toolkit = n)}
                    >
                        {(props) => (
                            <Container className="contentWrapper">
                                {state.type == "allcontracts" && (
                                    <div className={classNames("row", styles.queueHeader, "no-gutters")}>
                                        <ReportsFilter selection={state.type} />
                                        <div className={classNames("col-md-6", "col-lg-6", styles.queueRightContent, "queueHeaderWrapper")}>
                                            <div className="col-lg-5 col-sm-5 col-6 date-range-picker input-group">
                                                <DateRangePicker
                                                    startDate={startDate}
                                                    minimumNights={0}
                                                    startDateId="rangeStartDate"
                                                    endDate={endDate}
                                                    endDateId="rangeEndDate"
                                                    onDatesChange={handleDateRangeChange}
                                                    isOutsideRange={(day) => day.isAfter(moment().add(1, 'days'))}
                                                    focusedInput={focusedInput}
                                                    onFocusChange={(focus) => setFocusedInput(focus)}
                                                    displayFormat={dateFormat}
                                                    showClearDates={true}
                                                />
                                                {!state.startDate && !state.endDate && <Calendar className={styles.dateFilterCalendar} />}
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-xs-5 input-group search-input">
                                                <CustomSearch
                                                    {...props.searchProps}
                                                    onTableSearch={getContractsData}
                                                    searchKey={searchText}
                                                    setSearchText={setSearchText}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {state.type === "contracts" && (
                                    <div className={classNames("row", styles.queueHeader, "no-gutters")}>
                                        <div className={classNames("col-md-6", "col-xs-12", styles.queueLeftContent, "queueHeaderWrapper")}>
                                            <div className="col-5 col-sm-7 col-lg-5 col-xs-7">
                                                <ReportsFilter />
                                            </div>
                                            <div className="col-lg-3 col-sm-5 col-5 mt-2 queueDropdown">
                                                <DropDownInput
                                                    name={"installationState"}
                                                    options={contractTypeOptions}
                                                    className="form-control"
                                                    defaultValue={contractStatusTypeCheck || "allcontracts"}
                                                    onChangedValue={onContractFilterChange}
                                                />
                                            </div>
                                        </div>
                                        <div className={classNames("col-md-6", "col-lg-6", styles.queueRightContent, "queueHeaderWrapper")}>
                                            <div className="col-lg-5 col-sm-5 col-6">
                                                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                                    <DropdownToggle className="dropbtn dropbtn-placeholder form-control reportsOtherDropdown">
                                                        Date Filter
                                                        <DownArrow className="dropbtnIcon" />
                                                    </DropdownToggle>
                                                    <DropdownMenu className={`queuereport-dropmenu ${dropdownOpen ? styles.dropmenu : ""}`} >
                                                        <div className="col-12 date-range-picker input-group">
                                                            <DateRangePicker
                                                                startDate={startDate}
                                                                minimumNights={0}
                                                                startDateId="rangeStartDate"
                                                                endDate={endDate}
                                                                endDateId="rangeEndDate"
                                                                onDatesChange={handleDateRangeChange}
                                                                focusedInput={focusedInput}
                                                                onFocusChange={(focus) => setFocusedInput(focus)}
                                                                displayFormat={dateFormat}
                                                                showClearDates={true}
                                                                isOutsideRange={(day) => day.isAfter(moment().add(1, 'days'))}
                                                            />
                                                            {!state.startDate && !state.endDate && <Calendar className={styles.dateFilterCalendar} />}
                                                        </div>
                                                        <div className="datesGroup">
                                                            <div className="radioButton">
                                                                <Input type="radio" value={ContractDateFilter.Install} checked={dateFilter === ContractDateFilter.Install}
                                                                    name="dateFilter" id="installation" onChange={onValueChange} />
                                                                <Label className="radio-controls-label" for="installation">Installation Date</Label>
                                                            </div>
                                                            <div className="radioButton">
                                                                <Input type="radio" value={ContractDateFilter.Purchase} checked={dateFilter === ContractDateFilter.Purchase}
                                                                    name="dateFilter" id="purchase" onChange={onValueChange} />
                                                                <Label className="radio-controls-label" for="purchase">Purchase Date</Label>
                                                            </div>
                                                            <div className="radioButton">
                                                                <Input type="radio" value={ContractDateFilter.Paid} checked={dateFilter === ContractDateFilter.Paid}
                                                                    name="dateFilter" id="paid" onChange={onValueChange} />
                                                                <Label className="radio-controls-label" for="paid">Paid Date</Label>
                                                            </div>
                                                        </div>
                                                        <div className={styles.dropmenuactions}>
                                                            <button className="btn btnLink" onClick={onClearDateFilter}>Clear All</button>
                                                            <Button color="primary" onClick={onApplyDateFilter}>Apply</Button>
                                                        </div>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </div>

                                            <div className="col-lg-4 col-sm-6 col-xs-5 input-group search-input">
                                                <CustomSearch
                                                    {...props.searchProps}
                                                    onTableSearch={getContractsData}
                                                    searchKey={searchText}
                                                    setSearchText={setSearchText}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {state.type === "activecontracts" && (
                                    <div className={classNames("row", styles.queueHeader, "no-gutters")}>
                                        <div className={classNames("col-md-6", "col-xs-12", styles.queueLeftContent, "queueHeaderWrapper")}>
                                            <div className="col-5 col-sm-7 col-lg-5 col-xs-7">
                                                <ReportsFilter />
                                            </div>
                                            <div className="col-lg-3 col-sm-5 col-5 mt-2 queueDropdown">
                                                <DropDownInput
                                                    name={"installationState"}
                                                    options={contractTypeOptions}
                                                    className="form-control"
                                                    defaultValue="active"
                                                    placeholder="Contracts"
                                                    onChangedValue={onContractFilterChange}
                                                />
                                            </div>
                                        </div>
                                        <div className={classNames("col-md-6", "col-lg-6", styles.queueRightContent, "queueHeaderWrapper")}>
                                            <div className="col-lg-5 col-sm-5 col-6">
                                                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                                    <DropdownToggle className="dropbtn dropbtn-placeholder form-control">Date Filter</DropdownToggle>
                                                    <DropdownMenu className={`queuereport-dropmenu ${dropdownOpen ? styles.dropmenu : ""}`} >
                                                        <div className="col-12 date-range-picker input-group">
                                                            <DateRangePicker
                                                                startDate={startDate}
                                                                minimumNights={0}
                                                                startDateId="rangeStartDate"
                                                                endDate={endDate}
                                                                endDateId="rangeEndDate"
                                                                onDatesChange={handleDateRangeChange}
                                                                focusedInput={focusedInput}
                                                                onFocusChange={(focus) => setFocusedInput(focus)}
                                                                displayFormat={dateFormat}
                                                                showClearDates={true}
                                                                isOutsideRange={(day) => day.isAfter(moment().add(1, 'days'))}
                                                            />
                                                            {!state.startDate && !state.endDate && <Calendar className={styles.dateFilterCalendar} />}
                                                        </div>
                                                        <div className="datesGroup">
                                                            <div className="radioButton">
                                                                <Input type="radio" value={ContractDateFilter.Install} checked={dateFilter === ContractDateFilter.Install}
                                                                    name="dateFilter" id="installation" onChange={onValueChange} />
                                                                <Label className="radio-controls-label" for="installation">Installation Date</Label>
                                                            </div>
                                                            <div className="radioButton">
                                                                <Input type="radio" value={ContractDateFilter.Purchase} checked={dateFilter === ContractDateFilter.Purchase}
                                                                    name="dateFilter" id="purchase" onChange={onValueChange} />
                                                                <Label className="radio-controls-label" for="purchase">Purchase Date</Label>
                                                            </div>
                                                            <div className="radioButton">
                                                                <Input type="radio" value={ContractDateFilter.Paid} checked={dateFilter === ContractDateFilter.Paid}
                                                                    name="dateFilter" id="paid" onChange={onValueChange} />
                                                                <Label className="radio-controls-label" for="paid">Paid Date</Label>
                                                            </div>
                                                        </div>
                                                        <div className={styles.dropmenuactions}>
                                                            <button className="btn btnLink" onClick={onClearDateFilter}>Clear All</button>
                                                            <Button color="primary" onClick={onApplyDateFilter}>Apply</Button>
                                                        </div>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </div>

                                            <div className="col-lg-4 col-sm-6 col-xs-5 input-group search-input">
                                                <SearchBar {...props.searchProps} placeholder="Filter Records" />
                                                {props.searchProps.searchText ?
                                                    <Close className={styles.filterCloseIcon} onClick={() => props.searchProps.onClear()} />
                                                    :
                                                    <SearchIcon className={styles.filterSearchIcon} />}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {state.type === "pendingcontracts" && (
                                    <div className={classNames("row", styles.queueHeader, "no-gutters")}>
                                        <div className={classNames("col-md-6", "col-xs-12", styles.queueLeftContent, "queueHeaderWrapper")}>
                                            <div className="col-5 col-sm-7 col-lg-5 col-xs-7">
                                                <ReportsFilter />
                                            </div>
                                        </div>
                                        <div className={classNames("col-md-6", "col-lg-6", styles.queueRightContent, "queueHeaderWrapper")}>
                                            <div className="col-lg-5 col-sm-5 col-6">
                                                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                                    <DropdownToggle className="dropbtn dropbtn-placeholder form-control">Date Filter</DropdownToggle>
                                                    <DropdownMenu className={`queuereport-dropmenu ${dropdownOpen ? styles.dropmenu : ""}`} >
                                                        <div className="col-12 date-range-picker input-group">
                                                            <DateRangePicker
                                                                startDate={startDate}
                                                                minimumNights={0}
                                                                startDateId="rangeStartDate"
                                                                endDate={endDate}
                                                                endDateId="rangeEndDate"
                                                                onDatesChange={handleDateRangeChange}
                                                                focusedInput={focusedInput}
                                                                onFocusChange={(focus) => setFocusedInput(focus)}
                                                                displayFormat={dateFormat}
                                                                showClearDates={true}
                                                                isOutsideRange={(day) => day.isAfter(moment().add(1, 'days'))}
                                                            />
                                                            {!state.startDate && !state.endDate && <Calendar className={styles.dateFilterCalendar} />}
                                                        </div>
                                                        <div className="datesGroup">
                                                            <div className="radioButton">
                                                                <Input type="radio" value={ContractDateFilter.Install} checked={dateFilter === ContractDateFilter.Install}
                                                                    name="dateFilter" id="installation" onChange={onValueChange} />
                                                                <Label className="radio-controls-label" for="installation">Installation Date</Label>
                                                            </div>
                                                            <div className="radioButton">
                                                                <Input type="radio" value={ContractDateFilter.Register} checked={dateFilter === ContractDateFilter.Register}
                                                                    name="dateFilter" id="register" onChange={onValueChange} />
                                                                <Label className="radio-controls-label" for="register">Register Date</Label>
                                                            </div>
                                                        </div>
                                                        <div className={styles.dropmenuactions}>
                                                            <button className="btn btnLink" onClick={onClearDateFilter}>Clear All</button>
                                                            <Button color="primary" onClick={onApplyDateFilter}>Apply</Button>
                                                        </div>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-xs-5 input-group search-input">
                                                <CustomSearch
                                                    {...props.searchProps}
                                                    onTableSearch={getContractsData}
                                                    searchKey={searchText}
                                                    setSearchText={setSearchText}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {state.type === "incompletesales" && (
                                    <div className={classNames("row", styles.queueHeader, "no-gutters")}>
                                        <div className={classNames("col-md-6", "col-xs-12", styles.queueLeftContent, "queueHeaderWrapper")}>
                                            <div className="col-5 col-sm-7 col-lg-5 col-xs-7">
                                                <ReportsFilter />
                                            </div>
                                        </div>
                                        <div className={classNames("col-md-6", "col-lg-6", styles.queueRightContent, "queueHeaderWrapper")}>
                                            <div className="col-lg-5 col-sm-5 col-6">
                                                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                                    <DropdownToggle className="dropbtn dropbtn-placeholder form-control">Date Filter</DropdownToggle>
                                                    <DropdownMenu className={`queuereport-dropmenu ${dropdownOpen ? styles.dropmenu : ""}`} >
                                                        <div className="col-12 date-range-picker input-group">
                                                            <DateRangePicker
                                                                startDate={startDate}
                                                                minimumNights={0}
                                                                startDateId="rangeStartDate"
                                                                endDate={endDate}
                                                                endDateId="rangeEndDate"
                                                                onDatesChange={handleDateRangeChange}
                                                                focusedInput={focusedInput}
                                                                onFocusChange={(focus) => setFocusedInput(focus)}
                                                                displayFormat={dateFormat}
                                                                showClearDates={true}
                                                                isOutsideRange={(day) => day.isAfter(moment().add(1, 'days'))}
                                                            />
                                                            {!state.startDate && !state.endDate && <Calendar className={styles.dateFilterCalendar} />}
                                                        </div>
                                                        <div className="datesGroup">
                                                            <div className="radioButton">
                                                                <Input type="radio" value={ContractDateFilter.Install} checked={dateFilter === ContractDateFilter.Install}
                                                                    name="dateFilter" id="installation" onChange={onValueChange} />
                                                                <Label className="radio-controls-label" for="installation">Installation Date</Label>
                                                            </div>
                                                            <div className="radioButton">
                                                                <Input type="radio" value={ContractDateFilter.Save} checked={dateFilter === ContractDateFilter.Save}
                                                                    name="dateFilter" id="save" onChange={onValueChange} />
                                                                <Label className="radio-controls-label" for="save">Saved Date</Label>
                                                            </div>
                                                        </div>
                                                        <div className={styles.dropmenuactions}>
                                                            <button className="btn btnLink" onClick={onClearDateFilter}>Clear All</button>
                                                            <Button color="primary" onClick={onApplyDateFilter}>Apply</Button>
                                                        </div>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </div>

                                            <div className="col-lg-4 col-sm-6 col-xs-5 input-group search-input">
                                                <CustomSearch
                                                    {...props.searchProps}
                                                    onTableSearch={getContractsData}
                                                    searchKey={searchText}
                                                    setSearchText={setSearchText}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {state.type === "paymenthistory" && (
                                    <div className={classNames("row", styles.queueHeader, "no-gutters")}>
                                        <div className={classNames("col-md-6", "col-xs-12", styles.queueLeftContent, "queueHeaderWrapper")}>
                                            <div className="col-6 col-sm-7 col-lg-6 col-xs-7">
                                                <PaymentHistoryFilter selection={state.type} />
                                            </div>
                                            <div className="col-lg-3 col-sm-5 col-5 mt-2 queueDropdown">
                                                <DropDownInput
                                                    name={"installationState"}
                                                    options={paymentTypeOptions}
                                                    className="form-control"
                                                    defaultValue={paymentHistoryStatusTypeCheck.statusId || "allpayments"}
                                                    onChangedValue={onPaymentFilterChange}
                                                />
                                            </div>
                                        </div>
                                        <div className={classNames("col-md-6", "col-lg-6", styles.queueRightContent, "queueHeaderWrapper")}>
                                            <div className="col-lg-5 col-sm-5 col-6">
                                                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                                    <DropdownToggle className="dropbtn dropbtn-placeholder form-control">Date Filter</DropdownToggle>
                                                    <DropdownMenu className={`queuereport-dropmenu ${dropdownOpen && styles.dropmenu}`} >
                                                        <div className="col-12 date-range-picker input-group">
                                                            <DateRangePicker
                                                                startDate={startDate}
                                                                minimumNights={0}
                                                                startDateId="rangeStartDate"
                                                                endDate={endDate}
                                                                endDateId="rangeEndDate"
                                                                onDatesChange={handleDateRangeChange}
                                                                focusedInput={focusedInput}
                                                                onFocusChange={(focus) => setFocusedInput(focus)}
                                                                displayFormat={dateFormat}
                                                                showClearDates={true}
                                                                isOutsideRange={(day) => day.isAfter(moment().add(1, 'days'))}
                                                            />
                                                            {!state.startDate && !state.endDate && <Calendar className={styles.dateFilterCalendar} />}
                                                        </div>
                                                        <div className="datesGroup">
                                                            <div className="radioButton">
                                                                <Input type="radio" value={ContractDateFilter.Transaction} checked={dateFilter === ContractDateFilter.Transaction}
                                                                    name="dateFilter" id="transaction" onChange={onValueChange} />
                                                                <Label className="radio-controls-label" for="transaction">Transaction Date</Label>
                                                            </div>

                                                        </div>
                                                        <div className={styles.dropmenuactions}>
                                                            <button className="btn btnLink" onClick={onClearDateFilter}>Clear All</button>
                                                            <Button color="primary" onClick={onApplyDateFilter}>Apply</Button>
                                                        </div>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-xs-5 input-group search-input">
                                                <CustomSearch
                                                    {...props.searchProps}
                                                    onTableSearch={getPaymentContractsData}
                                                    searchKey={searchText}
                                                    setSearchText={setSearchText}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                )}
                                {state.type === "servicerequest" && (
                                    <div className={classNames("row", styles.queueHeader, "no-gutters")}>
                                        <div className={classNames("col-md-6", "col-xs-12", styles.queueLeftContent, "queueHeaderWrapper")}>
                                            <div className="col-6 col-sm-7 col-lg-6 col-xs-7">
                                                <ServiceRequestFilter selection={state.type} />
                                            </div>
                                            <div className="col-lg-3 col-sm-5 col-5 mt-2 queueDropdown">
                                                <DropDownInput
                                                    name={"installationState"}
                                                    options={serviceRequestOptions}
                                                    className="form-control"
                                                    defaultValue={serviceStatusTypeCheck || "allrequests"}
                                                    onChangedValue={onServiceRequestFilterChange}
                                                />
                                            </div>
                                        </div>

                                        <div className={classNames("col-md-6", "col-lg-6", styles.queueRightContent, "queueHeaderWrapper")}>
                                            <div className="col-lg-5 col-sm-5 col-6">

                                                <div className="col-12 date-range-picker input-group service-req-date-picker">
                                                    <DateRangePicker
                                                        startDate={startDate}
                                                        minimumNights={0}
                                                        startDateId="serviceRangeStartDate"
                                                        endDate={endDate}
                                                        endDateId="serviceRangeEndDate"
                                                        onDatesChange={handleClaimDateRangeChange}
                                                        focusedInput={focusedInput}
                                                        onFocusChange={(focus) => setFocusedInput(focus)}
                                                        displayFormat={dateFormat}
                                                        showClearDates={true}
                                                        isOutsideRange={(day) => day.isAfter(moment().add(1, 'days'))}
                                                    />
                                                    {!state.startDate && !state.endDate && <Calendar className={styles.dateFilterCalendar} />}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-xs-5 input-group search-input">
                                                <CustomSearch
                                                    {...props.searchProps}
                                                    onTableSearch={getServiceRequestData}
                                                    searchKey={searchText}
                                                    setSearchText={setSearchText}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {state.type === "claims" && (
                                    <div className={classNames("row", styles.queueHeader, "no-gutters")}>
                                        <div className={classNames("col-md-6", "col-xs-12", styles.queueLeftContent, "queueHeaderWrapper")}>
                                            <div className="col-5 col-sm-6 col-lg-5 col-xs-6">
                                                <ReportsFilter />
                                            </div>
                                            <div className="col-lg-3 col-sm-5 col-5 mt-2 queueDropdown">
                                                <DropDownInput
                                                    name={"installationState"}
                                                    options={claimOptions}
                                                    className="form-control"
                                                    defaultValue={claimStatusTypeCheck.claimStatus || "allclaims"}
                                                    onChangedValue={onClaimFilterChange}
                                                />
                                            </div>
                                        </div>

                                        <div className={classNames("col-md-5", "col-lg-6", styles.queueRightContent, "queueHeaderWrapper")}>
                                            <div className="col-lg-5 col-sm-5 col-6">
                                                <div className="col-12 date-range-picker input-group service-req-date-picker">
                                                    <DateRangePicker
                                                        startDate={startDate}
                                                        minimumNights={0}
                                                        startDateId="claimRangeStartDate"
                                                        endDate={endDate}
                                                        endDateId="claimRangeEndDate"
                                                        onDatesChange={handleClaimDateRangeChange}
                                                        focusedInput={focusedInput}
                                                        onFocusChange={(focus) => setFocusedInput(focus)}
                                                        displayFormat={dateFormat}
                                                        showClearDates={true}
                                                        isOutsideRange={(day) => day.isAfter(moment().add(1, 'days'))}
                                                    />
                                                    {!state.startDate && !state.endDate && <Calendar className={styles.dateFilterCalendar} />}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-xs-5 input-group search-input">
                                                <CustomSearch
                                                    {...props.searchProps}
                                                    onTableSearch={getClaimsData}
                                                    searchKey={searchText}
                                                    setSearchText={setSearchText}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <Card>
                                    <CardBody>
                                        {/* {state.type !== "paymenthistory" && */}
                                            <PaginationProvider
                                                pagination={
                                                    paginationFactory(customPaginationOptions)
                                                }
                                            >
                                                {
                                                    ({
                                                        paginationProps,
                                                        paginationTableProps
                                                    }) => (
                                                        <>
                                                            <BootstrapTable
                                                                remote={true}
                                                                id="contractTable"
                                                                wrapperClasses={classNames("table-responsive", styles.tableWrapper)}
                                                                classes={styles.claimSummaryTable}
                                                                keyField={keyField}
                                                                data={column || tableData || []}
                                                                columns={tableColumns || []}
                                                                bordered={false}
                                                                selectRow={selectRow}
                                                                onTableChange={onTableChange}
                                                                {...paginationTableProps}
                                                            />
                                                            <div>
                                                                <PaginationListStandalone
                                                                    {...paginationProps}
                                                                />
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </PaginationProvider>
                                        {/* } */}
                                        {/* {(state.type === "paymenthistory") &&
                                            <BootstrapTable
                                                remote={false}
                                                id="serviceRequestTable"
                                                wrapperClasses={classNames("table-responsive", styles.tableWrapper)}
                                                classes={styles.claimSummaryTable}
                                                defaultSorted={defaultSortedBy}
                                                keyField={keyField}
                                                data={column || tableData || []}
                                                columns={tableColumns || []}
                                                bordered={false}
                                                selectRow={selectRow}
                                                pagination={paginationFactory(options)}
                                                {...props.baseProps}
                                            />
                                        } */}
                                    </CardBody>
                                </Card>
                                {state.type === "incompletesales" && <div className="btnWrapper float-right">
                                    <button className="btn-primary mb-3" disabled={selectedMulipleRow.length <= 0} onClick={handleDeleteShowActionModal}>Delete Selected </button>
                                </div>}

                                {(state.type === "pendingcontracts") && (
                                    <Container>
                                        <Row xs={6}>
                                            <Col>
                                                <div className={styles.orderTotal}>
                                                    <NeutralInfoBadge text="Order Total:" amount={orderTotal} currency={currency}/>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className={styles.buttonContainer}>
                                                    <button
                                                        className="mb-3 btnLink"
                                                        disabled={selectedMulipleRow.length <= 0}
                                                        onClick={() => {
                                                            setShowPendingContractsModal(true);
                                                            setPendingContractsModalType('Cancel');
                                                        }}
                                                    >
                                                        Cancel Selected
                                                    </button>
                                                    <button
                                                        className="btn-primary mb-3"
                                                        disabled={selectedMulipleRow.length <= 0}
                                                        onClick={() => {
                                                            setShowPendingContractsModal(true);
                                                            setPendingContractsModalType('Pay');
                                                        }}
                                                    >
                                                        Pay Selected
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                )}

                                {!singlePendingSelection &&
                                    <PendingContractsSelectionModal
                                        open={showPendingContractsModal}
                                        type={pendingContractsModalType}
                                        data={selectedMultipleRowData}
                                        onClose={handleModalClose} />
                                }

                                {singlePendingSelection &&
                                    <PendingContractsSelectionModal
                                        open={showPendingContractsModal}
                                        type={pendingContractsModalType}
                                        data={singlePendingContract}
                                        onClose={handleModalClose} />
                                }

                                {showSnackBar &&
                                    <SnackBarAlert
                                        content={`${pendingContractsSelected.length} pending contract(s) ${pendingContractsModalType === 'Cancel' ? 'canceled successfully' : 'payment successful'}`}
                                        showSnackBar={true}
                                        onClose={() => setShowSnackBar(false)}
                                    />
                                }
                            </Container>
                        )}
                    </ToolkitProvider>
                }
            </div>
        </>
    );
}

export default ReportsContainer;