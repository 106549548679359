import { phoneFormat } from '../shared/util'

export const brandContent = {
    lennox: {
        clientName: 'Lennox',
        clientSite: 'www.lennoxpros.com',
        programName: 'Comfort Shield',
        programPhone: phoneFormat('866-816-2434'),
        programEmail: 'Warranty-ComfortShieldClaims@aig.com',
        advantageProgramName: 'Auto Select',
        pricingLevelName: 'Tier',
        advantageBenefits: {
            homeScreen: [
                'Lock in and protect every customer',
                'Simple, automated process',
                'Discounted pricing opportunities',
                'Manufacturer recommended strategy',
                'Closing tool for all sales',
            ],
            preferencesScreen: [
                'Lock in and protect every customer',
                'Simple, automated process',
                'Discounted pricing opportunities',
                'Manufacturer recommended strategy',
                'Closing tool for all sales',
            ]
        }
    },
    aig: {
        clientName: '{CompanyName}',
        clientSite: '{ClientSite}',
        programName: '{ProgramName}',
        advantageProgramName: '{Advantage}',
        programPhone: phoneFormat('XXXXXXXXXX'),
        programEmail: 'XXXXXXXXXX@aig.com',
        pricingLevelName: 'Plan',
        advantageBenefits: {
            homeScreen: [
                'Zero upfront administrative work',
                'Significantly discounted pricing on shorter terms plans',
                'Reimbursement rate options',
                'Auto generated contract for every registration',
                'Profit margin opportunity on all sales',
            ],
            preferencesScreen: [
                'Zero upfront administrative work',
                'Significantly discounted pricing on shorter terms plans',
                'Reimbursement rate options',
                'Auto generated contract for every registration',
                'Profit margin opportunity on all sales',
            ]
        }
    },
    rheem: {
        clientName: 'Rheem',
        clientSite: 'MyRheem/MyRuud',
        programName: 'Protection Plus',
        advantageProgramName: 'Advanced',
        programPhone: phoneFormat('8772764294'),
        programEmail: 'Warranty-HVACClaims@aig.com',
        porProgramName: 'Express',
        pricingLevelName: 'Plan',
        valuePlanName: 'Core',
        standardPlanName: 'Premium',
        standardPlanOne: 'Premium Plan 1',
        standardPlanTwo: 'Premium Plan 2',
        standardPlanThree: 'Premium Plan 3',
        standardPlanFour: 'Premium Plan 4',
        valuePlanTwo: 'Core Plan 2',
        valuePlanThree: 'Core Plan 3',
        valuePlanFour: 'Core Plan 4',
        advantageBenefits: {
            homeScreen: [
                'Zero upfront administrative work',
                'Significantly discounted pricing on shorter terms plans',
                'Reimbursement rate options',
                'Auto generated contract for every registration',
                'Profit margin opportunity on all sales',
            ],
            preferencesScreen: [
                'Zero upfront administrative work',
                'Significantly discounted pricing on shorter terms plans',
                'Reimbursement rate options',
                'Auto generated contract for every registration',
                'Profit margin opportunity on all sales',
            ]
        }
    },
    carrier: {
        clientName: 'Carrier',
        clientSite: 'www.climateshieldcomplete.com',
        programName: 'Climate Shield Complete',
        advantageProgramName: 'Ease',
        programPhone: phoneFormat('8665388922'),
        programEmail: 'Warranty-HVACClaims@aig.com',
        porProgramName: 'Select',
        pricingLevelName: 'Plan',
        valuePlanName: 'Prime',
        standardPlanName: 'Standard',
        standardPlanOne: 'Plan 1',
        standardPlanTwo: 'Plan 2',
        standardPlanThree: 'Plan 3',
        standardPlanFour: 'Plan 4',        
        standardPlanFive: 'Plan 5',
        standardPlanSix: 'Plan 6',
        valuePlanOne: 'Plan 1 Prime',
        valuePlanTwo: 'Plan 2 Prime',
        valuePlanThree: 'Plan 3 Prime',
        valuePlanFour: 'Plan 4 Prime',
        advantageBenefits: {
            homeScreen: [
                'Zero upfront administrative work',
                'Significantly discounted pricing on shorter terms plans',
                'Reimbursement rate options',
                'Auto generated contract for every registration',
                'Profit margin opportunity on all sales',
            ],
            preferencesScreen: [
                'Zero upfront administrative work',
                'Significantly discounted pricing on shorter terms plans',
                'Reimbursement rate options',
                'Auto generated contract for every registration',
                'Profit margin opportunity on all sales',
            ]
        }
    },
    trane: {
        clientName: 'Trane',
        clientSite: 'http://www.ASDealerNet.com or http://www.ComfortSite.com',
        programName: 'ComfortSure',
        advantageProgramName: '',
        specialityProgramName: 'Edge',
        advantageProgramName: 'Edge',
        programPhone: phoneFormat('8665449928'),
        programEmail: 'XXXXXXXXXXXXXXXXXX@aig.com',
        porProgramName: '',
        pricingLevelName: 'Plan',
        valuePlanName: 'Value',
        standardPlanName: 'Premium',
        standardPlanOne: 'Premium Plan 1',
        standardPlanTwo: 'Premium Plan 2',
        standardPlanThree: 'Premium Plan 3',
        standardPlanFour: 'Premium Plan 4',
        standardPlanFive: 'Premium Plan 5',
        standardPlanSix: 'Premium Plan 6',
        valuePlanTwo: 'Value Plan 2',
        valuePlanThree: '',
        valuePlanFour: 'Value Plan 4',
        advantageBenefits: {
            homeScreen: [
                'Zero upfront administrative work',
                'Significantly discounted pricing on shorter terms plans',
                'Reimbursement rate options',
                'Auto generated contract for every registration',
                'Profit margin opportunity on all sales',
            ],
            preferencesScreen: [
                'Zero upfront administrative work',
                'Significantly discounted pricing on shorter terms plans',
                'Reimbursement rate options',
                'Auto generated contract for every registration',
                'Profit margin opportunity on all sales',
            ]
        }
    },
    ge: {
        clientName: 'GE',
        clientSite: 'https://www.comfortprotectplans.com',
        programName: 'Comfort Protect',
        advantageProgramName: '',
        specialityProgramName: '',
        programPhone: phoneFormat('800-349-4020'),
        programEmail: 'ComfortProtectClaims@aig.com',
        porProgramName: '',
        pricingLevelName: 'Plan',
        valuePlanName: '',
        standardPlanName: '',
        standardPlanOne: 'Plan 1',
        standardPlanTwo: 'Plan 2',
        standardPlanThree: 'Plan 3',
        standardPlanFour: '',
        standardPlanFive: '',
        standardPlanSix: '',
        valuePlanTwo: '',
        valuePlanThree: '',
        valuePlanFour: '',
        advantageBenefits: {
            homeScreen: [],
            preferencesScreen: []
        }
    },
    icp: {
        clientName: 'ICP',
        clientSite: 'www.comfortpromise.com',
        programName: 'Comfort Promise',
        advantageProgramName: 'Connect',
        specialityProgramName: '',
        programPhone: phoneFormat('8665252454'),
        programEmail: 'Warranty-HVACClaims@aig.com',
        porProgramName: 'Select',
        pricingLevelName: 'Plan',
        valuePlanName: '',
        standardPlanName: '',
        standardPlanOne: 'Plan 1',
        standardPlanTwo: 'Plan 2',
        standardPlanThree: 'Plan 3',
        standardPlanFour: 'Plan 4',
        standardPlanFive: 'Plan 5',
        standardPlanSix: 'Plan 6',
        valuePlanTwo: '',
        valuePlanThree: '',
        valuePlanFour: '',
        advantageBenefits: {
            homeScreen: [
                'Zero upfront administrative work',
                'Significantly discounted pricing on shorter terms plans',
                'Reimbursement rate options',
                'Auto generated contract for every registration',
                'Profit margin opportunity on all sales',
            ],
            preferencesScreen: [
                'Zero upfront administrative work',
                'Significantly discounted pricing on shorter terms plans',
                'Reimbursement rate options',
                'Auto generated contract for every registration',
                'Profit margin opportunity on all sales',
            ]
        }
    },
    whatscovered: {
        icp: {
            layout: 'table',
            costFlag: ['yes', 'no', 'yes'],
            diagnosticFlag:['yes', 'no', 'no'],
            qualityServiceFlag:['yes', 'yes', 'no'],
            fullyTransferableFlag:['yes', 'no', 'yes'],
            noDeductibleFlag:['yes', 'yes', 'no'],                
            partFlag: false,
            refrigerantFlag: false,
            disclaimer: ' *Depending on type of plan purchased benefits may apply.',
        }
    }
}

export const getBrandContent = (account) => {
    const accountLower = account.toLowerCase();
    if (account && brandContent[accountLower]) {        
        return brandContent[accountLower];
    }

    return brandContent.aig;
}

