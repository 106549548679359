import React, { Component } from "react";
import {
  Container, Card, CardBody, Row, Col, Label,
  Input, FormGroup
} from "reactstrap";
import { DropDownAutoComplete } from '../../inputs/DropDownAutoComplete';
import { verifyAddress, VerificationResponseCodes, getLocationFromZip } from '../../../api/addressverification';
import SuggestedAddress from '../../../components/Modals/SuggestedAddress';
import InvalidAddress from '../../../components/Modals/InvalidAddress';
import styles from '../PlanDetails/PlanDetails.module.css'; //for pageHeader only
import { SessionContext } from "../../../context/SessionContext";
import { dataLayerPush } from '../../../components/Global/helpers'
import Cleave from 'cleave.js/react';
import SimpleReactValidator from 'simple-react-validator';

export class CustomerInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      useInstallAddress: true,
      emailCertificate: true,
      mailCertification: false,

      errorsCustomerInfo: {
        customerFirstName: false,
        customerLastName: false,
        customerMobile: false,
        customerAlternatePhone: false,
        customerEmail: false
      },

      errPhone: false,
      errCustomerEmail: false,
      errDealerEmail: false,

      errorsMailInfo: {
        customerMailingAddress: false,
        customerMailingCity: false,
        customerMailingState: false,
        customerMailingPostal: false,
      },

      customerMobile: "",
      customerAlternatePhone: "",
      duplicateMobile: false,
      duplicateAlternatePhone: false,

      arrayCustomerInfo: [],
      modelCustomerInfo: {
        customerFirstName: "",
        customerLastName: "",
        customerEmail: "",
        customerMobile: "",
        customerAlternatePhone: "",
      },

      arrayMailInfo: [],
      modelMailInfo: {
        customerMailingAddress: props.mailInfo === "" ? props.installationAddress : props.mailInfo[0].customerMailingAddress,
        customerMailingAddress2: props.mailInfo === "" ? props.installationAddress2 : props.mailInfo[0].customerMailingAddress2,
        customerCompany: "",
        customerMailingCity: props.mailInfo === "" ? props.installationCity : props.mailInfo[0].customerMailingCity,
        customerMailingState: props.mailInfo === "" ? props.installationState : props.mailInfo[0]?.customerMailingState,
        customerMailingStateId: props.mailInfo === "" ? props.installationStateId : props.mailInfo[0]?.customerMailingStateId,
        customerMailingPostal: props.mailInfo === "" ? props.installationPostal : props.mailInfo[0].customerMailingPostal,
      },

      modelPrintingOptions: {
        emailDealer: "",
        emailCustomer: "",
        downloadReceipt: false,
        mailReceipt: false,
        emailReceipt: false,
        mailReceiptCustomer: false,
        emailReceiptCustomer: false,
      },

      states: this.props.orderInfoStates,
      showSuggestedAddressValidationModal: false,
      showInvalidAddressModal: false,
      mobile: this.props.mobileInfo,
      home: this.props.altMobileInfo,
      vaidationFired: false,
      checkAlternateError: this.props.altMobileInfo.length && this.props.altMobileInfo.length < 10,
      checkPhoneError: this.props.mobileInfo.length && this.props.mobileInfo.length < 10,
    };
    this.handleStateDropDown = this.handleStateDropDown.bind(this);
    this.handleMailInputChange = this.handleMailInputChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.validator = new SimpleReactValidator({});
  }

  componentDidMount() {

    var opt = this.props.optC.toString();
    if (opt == "") {
      this.props.updateCustomerInfo("options_a", this.state.modelPrintingOptions, false, false, true)
    }

    dataLayerPush('Pageview', {
      pageTitle: "Customer Info",
      pagePath: "/sales/customerinfo"
    })
    const assetPackagesForGtm = [];
    (this.props.assetPackagesData || []).map((value, i) => assetPackagesForGtm.push({
      item_name: value?.name,
      quantity: this.props.assetPackagesData.length ?? 0,
      product_qty: value?.assets?.length,
      price: value?.planPrice,
      retail: value?.equipmentRetailPrice,
      reimbursement: '',
      type: '',
      term: value?.selectedYears,
      products: value.assets?.map(e => ({
        brand: e?.brand,
        prod_description: this.props.productTableData.find(
          x => x.assetId == e.assetId
        ).prodDescription
      }))
    }))
    //console.log({ x: this.state.assetPackagesData, assetPackagesForGtm })
    dataLayerPush(null, { ecommerce: null }) // clear last ecommerce data
    dataLayerPush('begin_checkout', {
      ecommerce: {
        items: [
          ...assetPackagesForGtm
        ]
      }
    })
    if (this.props.orderInfoStates.length === 0) {
      fetch('party/states')
        .then(res => res.json())
        .then(data => {
          this.setState({
            states: data.map(s => {
              return {
                data: s.stateId,
                display: s.name,
                abbreviation: s.abbreviation
              }
            })
          });
        })
    }

    if (this.props.customerInfo.length > 0) {
      this.validateCustomerInformation();
    }
  }


  handleStateDropDown = e => {
    if (this.props.orderInfoStates.length === 0) {
      this.props.updateOrderParty("installationState", "", this.state.states, "");
    }

    const state = this.state.states.find(({ data }) => data === e).display;
    this.setState({ stateId: e, state: state });

    console.log(this.state.state);

    this.setState(prevState => ({
      errorsMailInfo: {
        ...prevState.errorsMailInfo,
        customerMailingState: false
      }
    }))

    this.setState(prevState => ({
      modelMailInfo: {
        ...prevState.modelMailInfo,
        customerMailingState: state,
        customerMailingStateId: e
      }
    }));

    let temp;
    let existingInfo = [...this.props.mailInfo];
    if (existingInfo.length > 0) {
      temp = existingInfo;
    } else {
      temp = [...this.state.arrayMailInfo];
    }

    if (existingInfo.length === 0) {
      if (temp.length === 0) {
        temp.push(this.state.modelMailInfo);
      }
    }    

    let newData = temp.map((obj, index) => {
      if (index == 0)
        return Object.assign({}, obj, { customerMailingState: state }, { customerMailingStateId: e })
    });

    this.setState({ arrayMailInfo: newData })
    this.props.updateCustomerInfo("mail", newData);
  }

  onUseInstallAddress = (e) => {
    //const { useInstallAddress } = this.state;   

    const propChk = this.props.chkInstallationAddress;

    let useInstallAddress;
    if (propChk === "") {
      useInstallAddress = this.state.useInstallAddress;
    } else {
      useInstallAddress = propChk;
    }

    if(!useInstallAddress === false) {
      let newData = [];
      newData.push(this.state.modelMailInfo);
      this.props.updateCustomerInfo("mail", newData);
    }

    this.setState({ useInstallAddress: !useInstallAddress });
    this.props.updateCustomerInfo("check", !useInstallAddress);
  };

  handleMailInputChange = e => {
    const { id, value } = e.target;
    let err = this.state.errorsMailInfo;

    if (value.trim() === "") {
      err[id] = true;
    } else {
      err[id] = false;
    }
    this.setState({ errorsMailInfo: err });

    let t;
    let returning = [...this.props.mailInfo];
    if (returning.length > 0) {
      t = returning;
    } else {
      t = [...this.state.arrayMailInfo];
    }


    if (returning.length === 0) {
      if (t.length === 0) {
        t.push(this.state.modelMailInfo);
      }
    }

    let newData = t.map((obj, index) => {
      if (index == 0)
        return Object.assign({}, obj, { [id]: value })
    });

    this.setState({ arrayMailInfo: newData })
    this.props.updateCustomerInfo("mail", newData);

  }

  handleInputChange = e => {

    const { id, value } = e.target;
    let err = this.state.errorsCustomerInfo;

    if (value.trim() === "") {
      err[id] = true;

    } else {
      err[id] = false;
    }

    this.setState({ errorsCustomerInfo: err });

    let t;
    let returning = [...this.props.customerInfo];
    if (returning.length > 0) {
      t = returning;
    } else {
      t = [...this.state.arrayCustomerInfo];
    }


    if (returning.length === 0) {
      if (t.length === 0) {
        t.push(this.state.modelCustomerInfo);
      }
    }

    let newData = t.map((obj, index) => {
      if (index == 0)
        return Object.assign({}, obj, { [id]: value })
    });


    this.setState({ arrayMailInfo: newData })
    this.props.updateCustomerInfo("customer", newData);

  }

  handlePhoneChange = e => {
    const { id, value, name, rawValue } = e.target;
    this.validatePhoneInfo( id, value, name, rawValue);
  }

  validatePhoneInfo = ( id, value, name, rawValue) => {
   value && this.validator.showMessageFor(name);
    if (id === "customerMobile") {
      this.setState({ checkPhoneError: true });
      if ((value.length === 0 && this.state.home) ||
        (value.length !== 0 && !this.validator.message("mobile", rawValue, "phone"))) {
        this.setState({
          checkPhoneError: false
        });
      }

      this.setState({ customerMobile: value });
      this.props.updateCustomerInfo("mobile", value);
      if(value !== "" &&  value===this.state.home) {
        this.setState({ duplicateMobile: true });
      } else {
        this.setState({ duplicateMobile: false });
      }
    }

    if (id === "customerAlternatePhone") {
      this.setState({ checkAlternateError: true });
      if ((value.length === 0 && this.state.mobile) ||
        (value.length !== 0 && !this.validator.message("home", rawValue, "phone"))) {
        this.setState({
          checkAlternateError: false
        });
      }

      this.setState({ customerAlternatePhone: value });
      this.props.updateCustomerInfo("alt_mobile", value);
      if(value !== "" &&  value===this.state.mobile) {
        this.setState({ duplicateAlternatePhone: true });
      } else {
        this.setState({ duplicateAlternatePhone: false });
      }
    }
    this.setState({ [name]: value });
  }

  checkPhoneError() {

    const mob = document.getElementById('customerMobile').value; //this.state.modelCustomerInfo.customerMobile
    const alt = document.getElementById('customerAlternatePhone').value; //this.state.modelCustomerInfo.customerAlternatePhone

    if (mob.length === 0 && alt.length === 0) {
      this.setState(() => ({ errPhone: true }));
      return;
    }

    if (mob.length < 14 && alt.length < 14) {
      this.setState(() => ({ errPhone: true }));
      return;
    }

    this.setState(() => ({ errPhone: false }));

  }

  handleBlur = e => {
    const { id, value } = e.target;
    this.props.updateCustomerInfo("email_customer", value)
  }

  handlePrintInputChange = e => {
    const { id, value } = e.target;

    if (id === "downloadReceipt") {
      this.setState({ emailCertificate: false, errDealerEmail: false })
      this.props.updateCustomerInfo("options_a", this.state.modelPrintingOptions, true, false, false);
    } else if (id === "mailReceipt") {
      this.setState({ emailCertificate: false, errDealerEmail: false })
      this.props.updateCustomerInfo("options_a", this.state.modelPrintingOptions, false, true, false)
    } else if (id === "emailReceipt") {
      this.setState({ emailCertificate: !this.state.emailCertificate })
      this.props.updateCustomerInfo("options_a", this.state.modelPrintingOptions, false, false, true)
    } else if (id === "mailReceiptCustomer") {
      this.setState({ mailCertification: false, errCustomerEmail: false })
      this.props.updateCustomerInfo("options_b", this.state.modelPrintingOptions, true, false)
    } else if (id === "emailReceiptCustomer") {
      this.setState({ mailCertification: !this.state.mailCertification })
      this.props.updateCustomerInfo("options_b", this.state.modelPrintingOptions, false, true)
    } else if (id === "emailDealer") {
      this.props.updateCustomerInfo("email_dealer", value)
    } else if (id === "emailCustomer") {
      this.props.updateCustomerInfo("email_customer", value)
    }

    if (id === "emailCustomer") {
      if (this.props.optE == true) {
        if (value.trim() === "") {
          this.setState({ errCustomerEmail: true })
        } else {
          this.setState({ errCustomerEmail: false })
        }
      }
    }

    if (id === "emailDealer") {
      if (this.props.optC == true) {
        if (value.trim() === "") {
          this.setState({ errDealerEmail: true })
        } else {
          this.setState({ errDealerEmail: false })
        }
      }
    }
  }

  highlightError() {
    const custFirst = document.getElementById('customerFirstName').value;
    const custLast = document.getElementById('customerLastName').value;
    const custMobile = document.getElementById('customerMobile').value;
    const custAltMob = document.getElementById('customerAlternatePhone').value;
    const chkAdd = document.getElementById('usingInstalAddress').checked;

    let c1 = false;
    let c2 = false;
    let c3 = false;

    if (custFirst.length === 0) {
      c1 = true;
    }

    if (custLast.length === 0) {
      c2 = true;
    }

    if (custMobile.length < 14 && custAltMob.length < 14) {
      c3 = true;
    }

    const t = Object.assign({}, this.state.modelCustomerInfo, { customerFirstName: c1, customerLastName: c2 })
    this.setState({ errorsCustomerInfo: t, errPhone: c3 })


    if (chkAdd === false) {
      const mailAdd = document.getElementById('customerMailingAddress').value;
      const mailCity = document.getElementById('customerMailingCity').value;
      const mailPost = document.getElementById('customerMailingPostal').value;
      const mailState = this.props.stateInfo;

      let m1 = false;
      let m2 = false;
      let m3 = false;
      let m4 = false;

      if (mailAdd?.length === 0) {
        m1 = true;
      }

      if (mailCity?.length === 0) {
        m2 = true;
      }

      if (mailPost?.length === 0) {
        m3 = true;
      }

      if (mailState?.length === 0) {
        m4 = true;
      }

      const v = Object.assign({}, this.state.modelMailInfo, { customerMailingAddress: m1, customerMailingCity: m2, customerMailingPostal: m3, customerMailingState: m4 })
      this.setState({ errorsMailInfo: v })

    }

    if (this.props.optC == true) {
      const optCtext = document.getElementById('emailDealer').value;
      if (optCtext.length === 0) {
        this.setState({ errDealerEmail: true })
      }
    }

    if (this.props.optE === true) {
      const optEtext = document.getElementById('emailCustomer').value;
      if (optEtext.length === 0) {
        this.setState({ errCustomerEmail: true })
      }
    }
  }

  validateCustomerInformation() {
    //called as REF from OrderInfoContainer
    this.setState({ vaidationFired: true });
    this.highlightError();
    let isInValid = false;
    let err = this.state.errorsCustomerInfo;

    const custFirst = document.getElementById('customerFirstName').value;
    if (custFirst.length === 0) {
      err['customerFirstName'] = true;

      this.setState({ errorsCustomerInfo: err });
      isInValid = true;
    }

    const custLast = document.getElementById('customerLastName').value;
    if (custLast.length === 0) {
      err['customerLastName'] = true;

      this.setState({ errorsCustomerInfo: err });
      isInValid = true;
    }

    const custEmail = document.getElementById('customerEmail').value;
    if (custEmail.length > 0 && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(custEmail))) {

      err['customerEmail'] = true;

      this.setState({ errorsCustomerInfo: err });
      document.getElementById('customerEmail').focus()
      isInValid = true;
    }
    else {
      let err = this.state.errorsCustomerInfo;
      err['customerEmail'] = false;

      this.setState({ errorsCustomerInfo: err });
    }
    const phoneInfoList = [
      {
        id: "customerMobile",
        name: "mobile",
        value: this.state.mobile,
        rawValue: this.state.mobile.replace(/[^\d\+]/g, ""),
      },
      {
        id: "customerAlternatePhone",
        name: "home",
        value: this.state.home,
        rawValue: this.state.home.replace(/[^\d\+]/g, ""),
      },
    ];
    
    if (!(!this.state.mobile && !this.state.home) &&
      !this.state.checkAlternateError && !this.state.checkPhoneError) {
      this.setState({ checkPhoneError: false, checkAlternateError: false });
    }
    else {
      !this.state.mobile && this.setState({ checkPhoneError: true });
      !this.state.home && this.setState({ checkAlternateError: true });
      isInValid = true;
    }
    phoneInfoList.map(phoneObj => {
      this.validatePhoneInfo(phoneObj.id, phoneObj.value, phoneObj.name, phoneObj.rawValue);
    })

    if (this.props.optC == true) {
      const optCtext = document.getElementById('emailDealer').value;
      if (optCtext.length === 0) {
        document.getElementById('emailDealer').focus();
        isInValid = true;
      }
    }

    if (this.props.optE === true) {
      const optEtext = document.getElementById('emailCustomer').value;
      if (optEtext.length === 0) {
        document.getElementById('emailCustomer').focus();
        isInValid = true;
      }
    }

    const chkAdd = document.getElementById('usingInstalAddress').checked;
    if (chkAdd === false) {
      const mailAdd = document.getElementById('customerMailingAddress').value;
      if (mailAdd.length === 0) {
        document.getElementById('customerMailingAddress').focus()
        isInValid = true;
      }

      const mailCity = document.getElementById('customerMailingCity').value;
      if (mailCity.length === 0) {
        document.getElementById('customerMailingCity').focus()
        isInValid = true;
      }

      const mailPost = document.getElementById('customerMailingPostal').value;
      if (mailPost.length === 0) {
        document.getElementById('customerMailingPostal').focus()
        isInValid = true;
      }

      const mailState = this.props?.installationStateId;
      if (mailState === undefined || mailState.length === 0) {
        isInValid = true;
      }
    }

    return isInValid;

  };

  verifyAddress = () => {
    const chkAdd = document.getElementById('usingInstalAddress').checked;
    if (chkAdd === false) {
      let state = this.state.states.find(state => state.data === this.props.stateInfo);
      const mailAdd = document.getElementById('customerMailingAddress').value;
      const mailAdd2 = document.getElementById('customerMailingAddress2').value;
      const mailCity = document.getElementById('customerMailingCity').value;
      const mailPost = document.getElementById('customerMailingPostal').value;
      let addressToVerify = {
        address1: mailAdd,
        address2: mailAdd2,
        city: mailCity,
        state: (state != null && state.display != null) ? state.display : "",
        zip: mailPost,
      }

      this.setState({
        addressToVerify: addressToVerify
      });
      verifyAddress(addressToVerify, this.state.clientid, this.onAddressVerified);
    } else {
      this.props.handleContinueValidationClick(false);
    }

  }

  onAddressVerified = (result) => {
    if (result.code === VerificationResponseCodes.addressVerified) {
      this.props.handleContinueValidationClick(false);

      this.setState({
        showSuggestedAddressValidationModal: false,
      });
    } else if (result.code === VerificationResponseCodes.addressChanged) {
      this.props.handleContinueValidationClick(true);

      this.setState({
        showSuggestedAddressValidationModal: true,
        verifiedAddress: result.returnedAddress
      });
    } else {
      this.props.handleContinueValidationClick(true);
      this.setState({
        showInvalidAddressModal: true
      });
    }
  }

  onContinueSaveAction = (selectedAddress) => {
    if (selectedAddress === "suggestedAddress") {
      let newAddress = this.state.verifiedAddress;

      let suggestedState;
      if (newAddress.state) {
        suggestedState = this.state.states.find(state => state.display.toUpperCase() === newAddress.state.toUpperCase());
      } else {
        suggestedState = this.state.states.find(state => state.data === this.props.stateInfo);
      }

      let t;
      let returning = [...this.props.mailInfo];
      if (returning.length > 0) {
        t = returning;
      } else {
        t = [...this.state.arrayMailInfo];
      }


      if (returning.length === 0) {
        if (t.length === 0) {
          t.push(this.state.modelMailInfo);
        }
      }

      const newCustomerAddress = {
        customerMailingAddress: newAddress.address1,
        customerMailingAddress2: newAddress.address2,
        customerMailingCity: newAddress.city,
        customerMailingPostal: newAddress.zip,
      }

      let newData = t.map((obj, index) => {
        if (index == 0)
          return Object.assign({}, newCustomerAddress)
      });
      this.setState({ arrayMailInfo: newData })
      this.props.updateCustomerInfo("mail", newData);
      this.props.updateCustomerInfo("state", suggestedState.data);

      this.setState({
        installationAddress: (`${newAddress.address1} ${newAddress.suite}`).trim(),
        stateId: suggestedState.data,
        showSuggestedAddressValidationModal: false,
      }, () => {
        document.getElementById('customerMailingAddress').value = newAddress.address1;
        document.getElementById('customerMailingAddress2').value = newAddress.address2;
        document.getElementById('customerMailingCity').value = newAddress.city;
        document.getElementById('customerMailingPostal').value = newAddress.zip;
      });
    } else {
      this.setState({
        showSuggestedAddressValidationModal: false,
        showInvalidAddressModal: false
      });

    }
    this.props.handleContinueValidationClick(false);

  }


  onCloseAddressModal = () => {
    this.setState({
      showSuggestedAddressValidationModal: false,
      showInvalidAddressModal: false
    });
  }



  render() {

    const propCustoInfo = this.props.customerInfo;
    const propMailInfo = this.props.mailInfo;
    const propMobInfo = this.props.mobileInfo;
    const propAltMobileInfo = this.props.altMobileInfo;
    const propChk = this.props.chkInstallationAddress;

    let chk = "";
    if (propChk === "") {
      chk = this.state.useInstallAddress;
    } else {
      chk = propChk;
    }

    return (

      <div>
        <Container className="contentWrapper">
          <h1 className={`h3 ${styles.pageHeader}`}>Customer Information</h1>
          <Card>
            <CardBody>
              <Col md="7" lg="4" className="p-0">
                <Row className="multiColWrapper">
                  <Col sm="6">
                    <FormGroup className={this.state.errorsCustomerInfo.customerFirstName ? "form-error" : ""} >
                      <Label className="form-label" for="customerFirstName">First Name</Label>
                      <span aria-hidden="true" className="required-indicator">*</span>
                      <Input className="form-control" type="text" id="customerFirstName" aria-labelledby="First Name" onChange={this.handleInputChange} defaultValue={propCustoInfo == "" ? "" : propCustoInfo[0].customerFirstName} />
                      {this.state.errorsCustomerInfo.customerFirstName === true && <div className="errorMsg">First name is required.</div>}
                    </FormGroup>
                  </Col>

                  <Col sm="6">
                    <FormGroup className={this.state.errorsCustomerInfo.customerLastName ? "form-error" : ""} >
                      <Label className="form-label" for="customerLastName">Last Name</Label>
                      <span aria-hidden="true" className="required-indicator">*</span>
                      <Input className="form-control" type="text" id="customerLastName" aria-labelledby="First Name" onChange={this.handleInputChange} defaultValue={propCustoInfo == "" ? "" : propCustoInfo[0].customerLastName} />
                      {this.state.errorsCustomerInfo.customerLastName === true && <div className="errorMsg">Last name is required.</div>}
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="multiColWrapper">
                  <Col sm="6">
                    <FormGroup>
                      <Label className="form-label" for="customerCompany">Company</Label>
                      <Input className="form-control" type="text" id="customerCompany" aria-labelledby="Company" onChange={this.handleInputChange} defaultValue={propCustoInfo == "" ? "" : propCustoInfo[0].customerCompany} />
                    </FormGroup>
                  </Col>
                  <Col sm="6">

                    <FormGroup className={this.state.errorsCustomerInfo.customerEmail && "form-error"}>
                      <Label className="form-label" for="customerEmail">Email</Label>
                      <Input className="form-control" type="text" id="customerEmail" aria-labelledby="Email" onChange={this.handleInputChange} value={propCustoInfo == "" ? "" : propCustoInfo[0].customerEmail} onBlur={this.handleBlur} />
                      {this.state.errorsCustomerInfo.customerEmail && <div className="errorMsg">Please enter a valid email address.</div>}
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="twoColWrapper">
                  <Col sm="6">
                    <FormGroup className={`mb-0 ${this.state.vaidationFired && this.state.checkPhoneError &&
                      ((!this.state.mobile && !this.state.home) || this.state.mobile) ? "form-error" : (this.state.duplicateMobile && "form-error")}`
                    }>
                      <Label className="form-label" for="customerMobile">Mobile Phone</Label>
                      <Cleave
                        name="mobile"
                        onChange={this.handlePhoneChange}
                        value={this.state.mobile}
                        className="form-control"
                        id="customerMobile"
                        options={{
                          blocks: [0, 3, 3, 4],
                          delimiters: ['(', ') ', '-'],
                          numericOnly: true
                        }}
                      />
                      {this.state.vaidationFired && this.state.checkPhoneError && !this.state.mobile && !this.state.home && (
                        <div className="errorMsg">
                          Please enter at least one phone number.
                        </div>
                      )
                      }
                      {((this.state.vaidationFired && this.state.checkPhoneError && this.state.mobile) 
                        || this.state.duplicateMobile) && (
                          <div className="errorMsg">
                            Please enter a valid phone number.
                          </div>
                      )
                      }
                    </FormGroup>
                  </Col>

                  <Col sm="6">
                    <FormGroup className={`mb-0 ${this.state.vaidationFired && this.state.checkAlternateError &&
                      ((!this.state.mobile && !this.state.home) || this.state.home) ? "form-error" : (this.state.duplicateAlternatePhone && "form-error")}`
                    }>
                      <Label className="form-label" for="customerAlternatePhone">Alternate Phone</Label>
                      <Cleave
                        name="home"
                        onChange={this.handlePhoneChange}
                        value={this.state.home}
                        className="form-control"
                        id="customerAlternatePhone"
                        options={{
                          blocks: [0, 3, 3, 4],
                          delimiters: ['(', ') ', '-'],
                          numericOnly: true
                        }}
                      />
                      {this.state.vaidationFired && this.state.checkAlternateError && !this.state.mobile && !this.state.home && (
                        <div className="errorMsg" >
                          Please enter at least one phone number.
                        </div>
                      )
                      }
                      {((this.state.vaidationFired && this.state.checkAlternateError && this.state.home) 
                        || this.state.duplicateAlternatePhone) && (
                          <div className="errorMsg">
                            Please enter a valid phone number.
                          </div>
                      )
                      }
                    </FormGroup>
                  </Col>
                </Row>
              </Col>

              <hr className="cardSectionHR" />

              <h2 className=" h5 subHead" for="custMailAddress">
                Mailing Address<span aria-hidden="true" className="required-indicator">*</span>
              </h2>
              <div className="checkbox mb-2">
                <Input className="mr-2" type="checkbox" id="usingInstalAddress" checked={chk} onChange={this.onUseInstallAddress} />
                {/* <Input className="mr-2" type="checkbox" id="usingInstalAddress" checked={this.state.useInstallAddress} onChange={this.onUseInstallAddress} /> */}
                <Label for="usingInstalAddress" className={`checkboxLabel ${this.context.accountName}`}>Use installation address</Label>
              </div>
              {/* {this.state.useInstallAddress ? ( */}
              {chk ? (
                <p className="mb-0">
                  {this.props.installationAddress} {/*9821 Vieux Carre Dr */}
                  {this.props.installationAddress2 && (<><br /> {this.props.installationAddress2}</>)} {/*Apt 12*/}
                  <br /> {this.props.installationCity},  {this.props.installtionStateAbb} {this.props.installationPostal}  {/*Louisville, KY 40223 */}
                </p>
              ) : (
                  <Col md="7" lg="4" className="p-0">
                    <FormGroup className={this.state.errorsMailInfo.customerMailingAddress ? "form-error" : ""}>
                      <Label className="form-label" for="customerMailingAddress">Street Address</Label>
                      <span aria-hidden="true" className="required-indicator">*</span>
                      <Input
                        className="form-control"
                        type="text"
                        id="customerMailingAddress"
                        aria-labelledby="Mailing Address"
                        onChange={this.handleMailInputChange}
                        defaultValue={propMailInfo === "" ? this.props.installationAddress : propMailInfo[0]?.customerMailingAddress}
                      />
                      {this.state.errorsMailInfo.customerMailingAddress === true && <div className="errorMsg">Mailing address is required.</div>}
                    </FormGroup>

                    <FormGroup>
                      <Label className="form-label" for="customerMailingAddress2">Apt., Suite, Building</Label>
                      <Input
                        className="form-control"
                        type="text"
                        id="customerMailingAddress2"
                        aria-labelledby="Apt Number"
                        onChange={this.handleMailInputChange}
                        defaultValue={propMailInfo === "" ? this.props.installationAddress2 : propMailInfo[0]?.customerMailingAddress2}
                      />
                    </FormGroup>

                    <FormGroup className={this.state.errorsMailInfo.customerMailingCity ? "form-error" : ""}>
                      <Label for="customerMailingCity" className="form-label">City</Label>

                      <span aria-hidden="true" className="required-indicator">*</span>
                      <Input
                        className="form-control"
                        type="text"
                        id="customerMailingCity"
                        aria-labelledby="City"
                        onChange={this.handleMailInputChange}
                        defaultValue={propMailInfo === "" ? this.props.installationCity : propMailInfo[0]?.customerMailingCity}
                      />
                      {this.state.errorsMailInfo.customerMailingCity === true && <div className="errorMsg">City is required.</div>}
                    </FormGroup>

                    <Row className="twoColWrapper">

                      <Col sm="6">
                        <FormGroup className={(this.state.errorsMailInfo.customerMailingState && this.props.installationStateId == undefined) ? "form-error" : ""}>
                          <Label for="customerMailingState" className="form-label">State</Label>
                          <span aria-hidden="true" className="required-indicator">*</span>
                          <DropDownAutoComplete
                            name={'statename'}
                            options={this.state.states} //hello
                            placeholder="Please select..."
                            onChangedValue={this.handleStateDropDown}
                            onBlur={this.handleStateDropDown}
                            defaultValue={this.props.installationStateId}
                          />
                          {(this.state.errorsMailInfo.customerMailingState && this.props.installationStateId === undefined) && <div className="errorMsg">State is required.</div>}
                        </FormGroup>
                      </Col>


                      <Col sm="6">
                        <FormGroup className={this.state.errorsMailInfo.customerMailingPostal ? "form-error" : ""}>
                          <Label className="form-label" for="customerMailingPostal">Zip/Postal</Label>
                          <span aria-hidden="true" className="required-indicator">*</span>
                          <Input
                            className="form-control"
                            type="text"
                            id="customerMailingPostal"
                            aria-labelledby="Zip/Postal"
                            onChange={this.handleMailInputChange}
                            defaultValue={propMailInfo === "" ? this.props.installationPostal : propMailInfo[0]?.customerMailingPostal}
                          />
                          {this.state.errorsMailInfo.customerMailingPostal === true && <div class="errorMsg">Zip/Postal is required.</div>}
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                )}
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <h2 className="h5 subHead" for="printingOptions">
                Printing Options
              </h2>


              <Row id="printingOptions" className="multiColWrapper">
                <Col xs="12" md="6">
                  <Label name="dealerReceiptOpt" className="form-label mb-2">Dealer Receipt Options</Label>
                  <span aria-hidden="true" className="required-indicator">*</span>
                  <div className="radio">
                    <Input
                      type="radio"
                      name="dealerReceiptOpt"
                      id="downloadReceipt"
                      defaultChecked={this.props.optA}
                      /* onChange={() => this.setState({  emailCertificate: false,  }) } */
                      onChange={this.handlePrintInputChange}
                    />
                    <Label className="controls-label" for="downloadReceipt">Download and Print Certificate of Coverage</Label>
                  </div>

                  <div className="radio">
                    <Input
                      type="radio"
                      name="dealerReceiptOpt"
                      defaultChecked={this.props.optB}
                      id="mailReceipt"
                      /* onChange={() => this.setState({ emailCertificate: false, }) } */
                      onChange={this.handlePrintInputChange}
                    />
                    <Label className="controls-label" for="mailReceipt">Mail Certificate of Coverage</Label>
                  </div>
                  <FormGroup className={this.state.errDealerEmail ? "form-error" : ""}>
                    <div className="radio">
                      <Input
                        type="radio"
                        name="dealerReceiptOpt"
                        id="emailReceipt"
                        defaultChecked={this.props.optC}
                        /* onChange={() => this.setState({ emailCertificate: !this.state.emailCertificate, }) } */
                        onChange={this.handlePrintInputChange}
                      />
                      <Label className="controls-label" for="emailReceipt">Email Certification of Coverage to:</Label>
                    </div>

                    <Input
                      disabled={!this.props.optC}//{!this.state.emailCertificate}
                      type="text"
                      className="form-control ml-4 col-8 mb-3 mb-md-0"
                      id="emailDealer"
                      value={this.props.emailDealer}
                      onChange={this.handlePrintInputChange}
                    />
                    {this.state.errDealerEmail === true && <div className="errorMsg"><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Email certification of coverage to is required.</div>}
                  </FormGroup>
                </Col>

                <Col xs="12" md="6">
                  <Label name="customerReceiptOpt" className="form-label mb-2">Customer Receipt Options</Label>
                  <span aria-hidden="true" className="required-indicator">*</span>

                  <div className="radio">
                    <Input
                      type="radio"
                      defaultChecked={this.props.optD} //{true}
                      name="customerReceiptOpt"
                      id="mailReceiptCustomer" /* onChange={() => this.setState({  mailCertification: false, }) } */
                      onChange={this.handlePrintInputChange}
                    />
                    <Label className="controls-label" for="mailReceiptCustomer">Mail Certificate of Coverage</Label>
                  </div>

                  <FormGroup className={this.state.errCustomerEmail ? "form-error" : ""}>
                    <div className="radio">
                      <Input
                        defaultChecked={this.props.optE} // === "" ? true : this.props.optE}
                        type="radio"
                        name="customerReceiptOpt"
                        id="emailReceiptCustomer"
                        onChange={this.handlePrintInputChange} /* onChange={() => this.setState({  mailCertification: !this.state.mailCertification, }) } */
                      />
                      <Label className="controls-label" for="emailReceiptCustomer">Mail and Email Certification of Coverage to:</Label>
                    </div>
                    <Input
                      disabled={!this.props.optE} //{!this.state.mailCertification}
                      type="text"
                      className="form-control ml-4 col-8"
                      id="emailCustomer"
                      value={this.props.emailCustomer}
                      onChange={this.handlePrintInputChange}
                    />
                    {this.state.errCustomerEmail === true && <div className="errorMsg"><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Mail and email certification of coverage to is required.</div>}
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            {this.state.showSuggestedAddressValidationModal &&
              <SuggestedAddress
                showSuggestedAddressValidationModal={this.state.showSuggestedAddressValidationModal}
                enteredAddress={this.state.addressToVerify}
                suggestedAddress={this.state.verifiedAddress}
                onContinueAction={this.onContinueSaveAction}
                onEditAddress={this.onCloseAddressModal} />}
            {this.state.showInvalidAddressModal &&
              <InvalidAddress
                showInvalidAddressModal={this.state.showInvalidAddressModal}
                onEditAddress={this.onCloseAddressModal}
                onContinueAction={this.onContinueSaveAction} />}
          </Card>
        </Container>
      </div >
    );
  }
}

CustomerInformation.contextType = SessionContext