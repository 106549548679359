import React, { Component } from 'react';
import {
    Container
} from 'reactstrap';
import NeedHelp from './Modals/NeedHelp/NeedHelp';


function FooterContainer() { 
        return (
            <footer role="contentinfo" aria-label="footer">
                <Container>
                    <ul className="footerNav">
                        <li><a href="https://www.aig.com/privacy-policy" target="_blank" aria-label="Privacy Policy opens a new details window">Privacy Policy</a></li>
                        <li><a href="https://www.aig.com/terms-of-use" target="_blank" aria-label="Site Terms of Use opens a new details window">Terms Of Use</a></li>
                    </ul>
                    <p style={{color: 'var(--Color__Neutral1)'}}>Copyright &copy; {(new Date().getFullYear())}, American International Group, Inc. All rights reserved.</p>
                </Container>
                <NeedHelp />
            </footer>
        );  
}

export default FooterContainer;