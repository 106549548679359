import React from "react";
import { Container, Card, CardBody, Col } from "reactstrap";
import styles from "./InvoiceConfirmation.module.css";
import BrandedIcon from '../../components/BrandingWhiteLabel/BrandedIcon';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';


export const InvoiceConfirmation = (props) => {
    const history = useHistory();
    const accountName = typeof props.location.state.clientId == "undefined" || props.location.state.clientId == null ? null : props.location.state.clientId;
    const eventId = typeof props.location.state.eventId == "undefined" || props.location.state.eventId == null ? null : props.location.state.eventId;
    const invoiceNumber = typeof props.location.state.invoiceNumber == "undefined" || props.location.state.invoiceNumber == null ? '' : props.location.state.invoiceNumber;
    const eventNumber = typeof props.location.state.eventNumber == "undefined" || props.location.state.eventNumber == null ? '' : props.location.state.eventNumber;
    const handlRedirect = () => {
        history.push('/requestforservice/' + eventId + '/' + accountName);
    }
    return (
        <Container className={"contentWrapper"}>
            <Card>
                <CardBody>
                    <div className={styles.thanksConfirmation}>
                        <BrandedIcon accountName={accountName} iconType={'successful'} />
                    </div>
                    <Col md="10" lg="8" className={styles.invMessage}>
                        <h1 className="h3">Invoice #{invoiceNumber} Submitted</h1>
                        <p>
                            Thank you for submitting an invoice. One of our representatives will review the information provided.
                            If approved, you will receive payment via the payment method on file within 30 days of submission.
                        </p>
                        <p>
                            If you'd like to speak with one of our representatives, call &nbsp;
                            <a className="callLink" href={`tel:8002402269`}>(800) 240-2269</a> or email &nbsp;
                            <br className="show-lg-up" />
                            <a className="btnLink mr-1" href={`mailto:Warranty-InvoiceProcessing@aig.com`}>
                                Warranty-InvoiceProcessing@aig.com.
                            </a>
                            Please reference your event number: {eventNumber}.
                        </p>
                        <div>
                            <a onClick={handlRedirect}
                                className={classNames(styles.redirectLink, "btnLink")}> {/*onClick should be handled by dev Team*/}
                                Return to Service Request Details
                            </a>
                        </div>
                    </Col>
                </CardBody>
            </Card>
        </Container>
    );
}
