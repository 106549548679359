/* eslint-disable react-hooks/rules-of-hooks */
import React, { Component } from "react";
import { Card, CardBody, Container } from "reactstrap";
import styles from "./ErrorConfirmation.module.css";
import { SessionContext } from "../../context/SessionContext";
import BrandedIcon from '../../components/BrandingWhiteLabel/BrandedIcon';
import { getBrandContent } from "../../components/BrandingWhiteLabel/BrandContent";
import { dataLayerPush } from '../../components/Global/helpers'


export default class ErrorConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isTokenError: this.props.isTokenError === true,
        clientName: this.props.clientName,
        clientSupportNumber: this.props.clientSupportNumber,
        internalSupportNumber: this.props.internalSupportNumber
    }
  }

  componentDidMount () {
    const { useTranslations } = this.context;
    const { languageCode } = this.props;
    dataLayerPush('Pageview', {
        pageTitle: "Handshake Error",
        pagePath: "/error/handshake"
    })
    var qTokenError = new URLSearchParams(window.location.search).get('tokenError');
    if (qTokenError === 'true')
      this.setState({isTokenError: true});
    if(languageCode !== undefined) {
      languageCode.toLowerCase() !== "en" && useTranslations("ErrorMessages", languageCode);
    }
    
  }

  render() {
    const { isTokenError } = this.state;
    const { accountName, translations, isTranslationsResolved } = this.context;
    const currentBrand = getBrandContent(accountName);
    const { languageCode } = this.props;
    return(
      <Container className="contentWrapper">
        <Card>
          <CardBody>
            {(languageCode === undefined || languageCode === "en") && 
              <div className={styles.errorMessage}>
                <BrandedIcon accountName={accountName} iconType={'error'} />
                {isTokenError ? (<p>Our system is unable to process your request at this time.</p>) :
                  (<p>Oops, something went wrong on our side.</p>)
                }
                {isTokenError ? (<p>Use the browser back button to return to {accountName}'s website and try again.<br className="show-lg-up" /> If issues persist call our Support Team at <a className="callLink">{currentBrand.programPhone}</a>.</p>) :
                  (<p>Our system is unable to process your request at this time. <br className="show-md-up" />Please try again later or call <a className="callLink">{currentBrand.programPhone}</a>.</p>)}
              </div>
            }
            {languageCode !== undefined && languageCode !== "en" && 
              <div className={styles.errorMessage}>
                <BrandedIcon accountName={accountName} iconType={'error'} />
                {isTokenError ? (<p>{translations?.tokenError?.title}</p>) : 
                  (<p>{translations?.title}</p>)
                }
                {isTokenError ? (<p>{translations?.tokenError?.p1Prefix} {accountName}{translations?.tokenError?.p1Suffix}<br className="show-lg-up" /> {translations?.tokenError?.pleaseCall} <a className="callLink">{currentBrand.programPhone}</a>.</p>) :
                  (<p>{translations?.p1}<br className="show-md-up" />{translations?.pleaseCall} <a className="callLink">{currentBrand.programPhone}</a>.</p>)}
              </div>
            }
          </CardBody>
        </Card>
      </Container>
    );
  }
}

ErrorConfirmation.contextType = SessionContext;