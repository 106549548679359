import React, { useEffect, useState, useContext } from "react";
import { SessionContext } from "../../../context/SessionContext";
import { CurrencyField, PriceInput } from "../Formatters";
import { COLUMNS, contractTypes } from "../lib/config";
import { useClaimInvoiceStore } from "./state";
import { getFooterTaxName } from '../../../lib/util.jsx'
import { widthFormatter } from "../Formatters/widthFormatter";

export const useConfigureTable = (value, contract, profile, reImbursementType, selectedAsset, invoiceId, coverageType) => {
    const [prepared, setPrepared] = useState(null);
    const [assetAttributes, setAssetAttributes] = useState([]);
    const [repairActionAttributes, setRepairActionAttributes] = useState([]);
    const [tax, setTax] = useState(false);
    const [repairCode, setRepairCode] = useState('');
    const { getCurrentTabAndSubtab } = useContext(SessionContext)
    const invoiceStoreState = useClaimInvoiceStore((state) => state);

    const getAssetAttributes = (assetId) => {
        fetch(`asset/GetAttributesByAssetIdSummary/${assetId}`).then(res => {
            if (res.ok) {
                res.json().then(data => {
                    setAssetAttributes(data)
                    return data;
                });
            }
        });
    }

    const loadRepairCodes = async () => {
        fetch(`asset/GetAllRepairCode`).then(res => {
            if (res.ok) {
                res.json().then(data => {
                    setRepairCode(data)
                    return data;
                });
            }
        });
    }

    const getTax = async (id, clientId) => {
        try {
            const res = await fetch(`tax/gettaxdetailsbystateid/${id}/${clientId}`);
            const resJson = await res.json();
            setTax(resJson);
        } catch (e) {
            console.log({ 'error fetching tax data': e })
        }
    }

    useEffect(() => {
        const assetId = contract.assets?.[0]?.assetId
        const id = contract.party.partyAddressModel?.[0]?.stateId
        const clientId = "hvac"
        getAssetAttributes(assetId)
        loadRepairCodes()
        getTax(id, clientId)
    }, [])


    const getLaborInitialData = () => {
        const initialRepairOptions = [{ repairAction: 'Unit Replaced', repairActionId: 'unitreplaced' }];
        const invAssets = invoiceStoreState.getSelectedAsset();
        const allAssets = selectedAsset?.length > 0 ? selectedAsset : invAssets;

        // pricingAttributId needs to be 9 based on story 
        const rateData = contract.riskProfile.riskProfileConfigurationModel?.filter(e => e.pricingAttributeId === 8)?.[0];
        const hrsData = contract.repairMatrixInfo
        if (allAssets?.length > 0) {
            return allAssets?.map(e => {
                const modifiedAssets = contract.assets.map(e => {
                    const currentContractAsset = contract.contractAssetModel?.filter(model => model.assetId === e.assetId)?.[0];
                    return {
                        assetId: e.assetId,
                        assetName: `${e.assetName} - SN#${currentContractAsset?.serialNumber}`
                    }
                })


                const selectedAssetInfo = modifiedAssets?.filter(ca => ca.assetId === e)?.[0];
                const componentData = contract?.repairMatrixInfo?.filter(
                    repairInfo => repairInfo.assetId === selectedAssetInfo?.assetId
                );
                return {
                    id: '',
                    invoiceId: 1,
                    asset: { options: modifiedAssets, selected: [selectedAssetInfo] ?? [] },
                    component: { options: componentData, selected: [] },
                    repair: { options: repairActionAttributes.length > 0 ? repairActionAttributes : initialRepairOptions, selected: [] },
                    hours: '0.5',
                    rate: parseInt(rateData?.value ?? 0),
                    total: parseInt(rateData?.value ?? 1) * 0.5
                }
            })
        }
        const componentData = contract?.repairMatrixInfo?.filter(
            repairInfo => repairInfo.assetId === contract.assets?.[0]?.assetId
        );
        return [{
            id: '',
            invoiceId: 1,
            asset: { options: contract.assets, selected: [contract.assets?.[0]] ?? [] },
            component: { options: componentData, selected: [] },
            repair: { options: repairActionAttributes.length > 0 ? repairActionAttributes : initialRepairOptions, selected: [] },
            hours: '0.5',
            rate: parseInt(rateData?.value ?? 0),
            total: parseInt(rateData?.value ?? 1) * 0.5
        }]
    };

    const getLaborSelectRateInitialData = () => {
        const initialRepairOptions = [{ repairAction: 'Unit Replaced', repairActionId: 'unitreplaced' }];
        const invAssets = invoiceStoreState.getSelectedAsset();
        const allAssets = selectedAsset || invAssets;
        // pricingAttributId needs to be 9 based on story 
        const rateData = contract.riskProfile.riskProfileConfigurationModel?.filter(e => e.pricingAttributeId === 8)?.[0];
        if (allAssets?.length > 0) {
            return allAssets?.map((e, i) => {
                const modifiedAssets = contract.assets.map(e => {
                    const currentContractAsset = contract.contractAssetModel?.filter(model => model.assetId === e.assetId)?.[0];
                    return {
                        assetId: e.assetId,
                        assetName: `${e.assetName} - SN#${currentContractAsset?.serialNumber}`
                    }
                })
                const selectedAssetInfo = modifiedAssets?.filter(ca => ca.assetId === e)?.[0];
                return {
                    id: '',
                    invoiceId: 1,
                    asset: { options: modifiedAssets, selected: [selectedAssetInfo] },
                    component: { options: contract?.repairMatrixInfo, selected: [] },
                    repair: { options: repairActionAttributes.length > 0 ? repairActionAttributes : initialRepairOptions, selected: [] },
                    repairCode: '',
                    rate: parseInt(rateData?.value ?? 0),
                    total: 0
                }
            })
        }

        return contract?.assets?.map((e, i) => {
            return {
                id: '',
                invoiceId: 1,
                asset: { options: contract.assets, selected: [contract.assets?.[i]] ?? [] },
                component: { options: contract?.repairMatrixInfo, selected: [] },
                repair: { options: repairActionAttributes.length > 0 ? repairActionAttributes : initialRepairOptions, selected: [] },
                repairCode: '',
                rate: parseInt(rateData?.value ?? 0),
                total: 0
            }
        })

        return [{
            id: '',
            invoiceId: 1,
            asset: { options: contract.assets, selected: [contract.assets?.[0]] ?? [] },
            component: { options: assetAttributes, selected: [] },
            repair: { options: repairActionAttributes.length > 0 ? repairActionAttributes : initialRepairOptions, selected: [] },
            repairCode: (repairCode ?? ''),
            rate: parseInt(rateData?.value ?? 0),
            total: 0
        }]
    };

    const getPartInitialData = () => {
        const invAssets = invoiceStoreState.getSelectedAsset();
        const allAssets = selectedAsset || invAssets;

        if (allAssets?.length > 0) {
            return allAssets?.map((e, i) => {
                const selectedAssetInfo = contract.assets?.filter(ca => ca.assetId === e)?.[0];
                return {
                    id: '',
                    qty: 1,
                    oem: false,
                    asset: { options: contract.assets, selected: [selectedAssetInfo] ?? []  },
                    partNumber: '',
                    description: { options: contract.assets?.[0]?.parts, selected: [] },
                    price: 0,
                    total: 0
                }
            })
        }

        return [{
            id: '',
            qty: 1,
            oem: false,
            asset: { options: contract.assets, selected: [contract.assets?.[0]] ?? [] },
            partNumber: '',
            description: { options: contract.assets?.[0]?.parts, selected: [] },
            price: 0,
            total: 0
        }]
    }

    const getPartMarkupInitialData = () => {
        const invAssets = invoiceStoreState.getSelectedAsset();
        const allAssets = selectedAsset || invAssets;

        if (allAssets?.length > 0) {
            return allAssets?.map((e, i) => {
                const selectedAssetInfo = contract.assets?.filter(ca => ca.assetId === e)?.[0];
                return {
                    id: '',
                    qty: 1,
                    oem: false,
                    asset: { options: contract.assets, selected: [selectedAssetInfo] ?? [] },
                    partNumber: '',
                    description: { options: contract.assets?.[0]?.parts, selected: [] },
                    markup: 0,
                    price: 0,
                    total: 0
                }
            })
        }

        return [{
            id: '',
            qty: 1,
            oem: false,
            asset: { options: contract.assets, selected: [contract.assets?.[0]] ?? [] },
            partNumber: '',
            description: { options: contract.assets?.[0]?.parts, selected: [] },
            markup: 0,
            price: 0,
            total: 0
        }]
    }

    const getTripInitialData = () => {
        // pricingAttributId needs to be 9 based on story 
        const tripData = contract.riskProfile.riskProfileConfigurationModel?.filter(e => e.pricingAttributeId === 9)?.[0];

        return [{
            id: '',
            qty: 1,
            trip: 'Round Trip',
            reason: 'Initial Trip',
            tripRate: parseInt(tripData?.value ?? 0),
            total: parseInt(tripData?.value ?? 0)

        }]
    }

    // pricingAttributId needs to be 16 based on story 
    const partsAllowanceData = contract?.riskProfile?.riskProfileConfigurationModel?.filter(e => e.pricingAttributeId === 16)?.[0];
    const getPartsAllowanceInitialData = () => {
        const data = [{
            name: 'Parts Allowance',
            total: parseFloat(partsAllowanceData?.value ?? 0),
        }];
        return data;
    }

    const getTaxInitialData = () => {
        const columns = getTaxColumns();
        const labor = getLaborInitialData();
        const trip = getTripInitialData();
        const parts = getPartInitialData();
        const partsMarkup = getPartMarkupInitialData();
        const partsAllowance = getPartsAllowanceInitialData();
        const fields = columns?.filter(e => !e.hidden && e.dataField).map(e => e.dataField);
        const values = contractTypes[value].filter(e => e !== 'tax');

        const laborTotal = labor.reduce((acc, e) => acc = acc + e.total, 0) * 0.15;
        const tripTotal = trip.reduce((acc, e) => acc = acc + e.total, 0) * 0.15;
        const partsTotal = parts.reduce((acc, e) => acc = acc + e.total, 0) * 0.15;
        const partsMarkupTotal = partsMarkup.reduce((acc, e) => acc = acc + e.total, 0) * 0.15;
        const partsAllowanceTotal = partsAllowance.reduce((acc, e) => acc = acc + e.total, 0) * 0.15;
        const data = values?.map((e, i) => {
            return fields?.reduce((acc, fl) => {
                let total = 0;
                if (e === "parts") {
                    total = partsTotal;
                } else if (e === "partsMarkup") {
                    total = partsMarkupTotal;
                } else if (e === "partsAllowance") {
                    total = partsAllowanceTotal;
                } else if (e === "trip") {
                    total = tripTotal;
                } else if (e === "labor") {
                    total = laborTotal;
                }
                acc = { ...acc, ...{ id: '', invoiceId: `${e}_${i}`, name: (reImbursementType === '2' && e === 'labor') ? 'laborSelectRate' : e, [fl]: 0 } }
                return acc;
            }, {})
        });
        return data;
    }

    const getTableInitData = (table) => {
        switch (table) {
            case 'labor':
                return getLaborInitialData();
            case 'laborSelectRate':
                return getLaborSelectRateInitialData();
            case 'parts':
                return getPartInitialData();
            case 'partsMarkup':
                return getPartMarkupInitialData();
            case 'partsAllowance':
                return getPartsAllowanceInitialData();
            case 'trip':
                return getTripInitialData();
            case 'tax':
                return getTaxInitialData();
            default:
                return [];
        }
    }

    const getTaxColumns = () => {
        const taxColumns = (tax || [])?.map(e => e.taxTypeModel?.taxTypeName);
        const values = contractTypes[value].filter(e => e !== 'tax');

        let columns = [            
            {
                dataField: 'name',
                align: 'left',
                text: '',
                formatter: (col, row, i) => {
                    return <span style={{ textTransform: 'capitalize' }}>{`${getFooterTaxName(col)} Tax`}</span>;
                    
                },
            }, 
            {
                dataField: "total",
                text: 'Total',
                align: 'right',
                style: widthFormatter('invoiceTotalCol'),
                footerAlign: 'right',
                formatter: (col, row, i) => {
                    if (profile?.country?.toLowerCase() === 'canada') return `$${Number(col)?.toFixed(2)}`;
                    return <PriceInput cell={col} row={row} rowIndex={i} fieldName="total" tableType="tax" />
                }
            },
        ];

        if (profile?.country === 'Canada') {
            const items = [
                {
                    dataField: "gst",
                    text: 'GST',
                    headerAlign: 'left',
                    align: 'right',
                    style: widthFormatter('invoiceTotalCol'),
                    hidden: !(taxColumns || []).filter(e => e === 'GST')?.includes('GST'),
                    formatter: (col, row, i) => (
                        <PriceInput cell={col} row={row} rowIndex={i} fieldName="gst" tableType="tax" />
                    )
                },
                {
                    dataField: "hst",
                    text: 'HST',
                    headerAlign: 'left',
                    align: 'right',
                    style: widthFormatter('invoiceTotalCol'),
                    hidden: !(taxColumns || []).filter(e => e === 'HST')?.includes('HST'),
                    formatter: (col, row, i) => (
                        <PriceInput cell={col} row={row} rowIndex={i} fieldName="hst" tableType="tax" />
                    )
                },
                {
                    dataField: "pst",
                    headerAlign: 'left',
                    text: 'PST',
                    align: 'right',
                    style: widthFormatter('invoiceTotalCol'),
                    hidden: !(taxColumns || []).filter(e => e === 'PST')?.includes('PST'),
                    formatter: (col, row, i) => (
                        <PriceInput cell={col} row={row} rowIndex={i} fieldName="pst" tableType="tax" />
                    )
                },
                {
                    dataField: "qst",
                    headerAlign: 'left',
                    text: 'QST',
                    align: 'right',
                    style: widthFormatter('invoiceTotalCol'),
                    hidden: !(taxColumns || []).filter(e => e === 'QST')?.includes('QST'),
                    formatter: (col, row, i) => (
                        <PriceInput cell={col} row={row} rowIndex={i} fieldName="qst" tableType="tax" />
                    )
                }
            ]
            columns.splice(1, 0, ...items);
        }
        return columns;
    }

    useEffect(() => {
        if (!value) {
            setPrepared(null);
            return;
        }
        const result = {};

        contractTypes && contractTypes[value] && contractTypes[value].forEach((table) => {
            const newTable = {}

            // IF TABLETYPE NEEDS ASSETS:
            // if (columnsByTable[table].includes("asset")) {
            //   // FETCH COLUMNS AND SET THEM TO ASSETOPTIONS ON TABLE
            //   const options = fetchAssets();
            //   newTable.assetOptions = options ?? [];
            // }
            // DO THE SAME FOR ANTHING ELSE THAT BELONGS ON THE TABLE LEVEL.

            newTable.tableType = table;
            if (table === "tax") {
               
                newTable.columns = getTaxColumns()
            } else {
                newTable.columns = (reImbursementType === '2' && (table === 'labour' || table === 'labor')) ? COLUMNS['laborSelectRate'] : COLUMNS[table];

            }
            const customTable = (reImbursementType === '2' && (table === 'labour' || table === 'labor')) ? 'laborSelectRate' : table;
            newTable.data = getTableInitData(customTable);
            result[customTable] = newTable;
        });
        setPrepared(result);
    }, [value, assetAttributes, tax]);

    return {
        prepared,
        tax,
        contract
    }
};
