import React, { useState, useCallback, useContext } from "react";
import Accordion from "../../shared/Accordion/Accordion";
import { FormGroup } from 'reactstrap';
import classnames from 'classnames';
import { ReactComponent as SearchIcon } from '../../../assets/images/search.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg';
import styles from './Faqs.module.css';
import BootstrapTable from "react-bootstrap-table-next";
import { getBrandContent } from '../../BrandingWhiteLabel/BrandContent';
import { SessionContext } from "../../../context/SessionContext";
import ClientPhone from "../../shared/ClientPhone/ClientPhone";

const ComfortShieldprogramFaqs = () => {

  const [activeAccord, setActiveAccord] = useState(-1);
  const [search, setSearch] = useState("");
  const [tempSearch, setTempSearch] = useState("");
  const context = useContext(SessionContext);
  const { accountName } = context;
  const currentBrand = getBrandContent(accountName);
  const onToggle = useCallback((id) => {
    if (id === activeAccord) {
      setActiveAccord(-1);
    }
    else {
      setActiveAccord(id);
    }
  }, [activeAccord, setActiveAccord]);

  const isLennox = currentBrand.clientName.toLowerCase() === 'lennox';
  const isCarrier = currentBrand.clientName.toLowerCase() === 'carrier';
  const isRheem = currentBrand.clientName.toLowerCase() === 'rheem';
  const isTrane = currentBrand.clientName.toLowerCase() === 'trane';
  const isGE = currentBrand.clientName.toLowerCase() === 'ge';
  const isICP = currentBrand.clientName.toLowerCase() === 'icp';
  const priceLevelName =  currentBrand?.pricingLevelName.charAt(0).toLowerCase() + currentBrand?.pricingLevelName.slice(1);
  const ratesColumns = [
    {
      dataField: "tierType",
      text: "Tier"
    },
    {
      dataField: "tripAmount",
      text: "Trip Amount"
    },
    {
      dataField: "laborRate",
      text: "Labor Rate"
    },
    {
      dataField: "timeToSell",
      text: "Time to Sell from Equipment Installation Date"
    }
  ];

  const ratesData = [
    {
      tierType: "Basic",
      tripAmount: "$65",
      laborRate: "$85",
      timeToSell: "Within nine (9) months"
    },
    {
      tierType: "Tier 1",
      tripAmount: "$85",
      laborRate: "$125",
      timeToSell: "Within sixty (60) months"
    },
    {
      tierType: "Tier 2",
      tripAmount: "$100",
      laborRate: "$175",
      timeToSell: "Within sixty (60) months"
    },
    {
      tierType: "Tier 3",
      tripAmount: "$125",
      laborRate: "$250",
      timeToSell: "Within sixty (60) months"
    }
  ];

  const lennoxAccordionContents = [
    {
      bodyContent: `Offering ${currentBrand.programName} warranties not only gives you an opportunity to clarify the manufacturer’s limited warranty, but also gives your consumers the choice to be protected from costly out-of-pocket expenses should failures occur. Your company too deserves protection from costly expenses related to consumer concessions, as well as to be reimbursed for your services when unexpected failures occur in the early months or years following installations.`,
      title: `Why should I offer my consumers ${currentBrand.programName} extended warranties?`
    },
    {
      bodyContent: `Yes, ${currentBrand.programName} is a comprehensive solution which allows you to manage all of your extended warranty business through one program - no matter the brand.`,
      title: `If I sell brands other than Lennox, will ${currentBrand.programName} cover that equipment too?`
    },
    {
      bodyContent: `${currentBrand.programName} warranties cover repairs needed as a result of mechanical and electrical failures. Depending on the tier purchased, coverage will extend to other items such as refrigerant, parts mark up, and miscellaneous parts that may be needed to complete repairs.`,
      title: `What do ${currentBrand.programName} extended warranties cover?`
    },
    {
      bodyContent: `The labor portion of warranties can begin as early as ninety-one (91) days from installation date. If parts are included in the warranty sold then coverage begins upon expiration of the factory parts warranty. For all labor coverage, effective dates will be ninety (91) days after the contract is received by ${currentBrand.programName}.`,
      title: `When will the ${currentBrand.programName} warranty take effect?`
    },
    {
      bodyContent: `If your company has a credit account with Lennox you will be invoiced for the sales through normal invoicing procedures you have with Lennox. All payments for contracts should be remitted to Lennox. If your company does not have a credit account with Lennox then payment via credit card will be required during the sales entry process.`,
      title: `How do I pay for ${currentBrand.programName} warranties that my consumers purchase?`
    },
    {
      bodyContent: `No, consumers that purchase ${currentBrand.programName} extended warranties will not be required to pay a deductible when setting up a service call.`,
      title: `Will my consumer be required to pay a deductible if they need to set up service?`
    },
    {
      bodyContent: `Yes, if your company sells ${currentBrand.programName} warranties to consumers then you will have first right of refusal for all service calls that may be needed.  Your company information will also print on the certificate of coverage that consumers will receive once the warranty is purchased.`,
      title: `Will I be the preferred servicer for consumers that purchase a ${currentBrand.programName} warranty from my company?`
    },
    {
      bodyContent: (
        <>
          During factory warranty you must follow the OEM’s replacement procedure and guidelines.
          If the equipment is out of factory warranty and covered by {currentBrand.programName} please call at
          <ClientPhone customClass="mx-1" />
          to notify us of the replacement and to receive an authorization number that will be used when filing claims.
        </>
      ),
      title: `Am I required to obtain authorization prior to replacing equipment?`
    },
    {
      bodyContent: `Annual maintenance is recommended according to the OEM specifications. Proof of annual maintenance may be requested.`,
      title: `Is annual maintenance required on equipment covered by ${currentBrand.programName}?`
    },
    {
      bodyContent: `New equipment and previously installed equipment are eligible for coverage. Previously installed equipment must be in good working condition and coverage must be sold within sixty (60) months of installation. Reimbursement tiers available for purchase vary based on date of previously installed equipment.`,
      title: `Are ${currentBrand.programName} extended warranties only offered on new installations?`
    },
    {
      bodyContent: (
        <>
          <p>{currentBrand.programName} warranties have multiple reimbursement tiers so trip and labor rates will vary depending on the warranty sold. Reimbursement Tiers are as follows:</p>
          <div className={styles.ratesTable}>
            <BootstrapTable
              keyField="tierType"
              data={ratesData}
              columns={ratesColumns}
              bordered={true}
              bootstrap4
              wrapperClasses="table-responsive"
            />
          </div>
        </>
      ),
      title: "What trip and/or labor rates will I be paid for completed repairs?"
    },
  ];

  const rheemAccordionContents = [
    {
      bodyContent: `${currentBrand.programName} is an extended service agreement (ESA) that financially prepares HVAC customers for unexpected and unbudgeted repairs after the manufacturer’s limited warranty expires.  Additionally, ${currentBrand.programName} can be offered to HVAC customers to cover labor charges, while the manufacturer’s limited parts warranty is still active.  Offering ${currentBrand.programName} to customers is a great way to gain repeated business, increase profits and develop lasting client relationships.`,
      title: `What is ${currentBrand.programName}?`
    },
    {
      bodyContent: `Offering ${currentBrand.programName} warranties not only gives you an opportunity to clarify the manufacturer’s limited warranty, but also gives your consumers the choice to be protected from costly out-of-pocket expenses should failures occur. Your company too deserves protection from costly expenses related to consumer concessions, as well as to be reimbursed for your services when unexpected failures occur in the early months or years following installations.`,
      title: `Why should I offer my consumers ${currentBrand.programName} extended warranties?`
    },
    {
      bodyContent: `The ${currentBrand.programName} program is an option for all ${currentBrand.clientName} family brands.`,
      title: `If I sell brands other than ${currentBrand.clientName} family brands, will ${currentBrand.programName} cover that equipment too?`
    },
    {
      bodyContent: (
        <>
          Covered repairs needed as a result of mechanical and electrical failures are covered. Options are:
          <ul>
            <li>Labor Only: Time for labor and a trip/diagnostic fee.</li>
            <li>Labor Plus: Time for labor, a trip/diagnostic fee, and an allowance for parts.</li>
            <li>Parts Only: Cost of the covered part(s) replaced during the repair. No time for labor and no trip/diagnostic fees is included.</li>
          </ul>
        </>
      ),
      title: `What do ${currentBrand.programName} extended warranties cover?`
    },
    {
      bodyContent: (
        <>
          <ul>
            <li>Premium Plans are the existing program structure that are designed with a flat rate reimbursement structure and generally includes increased repair time and additional reimbursement when compared with typical 3rd party warranty providers.</li>
            <li>The new level, Core, is designed with a time and material reimbursement structure. While the total reimbursement is less when compared to the Premium Plans, it is in line with the competition and allows for a reduced, more aggressive, up front contract price.</li>
          </ul>
        </>
      ),
      title: `What is the difference between ${currentBrand.programName} Core and Premium Plans?`
    },
    {
      bodyContent: `The labor portion of warranties can begin as early as thirty-one (31) days from installation date. If parts are included in the warranty sold, then coverage begins upon expiration of the factory parts warranty. For all labor coverage, effective dates will be thirty-one (31) days after the contract is purchased through ${currentBrand.programName}.  If coverage is purchased 1 year or more from installation date, the effective date will be ninety-one (91) days after the contract is purchased.`,
      title: `When will the ${currentBrand.programName} warranty take effect?`
    },
    {
      bodyContent: `Payment via credit card will be required during the order entry process.  Dealers will have the ability to save your preferred payment method to assist in an efficient and friendly checkout process.`,
      title: `How do I pay for ${currentBrand.programName} warranties that my consumers purchase?`
    },
    {
      bodyContent: `No, consumers that purchase ${currentBrand.programName} extended warranties will not be required to pay a deductible when setting up a service call.`,
      title: `Will my consumer be required to pay a deductible if they need to set up service?`
    },
    {
      bodyContent: `Yes, if your company sells ${currentBrand.programName} warranties to consumers then you will have first right of refusal for all service calls that may be needed. Your company information will also print on the certificate of coverage that is mailed out, for customers to contact for service needs.`,
      title: `Will I be the preferred servicer for consumers that purchase a ${currentBrand.programName} warranty from my company?`
    },
    {
      bodyContent: `Preauthorization is not required.  Simply diagnose and repair the customer’s issue and once complete submit your claim and invoice documentation to ${currentBrand.programName} for review and claim payment for covered repairs.`,
      title: `Am I required to obtain authorization prior to replacing equipment?`
    },
    {
      bodyContent: `Annual maintenance is recommended by the OEM specifications. Proof of annual maintenance may be requested.`,
      title: `Is annual maintenance required on equipment covered by ${currentBrand.programName}?`
    },
    {
      bodyContent: `New equipment and previously installed equipment are eligible for coverage. Previously installed equipment must be in good working condition and coverage must be sold within sixty (60) months of installation.  The wait period for coverage sold beyond 1 Year from installation, will increase from the standard 30 day wait period, to 90 days.`,
      title: `Are ${currentBrand.programName} extended warranties only offered on new installations?`
    }
  ];

  const carrierAccordionContents = [
    {
      bodyContent: `${currentBrand.programName} is an extended service agreement (ESA) that financially prepares HVAC customers for unexpected and unbudgeted repairs after the manufacturer’s limited warranty expires.  Additionally, ${currentBrand.programName} can be offered to HVAC customers to cover labor charges, while the manufacturer’s limited parts warranty is still active.  Offering ${currentBrand.programName} to customers is a great way to gain repeated business, increase profits and develop lasting client relationships.`,
      title: `What is ${currentBrand.programName}?`
    },
    {
      bodyContent: `Offering ${currentBrand.programName} warranties not only gives you an opportunity to clarify the manufacturer’s limited warranty, but also gives your consumers the choice to be protected from costly out-of-pocket expenses should failures occur. Your company too deserves protection from costly expenses related to consumer concessions, as well as to be reimbursed for your services when unexpected failures occur in the early months or years following installations.`,
      title: `Why should I offer my consumers ${currentBrand.programName} extended warranties?`
    },
    {
      bodyContent: `The ${currentBrand.programName} program is an option for all ${currentBrand.clientName} family brands.`,
      title: `If I sell brands other than ${currentBrand.clientName} family brands, will ${currentBrand.programName} cover that equipment too?`
    },
    {
      bodyContent: (
        <>
          Covered repairs needed as a result of mechanical and electrical failures are covered. Options are:
          <ul>
            <li>Labor Only: Time for labor and a trip/diagnostic fee.</li>
            <li>Labor Plus: Time for labor, a trip/diagnostic fee, and an allowance for parts.</li>
            <li>Parts Only: Cost of the covered part(s) replaced during the repair. No time for labor and no trip/diagnostic fees is included.</li>
            <li>Parts &amp; Labor: Time for labor, a trip/diagnostic fee, part allowance, and cost of the covered part(s) replaced during the repair.</li>
          </ul>
        </>
      ),
      title: `What do ${currentBrand.programName} extended warranties cover?`
    },
    {
      bodyContent: (
        <>
          <ul>
            <li>{currentBrand.standardPlanName} Plans are the existing program structure that are designed with a flat rate reimbursement structure and generally includes increased repair time and additional reimbursement when compared with typical 3rd party warranty providers.</li>
            <li>The new level, {currentBrand.valuePlanName}, is designed with a time and material reimbursement structure. While the total reimbursement is less when compared to the Standard Plans, it is in line with the competition and allows for a reduced, more aggressive, up front contract price.</li>
          </ul>
        </>
      ),
      title: `What is the difference between ${currentBrand.programName} ${currentBrand.valuePlanName} and ${currentBrand.standardPlanName} Plans?`
    },
    {
      bodyContent: `The labor portion of warranties can begin as early as thirty-one (31) days from installation date. If parts are included in the warranty sold, then coverage begins upon expiration of the factory parts warranty. For all labor coverage, effective dates will be thirty-one (31) days after the contract is purchased through ${currentBrand.programName}.  If coverage is purchased 1 year or more from installation date, the effective date will be ninety-one (91) days after the contract is purchased.`,
      title: `When will the ${currentBrand.programName} warranty take effect?`
    },
    {
      bodyContent: `Payment via credit card will be required during the order entry process.  Dealers will have the ability to save your preferred payment method to assist in an efficient and friendly checkout process.`,
      title: `How do I pay for ${currentBrand.programName} warranties that my consumers purchase?`
    },
    {
      bodyContent: `No, consumers that purchase ${currentBrand.programName} extended warranties will not be required to pay a deductible when setting up a service call.`,
      title: `Will my consumer be required to pay a deductible if they need to set up service?`
    },
    {
      bodyContent: `Yes, if your company sells ${currentBrand.programName} warranties to consumers then you will have first right of refusal for all service calls that may be needed. Your company information will also print on the certificate of coverage that is mailed out, for customers to contact for service needs.`,
      title: `Will I be the preferred servicer for consumers that purchase a ${currentBrand.programName} warranty from my company?`
    },
    {
      bodyContent: `Preauthorization is not required.  Simply diagnose and repair the customer’s issue and once complete submit your claim and invoice documentation to ${currentBrand.programName} for review and claim payment for covered repairs.`,
      title: `Am I required to obtain authorization prior to replacing equipment?`
    },
    {
      bodyContent: `Annual maintenance is recommended by the OEM specifications. Proof of annual maintenance may be requested.`,
      title: `Is annual maintenance required on equipment covered by ${currentBrand.programName}?`
    },
    {
      bodyContent: `New equipment and previously installed equipment are eligible for coverage. Previously installed equipment must be in good working condition and coverage must be sold within sixty (60) months of installation.  The wait period for coverage sold beyond 1 Year from installation, will increase from the standard 30 day wait period, to 90 days.`,
      title: `Are ${currentBrand.programName} extended warranties only offered on new installations?`
    }
  ];

  const traneAccordionContents = [
    {
      bodyContent: `${currentBrand.programName} is an extended service agreement (ESA) program that financially prepares HVAC customers for unexpected and unbudgeted repairs after the manufacturer’s limited warranty expires. Additionally, ${currentBrand.programName} can be offered to HVAC customers to cover labor charges, while the manufacturer’s limited parts warranty is still active. Offering ${currentBrand.programName} to customers is a great way to gain repeated business, increase profits and develop lasting client relationships.`,
      title: `What is ${currentBrand.programName}?`
    },
    {
      bodyContent: `${currentBrand.specialityProgramName} a discounted ${currentBrand.programName} ESA program, designed for the ${currentBrand.programName} contractor who has a high degree of utilization of ESA’s and generally offers/sell’s an ESA with each install.`,
      title: `What is the ${currentBrand.programName} ${currentBrand.specialityProgramName} Program?`
    },
    {
      bodyContent: (
        <ol type='a'>
          <li>Offer/Sell ESA’s on most or all New Installs</li>
          <li>Purchase a minimum of 100 ESA contracts annually (per calendar year)</li>
        </ol>
      ),
      title: `What are the requirements for ${currentBrand.specialityProgramName}?`
    },
    {
      bodyContent: (
        <>
          Yes, there are two way to enroll.
          <ol type='i'>
            <li>If you are newly enrolling in ${currentBrand.programName} for the 1st time, simply check the "Please enroll me in ${currentBrand.specialityProgramName} program."</li>
            <li>If you are already in enrolled in ${currentBrand.programName}, you’ll need to complete the "${currentBrand.specialityProgramName} Program Enrollment Form."</li>
          </ol>
        </>
      ),
      title: `Do I need to enroll in ${currentBrand.specialityProgramName}?`
    },
    {
      bodyContent: `${currentBrand.programName} dealers can unenroll at their preference, however once the dealer unenrolls they will lose the discounted pricing and auto solicitation renewal benefits.`,
      title: `How long must I stay enrolled in the ${currentBrand.specialityProgramName} Program?`
    },
    {
      bodyContent: `The ${currentBrand.valuePlanName} ${currentBrand.pricingLevelName}  is ${currentBrand.programName}’s most aggressive rates available. ${currentBrand.programName} ${currentBrand.valuePlanName} ${currentBrand.pricingLevelName}s uses a labor allowance guideline table for component repairs. This table allocates a specific time for a component repair.`,
      title: `What is the ${currentBrand.programName} ${currentBrand.valuePlanName}  ${currentBrand.pricingLevelName}s?`
    },
    {
      bodyContent: `Offering ${currentBrand.programName} warranties not only gives you an opportunity to clarify the manufacturer's limited warranty, but also gives your consumers the choice to be protected from costly out-of-pocket expenses should failures occur. Your company too deserves protection from costly expenses related to consumer concessions, as well as to be reimbursed for your services when unexpected failures occur in the early months or years following installations.`,
      title: `Why should I offer my consumers ${currentBrand.programName} extended warranties?`
    },
    {
      bodyContent: `${currentBrand.programName} is designed exclusively for ${currentBrand.clientName} Technologies manufactured equipment and should only be used on Trane Technologies equipment. Certain exceptions can apply, please call support for additional details on exceptions.`,
      title: `If I sell brands other than ${currentBrand.clientName} Technologies manufactured equipment, will ${currentBrand.programName} cover that equipment too?`
    },
    {
      bodyContent: (
        <>
          Covered repairs as a result of mechanical and electrical failures are covered. Options are:
          <ul>
            <li>Labor Only: Time for labor and a trip/diagnostic fee.</li>
            <li>Labor Plus: Time for labor, a trip/diagnostic fee, and an allowance for parts.</li>
            <li>Parts &amp; Labor Plus: Time for labor, a trip/diagnostic fee, cost of the covered part(s) and a flat fee allowance for parts.</li>
            <li>Parts Only: Cost of the covered part(s) replaced during the repair. No labor or trip/diagnostic fees is included.</li>
          </ul>
        </>
      ),
      title: `What do ${currentBrand.programName} ESA's cover?`
    },
    {
      bodyContent: `${currentBrand.standardPlanName} ${currentBrand.pricingLevelName}s are the existing program structure that are designed with a flat rate reimbursement structure and generally includes increased repair time and additional reimbursement when compared with typical 3rd party warranty providers.
      ${currentBrand.valuePlanName}, a new reimbursement level, is designed with an hourly allowance table for covered components . While the total reimbursement is less when compared to the ${currentBrand.standardPlanName}  ${currentBrand.pricingLevelName}s, it is in line with the industry standards and allows for a more aggressive, up front contract price.`,
      title: `What is the difference between ${currentBrand.programName} ${currentBrand.standardPlanName} and ${currentBrand.valuePlanName} ${currentBrand.pricingLevelName}s?`
    },
    {
      bodyContent: `The labor portion of warranties can begin as early as thirty-one (31) days from installation date. If parts are included in the warranty sold, then coverage begins upon expiration of the factory parts warranty. For all labor coverage, effective dates will be thirty-one (31) days after the contract is purchased through ${currentBrand.programName}. If coverage is purchased 1 year or more from installation date, the effective date will be ninety-one (91) days after the contract is purchased.`,
      title: `When will the ${currentBrand.programName} warranty take effect?`
    },
    {
      bodyContent: `Payment via credit or Debit card will be required during the order entry process. Dealers will have the ability to save your preferred payment method to assist in an efficient and friendly checkout process.`,
      title: `How do I pay for ${currentBrand.programName} ESA's that my consumers purchase?`
    },
    {
      bodyContent: `No, consumers that purchase ${currentBrand.programName} ESA's will not be required to pay a deductible when setting up a service call.`,
      title: `Will my consumer be required to pay a deductible if they need to set up service?`
    },
    {
      bodyContent: `Yes, if your company sells ${currentBrand.programName} ESA's to consumers then you will have first right of refusal for all service calls that may be needed. Your company information will also print on the certificate of coverage that is mailed out, for customers to contact you directly for service needs.`,
      title: `Will I be the preferred servicer for consumers that purchase a ${currentBrand.programName} ESA from my company?`
    },
    {
      bodyContent: `All ${currentBrand.programName} Contracts are available on the ${currentBrand.programName} Portal, however only Standard and ${currentBrand.specialityProgramName}  can be claimed directly on the portal, you can go there to submit invoices for repairs, as well as complete other ${currentBrand.programName} warranty related tasks.
      ${currentBrand.valuePlanName}  ${currentBrand.pricingLevelName} claims must be filed via the ${currentBrand.valuePlanName}  ${currentBrand.pricingLevelName}s Claim form.  You can download a PDF-Fill in ${currentBrand.valuePlanName}  Claim form from the portal or your can contact our Support team at warranty-hvacsupport@aig.com or ${currentBrand.programPhone} to obtain the ${currentBrand.valuePlanName} claim form.`,
      title: `How do I file claims on ${currentBrand.programName} ${currentBrand.pricingLevelName}s?`
    },
    {
      bodyContent: `We are committed to processing invoices within thirty (30) days of receipt and will issue payment in the form of a check for approved claims. ACH (direct deposit) is also available to expedite claim payments. Contact Protection Plus Support for more assistance to setup ACH payments.`,
      title: `How soon will I be paid for approved claims?`
    },
    {
      bodyContent: `Preauthorization is not required. Simply diagnose and repair the customer's issue and once complete submit your claim and invoice documentation to ${currentBrand.programName} for review and claim payment for covered repairs.`,
      title: `Am I required to obtain authorization prior to replacing equipment?`
    },
    {
      bodyContent: `Annual maintenance is recommended by the OEM specifications. Proof of annual maintenance may be requested.`,
      title: `Is annual maintenance required on equipment covered by ${currentBrand.programName}?`
    },
    {
      bodyContent: `New equipment and previously installed equipment are eligible for coverage. Previously installed equipment must be in good working condition and coverage must be sold within sixty (60) months of installation. The wait period for coverage sold beyond 1 Year from installation, will increase from the standard 30-day wait period, to 90 days.`,
      title: `Are ${currentBrand.programName} ESA's only offered on new installations?`
    },
    {
      bodyContent: `Regulations differ by state, but to be safe and abide by state regulatory requirements, the cost of coverage should be listed on the sales invoice paperwork given to your customers. If the customer declines the coverage, that cost would then need to be deducted from your bid price to the customer.`,
      title: `When selling the coverage to my customer, am I required to list the cost of the labor coverage on their sales invoice?`
    },
    {
      bodyContent: `Yes, ${currentBrand.programName} has a complete catalog of commercial offerings for your Trane and American Standard commercial needs.`,
      title: `Is commercial equipment available for coverage under ${currentBrand.programName}?`
    },
    {
      bodyContent: `Yes, all Protection Plus ${currentBrand.pricingLevelName}s are fully transferable at no cost. To transfer coverage, download the Transfer Form, complete, and submit according to the instructions on the form. Once the transfer is complete, an updated copy of the labor warranty will be issued to the new customer. Please note: Transfer Forms require original customer's signatures, or the transfer request will not be accepted.`,
      title: `Are ${currentBrand.programName} ${currentBrand.pricingLevelName}s transferable? If yes, how?`
    },
    {
      bodyContent: `${currentBrand.programName} Standard, ${currentBrand.specialityProgramName}  & ${currentBrand.valuePlanName}  ${currentBrand.pricingLevelName}s are renewable. ${currentBrand.programName} will notify your customers when their coverage is nearing expiration and provide options to renew, all while maintaining their relationship with you as their preferred servicing contractor. ${currentBrand.programName} will ensure your company is referenced in all renewal communications.`,
      title: `Are ${currentBrand.programName} program ${currentBrand.pricingLevelName}s renewable? If yes for original terms sold 5 years or less, how?`
    },
    {
      bodyContent: `No. The dealer can opt out by simply calling or emailing our support team and having it noted on their account.`,
      title: `Do I have to have my ${currentBrand.programName} program ${currentBrand.pricingLevelName} customers offered a renewal?`
    }
  ];

  const reimbursementColumnsGE = [
    {
      dataField: "reimbursementPlanType",
      text: "Plans"
    },
    {
      dataField: "trip",
      text: "Trip"
    },
    {
      dataField: "laborRate",
      text: "Labor"
    }
  ];

  const reimbursementDataGE = [
    {
      reimbursementPlanType: currentBrand.standardPlanOne,
      trip: "$65",
      laborRate: "$85"
    },
    {
      reimbursementPlanType: currentBrand.standardPlanTwo,
      trip: "$85",
      laborRate: "$125"
    },
    {
      reimbursementPlanType: currentBrand.standardPlanThree,
      trip: "$85",
      laborRate: "$200"
    }
  ];
  
  const geAccordionContents = [
    {
      bodyContent: `Offering extended service agreements with ${currentBrand.programName} gives you the opportunity to clarify the manufacturer’s limited warranty and gives your consumers the choice to be protected from costly out-of-pocket expenses should failures occur. Your company too deserves protection from costly expenses related to consumer concessions, as well as to be reimbursed for your services when unexpected failures occur in the early months or years following installations.`,
      title: `Why should I offer my consumers ${currentBrand.programName} extended service agreements?`
    },
    {
      bodyContent: `Yes, ${currentBrand.programName} is a comprehensive solution which allows you to manage all your extended service agreements through one program - no matter the brand.`,
      title: `If I sell brands other than ${currentBrand.clientName}, will ${currentBrand.programName} cover that equipment too?`
    },
    {
      bodyContent: `${currentBrand?.programName} ${priceLevelName}s reimburse covered repairs needed as a result of mechanical and electrical failures.  See program terms and conditions and dealer enrollment guidelines for further information, including program labor rates.`,
      title: `What does ${currentBrand?.programName} extended service agreements cover?`
    },
    {
      bodyContent: `The labor portion of the plan can begin as early as thirty-one (31) days from installation date. If parts are included in the warranty sold, then parts coverage begins upon expiration of the factory parts warranty. For all labor coverage, effective dates will be thirty-one (31) days after the contract is received by ${currentBrand.programName}.`,
      title: `When will the ${currentBrand.programName} extended service agreement take effect?`
    },
    {
      bodyContent: `Our user-friendly websites will allow you to pay at point of purchase for each extended service agreement. Payment methods accepted include Visa, MasterCard, Discover and American Express in the form of a credit or debit card.`,
      title: `How do I pay for ${currentBrand.programName} extended service agreements that my consumers purchase?`
    },
    {
      bodyContent: `No, consumers that purchase ${currentBrand.programName} extended service agreements will not be required to pay a deductible when setting up a service call.`,
      title: `Will my consumer be required to pay a deductible if they need to schedule service?`
    },
    {
      bodyContent: `Yes, if your company sells ${currentBrand.programName} extended service agreements to consumers, then you will have first right of refusal for all service calls that may be needed. Your company information will also be printed on the certificate of coverage that consumers will receive once the plan is purchased.`,
      title: `Will I be the preferred servicer for consumers that purchase a ${currentBrand.programName} extended service agreement from my company?`
    },
    {
      bodyContent: `Yes, annual maintenance is a requirement of the program and must be completed per the manufacturer’s specifications.  If selected during claims processing, proof of annual maintenance of the covered equipment may be requested.`,
      title: `Is annual maintenance required on equipment covered by ${currentBrand.programName}?`
    },
    {
      bodyContent: `New equipment and previously installed equipment are eligible for coverage. Previously installed equipment must be in good working condition and coverage must be sold within sixty (60) months of installation. If purchased after twelve (12) months from installation, wait period is increased to ninety (90) days versus thirty (30) days if purchased within the first twelve (12) months from installation.`,
      title: `Are ${currentBrand.programName} extended service agreements only offered on new installations?`
    },
    {
      bodyContent: (
        <>
          <p>{currentBrand?.programName} extended service agreements have multiple reimbursement {priceLevelName}s so trip and labor rates will vary depending on the plan sold. Reimbursement {priceLevelName}s are as follows:</p>
          <div className={classnames(styles.reimbursementRatesTable, styles.reimbursementRatesTableGE)}>
            <BootstrapTable
              keyField="reimbursementType"
              data={reimbursementDataGE}
              columns={reimbursementColumnsGE}
              bordered={true}
              bootstrap4
              wrapperClasses="table-responsive"
            />
          </div>
          <p> See the program’s dealer enrollment terms and conditions and guidelines for further details.</p>
        </>
      ),
      title: `What trip and/or labor rates will I be paid for completed repairs?`
    }
  ];

  const reimbursementColumnsICP = [
    {
      dataField: "pricelevel",
      text: `${currentBrand.pricingLevelName}`
    },
    {
      dataField: "trip",
      text: "Trip/Diagnostic"
    },
    {
      dataField: "laborRate",
      text: "Labor Rate"
    },
    {
      dataField: "coverageType",
      text: "Coverage"
    },
    {
      dataField: "range",
      text: "Range of Reimbursement(based on repair code/hours)"
    }
  ];

  const reimbursementDataICP = [
    {
      pricelevel:`${currentBrand.standardPlanOne}`,
      range: <>$161.05 - $532.50 <br/> $196.05 - $567.50</>,
      coverageType: <>Labor Only <br/> Labor Plus</>,
      trip: "$65",
      laborRate: "$85"
    },
    {
      pricelevel:`${currentBrand.standardPlanTwo}`,
      range: <>$236.25 - $782.50  <br/> $271.25 - $817.50</>,
      coverageType: <>Labor Only <br/> Labor Plus</>,
      trip: "$95",
      laborRate: "$125"
    },
    {
      pricelevel:`${currentBrand.standardPlanThree}`,
      range :<>$264.50 - $920.00 <br/> $299.50 - $955.00</>,
      coverageType: <>Labor Only <br/> Labor Plus</>,
      trip: "$95",
      laborRate: "$150"
    },
    {
      pricelevel:`${currentBrand.standardPlanFour}`,
      range: <>$321.00 - $1,195.00 <br/> $365.00 - $1,230.00</>,
      coverageType: <>Labor Only <br/> Labor Plus</>,
      trip: "$95",
      laborRate: "$200"
    },
    {
      pricelevel:`${currentBrand.standardPlanFive}`,
      range: <>$376.25 - $1,470.00 <br/> $411.25 - $1,505.00</> ,
      coverageType: <>Labor Only <br/> Labor Plus</>,
      trip: "$95",
      laborRate: "$250"
    },
    {
      pricelevel:`${currentBrand.standardPlanSix}`,
      range: <>$432.50 - $1,745.00 <br/> $467.50 - $1,780.00</>,
      coverageType: <>Labor Only <br/> Labor Plus</>,
      trip: "$95",
      laborRate: "$300"
    }
  ];
  const icpAccordianContents = [
    {
      bodyContent: `Offering ${currentBrand.programName} labor coverage not only gives you an opportunity to clarify the manufacturer’s limited warranty, but also gives your consumers the choice to be protected from costly out-of-pocket expenses should failures occur. Your company also deserves protection from costly expenses related to consumer concessions, as well as to be reimbursed for your services when unexpected failures occur in the early months or years following installations.`,
      title: `Why should I offer my consumers ${currentBrand.programName} extended service agreements?`
    },
    {
      bodyContent: `Yes, ${currentBrand.programName} is a comprehensive solution which allows you to manage all of your extended warranty business through one program - no matter the brand.`,
      title: `Will ${currentBrand.programName} cover any brand of equipment?`
    },
    {
      bodyContent: `We have options available for all residential heating and cooling equipment: split systems, individual products, packaged units, mini-split systems, zone systems, and accessories.`,
      title: `What products are eligible for coverage?`
    },
    {
      bodyContent: `${currentBrand.programName} ${currentBrand.pricingLevelName}s cover repairs needed as a result of mechanical and electrical failures. Depending on the reimbursement ${currentBrand.pricingLevelName} purchased, coverage will extend to other items such as refrigerant, parts mark up, and miscellaneous parts that may be needed to complete repairs.`,
      title: `What do ${currentBrand.programName} extended service agreements cover?`
    },
    {
      bodyContent: `The labor portion of warranties can begin as early as thirty-one (31) days from installation date. If parts are included in the warranty sold, then coverage begins upon expiration of the factory parts warranty. For all labor coverage, effective dates will be thirty-one (31) days after the contract is received by ${currentBrand.programName}.`,
      title: `When will the ${currentBrand.programName} agreement take effect?`
    },
    {
      bodyContent: `If your company has a credit account with ${currentBrand.clientName}, you will be invoiced for the sales through normal invoicing procedures that you have with ${currentBrand.clientName}. All payments for contracts should be remitted to ${currentBrand.clientName}. If your company does not have a credit account with ${currentBrand.clientName}, then payment via credit card will be required during the sales entry process.`,
      title: `How do I pay for ${currentBrand.programName} warranties that my consumers purchase?`
    },
    {
      bodyContent: `New equipment and previously installed equipment are eligible for coverage. Previously installed equipment must be in good working condition and coverage must be sold within sixty (60) months of installation. Reimbursement options available for purchase vary based on date of previously installed equipment.`,
      title: `Are ${currentBrand.programName} extended service agreements only offered on new installations?`
    },
    {
      bodyContent: `No, consumers that purchase ${currentBrand.programName} extended service agreements will not be required to pay a deductible when setting up a service call.`,
      title: `Will my consumer be required to pay a deductible if they need to set up the service?`
    },
    {
      bodyContent: `Yes, if your company sells ${currentBrand.programName} coverage to consumers then you will have first right of refusal for all service calls that may be needed. Your company information will also print on the certificate of coverage that consumers will receive once the warranty is purchased.`,
      title: `Will I be the preferred servicer for consumers that purchase ${currentBrand.programName} coverage from my company?`
    },
    {
      bodyContent: `During factory warranty you must follow the OEM’s replacement procedure and guidelines. If the equipment is out of factory warranty and covered by ${currentBrand.programName}, please call ${currentBrand.programPhone} to notify us of the replacement and to receive an authorization number to be used when filing claims.`,
      title: `Am I required to obtain authorization prior to replacing equipment?`
    },
    {
      bodyContent: `Yes, annual maintenance is a requirement of the ${currentBrand.programName} program and must be completed per the manufacturer’s specifications. The annual maintenance requirement prints on the front page of the Certificate of Coverage and in the Terms & Conditions consumers receive. If selected during claims processing, proof of annual maintenance of the covered equipment must be provided.`,
      title: `Is annual maintenance required on equipment covered by ${currentBrand.programName}?`
    },
    {
      bodyContent: (
        <>
          <p> {currentBrand.programName} {currentBrand.pricingLevelName}s have multiple reimbursement {currentBrand.pricingLevelName}s. Trip and labor rates will vary depending on the agreement sold. <br/>
            Reimbursement {currentBrand.pricingLevelName}s are as follows:
          </p>
          <div className={classnames(styles.reimbursementRatesTable, styles.reimbursementRatesTableICP)}>
            <BootstrapTable
              keyField="reimbursementType"
              data={reimbursementDataICP}
              columns={reimbursementColumnsICP}
              bordered={true}
              bootstrap4
              wrapperClasses="table-responsive"
            />
          </div>
        </>
      ),
      title: `What trip and/or labor rates will I be paid for completed repairs?`
    }
  ];
  const filterSearch = acc => {
    const { title, bodyContent } = acc;
    return (typeof title === "string" && title.toLowerCase().includes(tempSearch)) || (typeof bodyContent === "string" && bodyContent.toLowerCase().includes(tempSearch));
  }

  const clientContents = isLennox ? lennoxAccordionContents : (isRheem ? rheemAccordionContents : (isCarrier ? carrierAccordionContents : (isICP ? icpAccordianContents : geAccordionContents)));

  const contents = clientContents
    .filter(filterSearch)
    .map((accord, i) => {
      if (search.length === 0) {
        (i < 9) ?
          (accord.title = `0${i + 1}. ${accord.title}`)
          :
          (accord.title = `${i + 1}. ${accord.title}`)
      }
      return <Accordion key={i} id={i} open={i === activeAccord} {...accord} onToggle={onToggle} />
    });

  return (
    <>
      <h1 className="h6">How can we help?</h1>
      <FormGroup className="col-md-4 col-lg-3 p-0">
        <input
          type="search"
          name="search"
          id="exampleSearch"
          placeholder="Search"
          className="form-control"
          value={search}
          onChange={e => {
            setSearch(e.target.value);
            setTempSearch(e.target.value.trim().toLowerCase());
          }}
        />
        {search.length > 0 ?
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => { setSearch(''); setTempSearch(''); }}
          /> : <SearchIcon className={styles.searchIcon} />
        }
      </FormGroup>
      {contents.length > 0 ? contents : <h3>0 Results Found</h3>}
      {/* {accordionContents
            .filter(filterSearch)
            .map((accord, i) => <Accordion key={i} id={i} open={i === activeAccord} {...accord} onToggle={onToggle} />)} */}
    </>
  )
}
export default ComfortShieldprogramFaqs;
