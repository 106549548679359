import React, { Component, Fragment } from "react";
import { Container, Card, CardBody, Row, Col, Label, Input, FormGroup } from "reactstrap";
import styles from "./CardDetails.module.css";
import { Spinner } from 'react-bootstrap';
import classNames from "classnames";
import SimpleReactValidator from 'simple-react-validator';
import NumberFormat from 'react-number-format';
import Cleave from 'cleave.js/react';
import { ReactComponent as ConfirmCheck } from '../../../assets/images/circlecheck-ADV.svg';
import { getBrandContent } from "../../BrandingWhiteLabel/BrandContent";
import CheckboxInput from "../../shared/CheckboxInput/CheckboxInput";
import { SessionContext } from "../../../context/SessionContext";
import { getCurrencySymbol } from '../../../lib/util';

let customPayBtn = styles.customPayBtn;

export class OrderSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentAmount: props.paymentAmount,
            taxAmount: props.taxAmount,
            gst: props.gst,
            pst: props.pst,
            country: props.countryCode,
            payInProgress: props.payInProgress,
            displayPayButton: props.displayPayButton,
            profileModel: props.profileModel,
            showConfirmMessage: false,
            contactMeChecked: false,
            pristinestate: false,
            fieldBlankError: false,
            phoneError: false,
            emailError: false,
            preferencePhone: true,
            phone: props.phone,
            email: props.email,
            countryId: props.countryId
        }
        this.validator = new SimpleReactValidator({
            element: message => <div className={classNames(styles.contactTypes, "errorMsg")}>
                {message.charAt(0).toUpperCase() + message.slice(1)}
            </div>,
            messages: {
                required: ':attribute is required.',
                min: ':attribute is required.',

            },
            validators: {
                isTrue: {
                    message: "You must agree to the :attribute Terms and Conditions to continue.",
                    rule: (val) => {
                        return val === true;
                    }
                },
                isOptionCheck: {
                    message: ':attribute.',
                    rule: (val) => {
                        return typeof (val) == "boolean";
                    },
                    required: true
                }
            }
        });
    }

    componentDidMount = () => {
        
    }

    componentDidUpdate(prevProps) {
        if (this.props.paymentAmount !== prevProps.paymentAmount ||
            this.props.taxAmount !== prevProps.taxAmount ||
            this.props.gst !== prevProps.gst ||
            this.props.pst !== prevProps.pst ||
            this.props.countryCode !== prevProps.countryCode) {
            this.setState({
                paymentAmount: this.props.paymentAmount,
                taxAmount: this.props.taxAmount,
                gst: this.props.gst,
                pst: this.props.pst,
                country: this.props.countryCode
            });
        }
    }

    pay = async () => {
        // trigger any validations on subscribed components
        //if (this.props.checkForValidationErrors != null) {
        //    var errors = this.props.checkForValidationErrors();
        //}
        this.setState({ payInProgress: true});;
        await this.props.payCallback();
        this.setState({ payInProgress: false });;
    }

    handeContactMe = async (flag) => {
        // TODO
        this.validator.showMessageFor('email');
        this.validator.showMessageFor('phone number');
        let phoneError = false;
        let emailError = false;
        if (!this.state.phone) {
            phoneError = true;
            this.setState({ phoneError: true });
        }

        if (this.state.phone && this.state.phone.length < 10 || this.validator.message("phone number", this.state.phone, "required|phone")) {
            phoneError = true;
        }
        if (!this.state.email) {
            emailError = true;
            this.setState({ emailError: true });
        }
        if (this.state.email && this.validator.message("email", this.state.email, "required|email")) {
            emailError = true;
        }
        if ((this.state.phone && phoneError === false && this.state.preferencePhone
            || this.state.email && emailError === false && !this.state.preferencePhone)
            && this.state.contactMeChecked === true) {

            var model = {
                "AgentQueueId": 33,
                "EnqueueReasonId": 25,
                "Phone": this.state.phone,
                "Email": this.state.email,
                "ItemValue": this.state.profileModel.partyId,
                "ContractPreference": this.state.preferencePhone == true ? 1 : 2
            }

            var response = await fetch("queue/savequeueitem/", {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(model)
            });

            if (response.ok) {
                this.setState({ showConfirmMessage: flag });
            }

        }
        this.setState({ pristineState: true, fieldBlankError: true });
    }


    handleCheckChange = () => {
        this.setState({ contactMeChecked: !this.state.contactMeChecked, contactMeError: this.state.contactMeChecked });
    }

    handlePhoneChange = (evt) => {
        this.setState({ phone: evt.target.value, pristineState: true });
        this.validator.showMessageFor('phone number');
        if (!evt.target.value || this.validator.message("phone number", evt.target.value, "required|phone")) {
            this.setState({ phoneError: true });
        }
        // TODO
    }
    handleRadioClick = e => {
        const { id } = e.target;
        if (id == 'phoneNumber') {
            this.setState({ preferencePhone: true });
        }
        if (id == "emailContact") {
            this.setState({ preferencePhone: false });
        }
        if (this.state.email) {
            this.setState({ emailError: false });
        } else {
            this.setState({ emailError: true });
        }
        if (this.state.email && this.validator.message("email", this.state.email, "required|email")) {
            this.setState({ emailError: true });
        }
        if (this.state.phone) {
            this.setState({ phoneError: false });
        } else {
            this.setState({ phoneError: true });
        }
        if (this.state.phone && this.validator.message("phone number", this.state.phone, "required|phone")) {
            this.setState({ phoneError: true });
        }
    }

    handleEmailChange = (evt) => {
        this.setState({ email: evt.target.value, pristineState: true });
        this.validator.showMessageFor('email');
        if (evt.target.value.trim() == "" || this.validator.message("email", evt.target.value, "required|email")) {
            this.setState({ emailError: true });
        } else {
            this.setState({ emailError: false })
        }
        // TODO
    }

    DealerAdvProgramCheck = () => {

        const details = this.state.profileModel
        if (details?.advantageProgramStatusId === 4) {

            return true;
        }
    }

    render() {
        const { translations } = this.props;
        let paymentAmount = this.state.paymentAmount;
        let taxAmount = this.state.taxAmount;
        let orderTotal = (paymentAmount + taxAmount);
        this.validator.purgeFields();
        const { accountName } = this.context;
        const currency = getCurrencySymbol(this.state?.countryId);
        //const benefits = getBrandContent(accountName).advantageBenefits.preferencesScreen
        const {
            showContactInput, showConfirmMessage, phone, email, preferencePhone, fieldBlankError
        } = this.state;
        const DealerEnrollCheck = this.DealerAdvProgramCheck();
        const errorOnPhoneField = ((
            this.state.pristineState &&
            fieldBlankError &&
            !this.state.phone &&
            preferencePhone &&
            this.state.phoneError
        ) || (
                this.state.phoneError &&
                preferencePhone &&
                this.validator.message("phone number", this.state.phone, "required|phone"))
        );
        const errorOnPhoneMessage = (((
            this.state.pristineState &&
            preferencePhone &&
            this.state.phoneError &&
            fieldBlankError &&
            !this.state.phone
        ) || (
                !this.state.phone &&
                preferencePhone &&
                this.state.phoneError)) &&
            this.validator.message("phone number", this.state.phone, "required|phone")
        );
        const errorOnEmailField = ((
            this.state.emailError &&
            this.state.pristineState &&
            fieldBlankError &&
            !preferencePhone
        ) || (
                this.state.emailError &&
                !preferencePhone &&
                this.validator.message("email", this.state.email, "required|email"))
        );
        const errorOnEmailMessage = (((
            this.state.pristineState &&
            !preferencePhone &&
            !email && this.state.emailError === true &&
            fieldBlankError
        ) || (
                !email && !preferencePhone &&
                this.state.emailError &&
                this.validator.message("email", this.state.email, "required|email"))) && (
                <div className={classNames(styles.contactTypes, "errorMsg d-flex")}>
                    Email is required.
                </div>
            )
        );
        const payLabel = translations?.pay || "Pay";
        return (
            <Col lg={4}>
                <Card>
                    <CardBody className={styles.totalInfo}>
                        <h2 className="h5 subHead">{translations?.orderSummary || "Order Summary"}</h2>
                        <div className={styles.planTotal}>
                            <p>{translations?.planTotal || "Plan Total"}</p>
                            <p><NumberFormat value={paymentAmount} displayType={'text'} thousandSeperator={true} prefix={currency} decimalScale={2} fixedDecimalScale={true} /></p>
                        </div>
                        {this.state.country == "CA" && this.state.gst > 0 &&
                            <div className={styles.taxApplied}>
                            <p className={styles.subTax}>GST</p>
                            <p>{this.state.gst.toFixed(2)}</p>
                            </div>
                        }
                        {(this.state.country == "CA" && this.state.pst > 0) &&
                            <div className={styles.taxApplied}>
                            <p className={styles.subTax}>PST</p>
                            <p>{this.state.pst.toFixed(2)}</p>
                            </div>
                        }
                        <div className={styles.taxApplied}>
                            <p>{translations?.tax || "Tax"}</p>
                            {this.state.taxAmount == 0 && this.state.gst == 0 && this.state.pst == 0 &&
                                <p>Not Applicable</p>
                            }
                            {this.state.taxAmount > 0 &&
                                <p><NumberFormat value={taxAmount} displayType={'text'} thousandSeperator={true} decimalScale={2} fixedDecimalScale={true} /></p>
                            }
                        </div>

                        <div className={styles.orderTotal}>
                            <p>{translations?.orderTotal || "Order Total"}</p>
                            <p><NumberFormat value={orderTotal} displayType={'text'} thousandSeperator={true} prefix={currency} decimalScale={2} fixedDecimalScale={true} /></p>
                        </div>
                        {this.props.displayPayButton &&
                            <div className="d-flex justify-content-center">
                                <button className={classNames("btn-primary", customPayBtn)} disabled={this.props.payInProgress} onClick={this.pay}>
                                    <Spinner color="light" size="sm" animation={this.props.payInProgress ? "border" : ""} />
                                {this.props.payInProgress ? "" : `${payLabel} ${currency}${orderTotal.toFixed(2)}`} 
                                </button>
                            </div>
                        }
                    </CardBody>
                </Card>
                {DealerEnrollCheck ? <Card>
                    <div className={styles.benefitsList}>
                        <div className={styles.secondCardHeader}>
                            <h3 className={styles.secondCardHeaderh5}>{getBrandContent(accountName).advantageProgramName} Program Benefits</h3>
                        </div>
                        <div className={styles.listOfBenefits}>
                            <p>Automate labor warranty processing for all future installs, with significant discounts and automatic billing.</p>
                            <hr className="small" />
                            <p>Why Sign Up for {getBrandContent(accountName).advantageProgramName}?</p>
                            <ul className="row mx-0 pl-3">
                                <li>Lock in and protect every customer</li>
                                <li>Simple, automated process</li>
                                <li>Discounted pricing opportunities</li>
                                <li>Manufacturer recommended strategy</li>
                                <li>Closing tool for all sales</li>
                            </ul>
                            {!showConfirmMessage ? (<Fragment><div className="checkbox mb-4">
                                <input className="mr-2" type="checkbox" id="contactMeCheck" checked={this.state.contactMeChecked} onChange={this.handleCheckChange.bind(this)} />
                                <label for="contactMeCheck" className={`checkboxLabel ${accountName}`} onClick={() => this.setState({ showContactInput: !showContactInput })}>I would like someone to contact me about the {getBrandContent(accountName).advantageProgramName} Program.</label>
                            </div>
                                {!this.state.contactMeChecked && this.state.pristineState && (
                                    <div className={classNames("errorMsg mb-2")}>
                                        This field is required.
                                    </div>
                                )}
                                {showContactInput == true && (<div className="col-lg-12 col-md-6 p-0">
                                    <FormGroup className={classNames(styles.contactTypes, "mb-2", errorOnPhoneField && "form-error")}>
                                        <div className="radio">
                                            <input type="radio" name="contactType" id="phoneNumber" value="contactNumber"
                                                defaultChecked onClick={this.handleRadioClick} />
                                            <label className={classNames("controls-label", styles.customRadioButton)} for="phoneNumber">
                                                <span className={classNames(errorOnPhoneField && "errorMsg")}>
                                                    Call
                                                </span>
                                            </label>
                                        </div>
                                        <Cleave 
                                            placeholder=""
                                            name="contactNumber"
                                            onChange={this.handlePhoneChange}
                                            value={this.state.phone}
                                            className="form-control col-lg-8 col-xl-9 col-8 col-md-9"
                                            id="phoneNumber"
                                            options={{ 
                                                blocks: [0, 3, 3, 4], 
                                                delimiters: ['(', ') ', '-'], 
                                                numericOnly: true 
                                            }}
                                        />
                                    </FormGroup>
                                    {errorOnPhoneMessage}
                                    {this.state.pristineState && preferencePhone && phone &&
                                        (phone.length != 10 && phone.length < 14) &&
                                        (<div className={classNames(styles.contactTypes, "errorMsg")}>
                                            Please enter a valid phone number.
                                        </div>
                                        )
                                    }
                                    <FormGroup className={classNames(styles.contactTypes, "mb-2", errorOnEmailField && "form-error")}>
                                        <div className="radio">
                                            <input type="radio" name="contactType" id="emailContact" value="contactMail"
                                                onClick={this.handleRadioClick} />
                                            <label className={classNames("controls-label", "mb-0", styles.customRadioButton)} for="emailContact">
                                                <span className={errorOnEmailField && "errorMsg"}>
                                                    Email
                                                </span>
                                            </label>
                                        </div>
                                        <input type="email" name="contactMail" id="emailContact"
                                            className="form-control col-lg-8 col-xl-9 col-8 col-md-9"
                                            value={this.state.email} defaultValue={email} onChange={this.handleEmailChange}
                                        />
                                    </FormGroup>
                                    {errorOnEmailMessage}
                                    {this.state.pristineState && !preferencePhone && email &&
                                        this.validator.message("email", email, "required|email") && (
                                            <div className={classNames(styles.contactTypes, "errorMsg")}>
                                                Please enter a valid email address.
                                            </div>
                                        )
                                    }
                                </div>)}
                                <div className="d-flex justify-content-center">
                                    <button className="btn-secondary" onClick={() => this.handeContactMe(!showConfirmMessage)}>Contact Me</button>
                                </div></Fragment>) : (
                                <div className={styles.contactConfirmation}>
                                    <ConfirmCheck />
                                    <div>Thank you, AIG will contact you within 1 to 2 business days.</div>
                                </div>
                            )}
                        </div>
                    </div>
                </Card> : ""}

            </Col>
        );
    }
}
OrderSummary.contextType = SessionContext