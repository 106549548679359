import React, { useState } from "react";
import { Container, Card, CardBody, Col } from "reactstrap";
import { ReactComponent as ConfirmationIcon } from '../../assets/images/Confirmation_Icon.svg';
import { ReactComponent as ReviewConfirmationIcon } from '../../assets/images/Contact Us icon.svg';
import { ReactComponent as RejectionIcon } from '../../assets/images/claim-cancelled.svg';
import styles from './AuthorizationConfirmation.module.css';
import { useHistory, useLocation } from 'react-router-dom';

export const AuthorizationConfirmation = (props) => {
    const loc = useLocation();
    const [inReview, setInReview] = useState(loc?.state?.inReview);
    const [rejected, ] = useState(loc?.state?.rejected);
    const [approved, ] = useState(loc?.state?.approved);
    const history = useHistory();

    const handleClick = () => {
        history.push('/requestforservice/' + loc?.state?.eventId + '/' + loc?.state?.clientId);
    }

    return (
        <Container className="contentWrapper">
            <Card>
                <CardBody>
                    {rejected &&
                        <div className={styles.authApprovalConfirmation}>
                            <RejectionIcon className="mb-4" />
                            <h1>Service Request Rejected</h1>
                            <Col md="6" className="text-center mb-4 pr-0 pl-4">
                                <p>If you’d like to speak with one of our representatives, call <a href="tel:800-479-5714" className="callLink"> (800) 479-5714 </a>
                                    or email <a className="btnLink" href="mailto:Warranty-Assist@aig.com">Warranty-Assist@aig.com</a>.
                                    Please reference your event number: {loc?.state?.eventNumber}.
                                </p>
                            </Col>
                        </div>
                    }
                    {inReview &&
                        <div className={styles.authReviewConfirmation}>
                            <ReviewConfirmationIcon className="mb-4" />
                            <h1>We’re Reviewing Your Request</h1>
                            <Col md="7" className="text-center mb-4">
                                <p>Thank you for submitting an authorization request. One of our representatives will review the information provided and reach out via email regarding the authorization status. Please allow up to
                                    2 business days for our team to review and process your request.
                                </p>
                                <p>If you’d like to speak with one of our representative, call <a href="tel:866-523-8649" className="callLink"> (866) 523-8649 </a>
                                    or email <a className="btnLink" href="mailto:Warranty-ServiceApprovals@aig.com">Warranty-ServiceApprovals@aig.com</a>. 
                                    Please reference your event number: {loc?.state?.eventNumber}.
                                </p>
                            </Col>
                            <button className="btnLink" onClick={handleClick}>Return to Service Request Details</button>
                        </div>
                    }
                    {approved &&
                        <div className={styles.authApprovalConfirmation}>
                            <ConfirmationIcon className="mb-4" />
                            <h1>Authorization Request Approved</h1>
                            <Col md="7" className="text-center mb-4">
                                <p>If you’d like to speak with one of our representative, call <a href="tel:866-523-8649" className="callLink"> (866) 523-8649 </a>
                                    or email <a className="btnLink" href="mailto:Warranty-ServiceApprovals@aig.com">Warranty-ServiceApprovals@aig.com</a>.
                                    Please reference your event number: {loc?.state?.eventNumber}.
                                </p>
                            </Col>
                            <button className="btnLink" onClick={handleClick}>Return to Service Request Details</button>
                        </div>
                    }
                </CardBody>
            </Card>
        </Container>
    );
}